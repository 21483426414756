import { useState, useEffect } from "react";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";
import { accessEdit } from "recoil/authState";
import { useHistory } from "react-router-dom";
import { Space, Table, Tag, Row, Col } from "antd";
import { PushpinTwoTone } from "@ant-design/icons";
import useGetList from "hooks/useGetList";
import { defaultUrl } from "services/api.helpers";
import LoadingView from "common/view/LoadingView";
import WriteButton from "common/board/WriteButton";

const BoardListWriterAdmin = ({ category }) => {
  const isEdit = useRecoilValue(accessEdit);
  const history = useHistory();
  const setModal = useSetRecoilState(modalState);
  const pathName = history.location.pathname;
  const [page, setPage] = useState(0);

  const { loading, data, error, totalCnt, refetch } = useGetList({
    url: `${defaultUrl}/boards?cate=${category}&rpp=12&page=${page}`,
  });

  useEffect(() => {
    refetch();
  }, [category, page]);

  const columnsNotice = [
    {
      title: "번호",
      width: "10%",
      dataIndex: "listNo",
      align: "center",
      className: "noTxt",
      key: "listNo",
      render: (number) =>
        parseInt(totalCnt / 12) > page
          ? totalCnt - 12 * (page + 1) + number
          : number,
    },
    {
      title: "",
      dataIndex: "pinned",
      width: "3%",
      key: "status",
      align: "center",
      render: (item) =>
        item ? (
          <PushpinTwoTone
            twoToneColor="#E94E1B"
            style={{ fontSize: "1.2em" }}
          />
        ) : (
          <></>
        ),
      sortOrder: "status" === false && "status" === true,
      shouldCellUpdate: (record, prevRecord) => true,
    },
    {
      title: "제목",
      dataIndex: "title",
      width: "55%",
      key: "title",
      className: "titleTxt",
      render: (text) => <a className="board-title-link">{text}</a>,
    },
    {
      title: "작성자",
      dataIndex: "writer",
      width: "10%",
      key: "writer",
      align: "center",
      render: () => <span>관리자</span>,
      className: "writerTxt",
    },
    {
      title: "날짜",
      dataIndex: "createdAt",
      render: (item) => moment(item).format("YY/MM/DD"),
      width: "12%",
      align: "center",
      key: "createdAt",
      className: "grayTxt",
    },
    {
      title: "조회수",
      dataIndex: "views",
      width: "10%",
      align: "center",
      key: "views",
      className: "grayTxt",
    },
  ];

  const onChange = (current) => {
    let crr = current - 1;
    return setPage(crr);
  };

  const onRow = (rowData, rowIndex) => {
    return {
      onClick: () => {
        history.push({
          pathname: `${pathName}/detail/${rowData.id}`,
        });
      },
    };
  };

  return (
    <>
      <ErrorModal />
      {loading ? (
        <LoadingView />
      ) : error ? (
        setModal({
          type: "error",
          isActive: true,
          title: "게시글 정보를 가져올수 없습니다.",
          msg: `${error}`,
          goBack: true,
        })
      ) : (
        <div>
          {isEdit ? (
            <WriteButton />
          ) : (
            <div className="board-top-control-bt role-hide-bt">
              <Row justify="end" style={{ height: "32px" }}>
                <Col span={4}></Col>
              </Row>
            </div>
          )}
          <div
            className="List"
            style={{
              maxWidth: "1200px",
              margin: "-5px auto",
              padding: "0 24px 24px",
            }}
          >
            <Space align="end" size="small" direction="vertical">
              <Table
                tableLayout="fixed"
                expandRowByClick={true}
                columns={columnsNotice}
                dataSource={data}
                onRow={onRow}
                rowKey={totalCnt}
                pagination={{
                  // defaultCurrent:1,
                  current: page + 1,
                  pageSize: 12,
                  total: totalCnt,
                  onChange: onChange,
                  size: "small",
                  showSizeChanger: false,
                  showQuickJumper: false,
                }}
              />
            </Space>
          </div>
        </div>
      )}
    </>
  );
};

export default BoardListWriterAdmin;
