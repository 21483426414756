import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { Layout } from "antd";
import "common/board/Board.css";
import "common/board-api/Board-api.css";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import { Route, Switch } from "react-router-dom";
import BoardListNotice from "notice/BoardListNotice";
import NoticeEdit from "notice/NoticeEdit";
import BoardDetail from "common/board-api/BoardDetail";
import NoticeWrite from "notice/NoticeWrite";

const { Content } = Layout;

const Notice = () => {
  const title = "공지사항";
  const subTitle = "공지사항";
  const menuList = [{ name: "공지사항", link: "/notice" }];

  return (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content className="board-content-wrap">
              <Switch>
                <Route path="/notice" exact>
                  <BoardListNotice category="notice" />
                </Route>
                <Route path="/notice/write" exact>
                  <NoticeWrite
                    category="notice"
                    title="공지사항"
                    url="/notice"
                  />
                </Route>
                <Route path="/notice/edit/:bbsId" exact>
                  <NoticeEdit title="공지사항" url="/notice" />
                </Route>
                <Route path="/notice/detail/:bbsId" exact>
                  <BoardDetail type="basic" url="/notice" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );
};

export default Notice;
