export const signInError = async (error, setModal) => {
    const errorCode = error.code;
    let errorMessage = error.message;
    // firebase의 errorCode.
    switch (errorCode) {
        case "auth/email-already-in-use":
            console.log(errorCode + errorMessage);
            errorMessage =
                "동일한 이메일 계정이 이미 존재합니다. 로그인을 해 주세요.";
            return setModal({
                type: "error",
                isActive: true,
                title: "이메일 인증 오류가 발생했습니다.",
                msg: errorMessage,
                redirect: `/auth/sign`
            });
        case "auth/user-not-found":
            console.log(errorCode + errorMessage);
            errorMessage = "해당 이메일로 가입된 사용자가 없습니다. 이메일 주소를 확인해주세요.";
            return setModal({
                type: "error",
                isActive: true,
                title: `가입되지 않은 사용자입니다.`,
                msg: errorMessage,
                redirect: `/auth/sign`
            });
        default:
            errorMessage = errorCode;
            return setModal({
                type: "error",
                isActive: true,
                title: error,
                msg: errorMessage,
                redirect: `/`
            });
    }
};
