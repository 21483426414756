import React, { useState } from "react";
import { Modal, Button, Result } from "antd";
import { WarningTwoTone, setTwoToneColor } from "@ant-design/icons";

const Warning = (props) => {
  const { handleGoBack, boardDeleteConfirm } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  setTwoToneColor("#F55F4A");
  // getTwoToneColor();

  return (
    <>
      <Button
        type="primary"
        danger
        onClick={showModal}
        style={{ marginRight: 30 }}
      >
        취소
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleGoBack}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        okButtonProps={{ size: "large", danger: "true" }}
        cancelButtonProps={{ size: "large" }}
      >
        <Result
          icon={<WarningTwoTone />}
          title="작성한 게시글이 저장되지 않습니다."
          subTitle="목록으로 돌아겠습니까?"
        />
      </Modal>
    </>
  );
};

export default Warning;
