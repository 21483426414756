import React, { useState, useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import auth from "services/firebase";
import axios from "axios";
import { defaultUrl, refreshToken } from "services/api.helpers";
import "common/form/QuillCustom.css";
import LoadingFlex from "common/view/LoadingFlex";

const ReactQuillEditor = (props) => {
  const [loading, setLoading] = useState(false);
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: {
        container: [
          //[{ 'font': [] }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ["link", "image", "video"],
          [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
          ["clean"],
        ],
      },
    },
    formats: formats,
  });
  const { value, onEditorChange } = props;

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    console.log(url);
    quill.insertEmbed(range.index - 1, "image", url);
    setLoading(false);
  };

  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("image", file);
    // body.append("type", "event");

    const token = await auth.currentUser
      .getIdToken()
      .then((idToken) => idToken);
    console.log(`ReactQuillEditor Token : ${token}, ${JSON.stringify(body)}`);

    const instance = await axios.create({
      baseURL: defaultUrl,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      timeout: 10000,
    });

    await instance
      .post("images/", body)
      .then((response) => {
        console.log(response);
        let _imgUrl = defaultUrl + response.data.url;
        console.log(_imgUrl);
        return insertToEditor(_imgUrl);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    setLoading(true);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  useEffect(() => {
    if (quill) {
      // Edit 모드에서 기존 value 가져오기.
      if (!!value) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }
      // onChange Handlers
      quill.on("text-change", (delta, oldDelta, source) => {
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // console.log(quillRef.current.firstChild.innerHTML);
        onEditorChange(quill.root.innerHTML);
      });
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill]);

  const formats = [
    "font",
    "size",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "link",
    "image",
    "video",
    "align",
    "color",
    "background",
  ];
  return (
    <div style={{ height: "56vh", paddingBottom: 42 }} className="quill-wrap">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            border: "1px solid #e0e0e0",
          }}
        >
          <LoadingFlex />
        </div>
      ) : (
        <div ref={quillRef} />
      )}
    </div>
  );
};

export default ReactQuillEditor;
