const AuthHeader = (title) => {
  return (
    <div className="sign-header">
      <p>
        <img
          style={{ width: "60px" }}
          src="/image/people_icon.png"
          alt="people-icon"
        />
      </p>
      <span className="sign-header-text">{title}</span>
    </div>
  );
};

export default AuthHeader;
