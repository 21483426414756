import { useState, useEffect } from "react";
import auth from "services/firebase";
import {Button, Row, Col, Tabs, Form, Input, message} from "antd";
import SentEmail from "auth/SentEmail";
import { useHistory } from "react-router-dom";
import ErrorModal from "common/view/ErrorModal";
import { putUserInfo } from "auth/AuthController";
import { authState, User, userRoleState } from "recoil/authState";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import { viewRoleState, viewSignUpState } from "recoil/viewState";
import { refreshToken, defaultUrl } from "services/api.helpers";
import axios from "axios";
import {signInError} from "auth/SignInError";

const SigninInput = (props:any) => {
    const { signinView, setSigninView } = props;
    type userValue = {
        email: string;
        password: string;
    };

    const history = useHistory();
    const setModal = useSetRecoilState(modalState);
    const setView = useSetRecoilState(userRoleState);
    const [userState, setUserState] = useRecoilState(authState);

    const getUserData = (user: any) => {
        axios
            .get(`${defaultUrl}/users/${user.uid}`, {
                headers: { Authorization: user._lat },
            })
            .then((res) => {
                message.success("로그인 되었습니다.");
                const data = res.data;

                const currentUser: User = {
                    id: user.uid,
                    email: data.email,
                    role: data.role,
                    name: data.name,
                    idNumber: data.idNumber ?? "",
                    department: data.department ?? "",
                    emailVerified: user.emailVerified,
                    refreshToken: user.refreshToken,
                    classList: data.classList ?? [],
                };

                putUserInfo(user, currentUser);

                setUserState({ ...currentUser });
                setView(data.role);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const signinWithEmailAccount = async (email: string, password: string) => {
        await auth
            .signInWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
                const user = userCredential.user!;
                if (user.emailVerified === true) {
                    await getUserData(user);
                    history.push("/");
                } else {
                    setView("ALL");
                    history.push("/auth/email");
                }
            })
            .catch((error) => {
                // 이메일 로그인 실패에 대한 에러
                console.log(error);
                setView("ALL");
                signInError(error, setModal);
            });
    };

    const onFinish = (value: userValue) => {
        return signinWithEmailAccount(value.email, value.password);
    };

    return (
        <>
            <div className="sign-header">
                * 동국대학교 메일 계정으로 회원가입 및 로그인이 가능합니다.
            </div>
            <ErrorModal />
            <Form
                name="login-form"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 19
                    }}
                    label="이메일"
                    name="email"
                    className="form-item"
                    rules={[
                        {required: true, message: '이메일이 입력되지 않았습니다.'},
                        {type: 'email', message: '올바른 이메일 주소를 입력해주세요.'}
                    ]}
                >
                    <Row>
                        <Col span={24}>
              <span className="ant-input-affix-wrapper" >
                <Input placeholder="이메일 계정" />
              </span>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 19
                    }}
                    label="비밀번호"
                    name="password"
                    className="form-item"
                    rules={[{ required: true, message: "비밀번호를 입력해 주세요." }]}
                >
          <span className="ant-input-affix-wrapper" >
            <Input type="password" placeholder="비밀번호" />
          </span>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button default-auth-bt"
                    >
                        로그인
                    </Button>
                </Form.Item>
            </Form>
            <p>
                <Button type="text" onClick={() => setSigninView("resetPassword")}>
                    비밀번호를 잊어버리셨나요?
                </Button>
            </p>
        </>
    );
};

export default SigninInput;
