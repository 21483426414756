import { Form, Button, Input } from "antd";

const NewComment = (props) => {
  const { TextArea } = Input;
  const { onChange, state, value } = props;
  return (
    <>
      <Form.Item>
        <TextArea
          rows={10}
          onChange={onChange}
          value={value}
          loading={state.loading}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          답변 작성
        </Button>
      </Form.Item>
    </>
  );
};

export default NewComment;
