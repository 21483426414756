import React from "react";
// import auth from "services/firebase";
// import axios from "axios";
import { atom, selector } from "recoil";

export interface User {
  id: string;
  email: string;
  role?: string;
  name: string;
  idNumber?: string;
  department?: string;
  emailVerified: boolean;
  refreshToken: string;
  classList?: Array<object>;
}

export const authState = atom<User>({
  key: "authState",
  default: {
    id: "",
    email: "",
    role: "",
    name: "",
    idNumber: "",
    department: "",
    emailVerified: false,
    refreshToken: "",
    classList: [],
  },
});


export const signUpUserEmail = atom<String | null>({
  key: "signUpUserData",
  default: null,
})


export const idTokenState = atom<string | null>({
  key: "idTokenState",
  default: null,
});

export const uidState = atom<string | null>({
  key: "uidState",
  default: null,
});

type role = "" | "ALL" | "ST" | "PR" | "AS" | "CO" | "ADM";

export const userRoleState = atom<role>({
  key: "userRoleState",
  default: "",
});

// 사용자의 권한을 체크해서 교수, 기업멘토, 조교, 관리자 권한의 유저는 수정 권한을 true로 주는 전역state.
export const accessEdit = atom<Boolean>({
  key: "accessEdit",
  default:false,
});
