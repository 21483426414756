import { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import auth from "services/firebase";
import { putUserInfo } from "auth/AuthController";
import ErrorModal from "common/view/ErrorModal";
import { modalState } from "recoil/modalState";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { authState, User, signUpUserEmail } from "recoil/authState";
import { viewRoleState, viewSignUpState } from "recoil/viewState";
import AuthHeader from "auth/AuthHeader";
import { useHistory } from "react-router-dom";
import SearchInput from "common/board/SearchInput";
import { actionCodeSettings } from "common/constants";
import {signInError} from "auth/SignUpError";

const SignUpStAo = () => {
    type inputStatus =
        | ""
        | "success"
        | "warning"
        | "error"
        | "validating"
        | undefined;

    type userValue = {
        email: string;
        password: string;
        department: string;
    };

    // 권한에 따른 전역 view state 값을 받아오고 셋팅하는 부분.
    const roleView = useRecoilValue(viewRoleState);
    const setModal = useSetRecoilState(modalState);
    const history = useHistory();

    const emailVeri = useSetRecoilState(signUpUserEmail);
    const [userState, setUserState] = useRecoilState(authState);
    const [status, setStatus] = useState<inputStatus>("");
    const [depart, setDepart] = useState("");

    // 로그인 회원가입 페이지가 동일한 페이지에서 토글되므로, 수동으로 탭전환하는 코드 활용 by Sarc
    const switchToSignInTab = ()=>{
        const _switchTab = document.querySelector("div.ant-tabs-tab:not(.ant-tabs-tab-active)") as HTMLElement;
        if (!!_switchTab) {
            _switchTab.click();
        }
    }

    const createEmailAccount = async (value: userValue) => {
        auth
            .createUserWithEmailAndPassword(value.email, value.password)
            .then((userCredential) => {
                const user = auth.currentUser!;
                const currentUser: User = {
                    id: user.uid,
                    email: value.email,
                    emailVerified: user.emailVerified,
                    name: userState.name,
                    idNumber: userState.idNumber,
                    role: roleView,
                    department: depart,
                    refreshToken: user.refreshToken,
                };

                emailVeri(value.email);
                putUserInfo(user, currentUser);

                user
                    .sendEmailVerification({url: window.location.protocol+"//"+window.location.host+"/?verified=true"})
                    .then(() => {
                        return history.replace("/auth/email");
                    })
                    .catch((err) => {
                        setModal({
                            type: "error",
                            isActive: true,
                            title: `이메일 전송을 실패했습니다.`,
                            msg: `${err}`,
                            redirect: `/auth/sign`
                        });
                    });
            })
            .catch((error) => {
                const errorCode:string = error.code;
                const errorMessage:string = error.message;
                console.log(
                    errorCode + errorMessage,
                );
                if (errorCode === 'auth/email-already-in-use') {
                    // 특정 에러일때(가입된 이메일 존재) 로그인 페이지 탭으로 전환해주는 함수 by Sarc
                    switchToSignInTab();
                }
                return signInError(error, setModal)
            });
    };

    const onFinish = async (value: userValue) => {
        setStatus("validating");

        if (depart === "") {
            console.log('학과미선택');
            setModal({
                type: "error",
                isActive: true,
                title: `학과가 선택되지 않았습니다.`,
                msg: `학과를 선택해 주세요.`,
            });
            setStatus("");
            return
        }

        setStatus("");

        const newValue = {
            email: value.email+"@dgu.ac.kr",
            password: value.password,
            department: depart,
        };

        return createEmailAccount(newValue);
    };
    return (
        <>
            {roleView === "ST"
                ? AuthHeader(`수강생 회원가입`)
                : AuthHeader(`조교 회원가입`)}
            <ErrorModal />
            <Form name="sutudents-signin" className="signin-form" onFinish={onFinish}>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="이메일"
                    // TODO 추후 이메일 provider가 확정되면 전활될 이메일 @앞부분용 validation - 한글 및 특수기호 입력방지
                    rules={
                        [   {required: true, message: '이메일이 입력되지 않았습니다.'},
                            {
                                required: true,
                                // 한글 및 특수기호 제외한 영문대소문자, 숫자만 가능한 패턴 + _(언더바 허용)추가 -asha
                                pattern: new RegExp(/^[^\W]+$/i),
                                message:
                                    '영문(대소문자포함), 숫자만 입력 가능합니다',
                            },
                        ]
                    }
                    name="email"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                >
                    <Row className="add-form-row">
                        <Col span={15}>
                <span className="ant-input-affix-wrapper" >
                  <Input name="email" placeholder="이메일" />
                </span>
                        </Col>
                        <Col span={7} className="email-text">
                            <span>@dgu.ac.kr</span>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="비밀번호"
                    rules={[
                        { required: true, message: "비밀번호를 입력해 주세요." },
                        { required: true, 
                          pattern: new RegExp(/^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z]).*$/),
                          message: "8자 이상의 문자 + 숫자 조합으로 입력해 주세요.",
                        },
                    ]}
                    name="password"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                >
                    <Input.Password
                        className="passw-input"
                        placeholder="비밀번호 설정 ( *8자 이상의 문자 + 숫자 조합 )"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="비밀번호확인"
                    name="passwordChk"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: '비밀번호를 다시 입력해주세요.',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        className="passw-input"
                        placeholder="비밀번호확인"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <SearchInput setDepart={setDepart} />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button default-auth-bt"
                    >
                        회원가입
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default SignUpStAo;
