import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createBoard } from "services/boardApi";
import "common/form/Form.css";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BoardWrite = ({ category, title, url }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    history.goBack();
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const HandleFinish = (values) => {
    setLoading(true);

    const newValue = {
      category: category,
      title: values.title,
      writer: "관리자",
      description: desc,
    };

    return postDataApi(newValue);
  };

  const postDataApi = async (data) => {
    // console.log(`postDataApi: ${JSON.stringify(data)}`);
    return createBoard(data)
      .then((e) => {
        setLoading(false);
        // console.log(`게시글이 정상적으로 작성되었습니다.`);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `게시글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("board create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    message.success({
      content: "새 게시글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          name="writeForm"
          layout="vertical"
          className="writeForm"
          onFinish={HandleFinish}
          onFinishFailed={HandleFinishFaild}
        >
          <Col span={2}>
            <Button onClick={handleGoBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
              <span className="list-text-bt">목록</span>
            </Button>
          </Col>
          <Row className="detailMainInfoWrap">
            <Col span={18} offset={3}>
              <h2>{title} 글쓰기</h2>
            </Col>
            <Col span={3}></Col>
          </Row>
          <Row className="edit-content-wrap">
            <Form.Item
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              rules={[
                { required: true, message: "제목을 입력하지 않았습니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
              rules={[
                { required: true, message: "내용을 입력하지 않았습니다." },
              ]}
            >
              <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
              <Warning handleGoBack={handleGoBack} />
              <Button
                type="primary"
                htmlType="submit"
                className="write-submit-button"
              >
                등록
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default BoardWrite;
