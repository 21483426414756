import React, { useState, useEffect } from "react";
import useGetListWt from "hooks/useGetListWt";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Space, Table, Spin, Tag, Row, Col, Button } from "antd";
import { defaultUrl } from "services/api.helpers";
import LoadingView from "common/view/LoadingView";
import { ArrowLeftOutlined } from "@ant-design/icons";

const MyInquiry = () => {
  let _category = "private";
  const history = useHistory();
  const pathName = history.location.pathname;
  const [page, setPage] = useState(0);

  const { loading, data, error, refetch, totalCnt } = useGetListWt({
    url: `${defaultUrl}/inquiries?cate=${_category}&rpp=12&page=${page}`,
  });

  useEffect(() => {
    refetch();
  }, [page]);

  const columns = [
    {
      title: "답변상태",
      dataIndex: "status",
      width: "10%",
      key: "id",
      align: "center",
      render: (item) =>
        item ? (
          <Tag color="green" className="statusTag">
            답변완료
          </Tag>
        ) : (
          <Tag color="orange" className="statusTag">
            미완료
          </Tag>
        ),
      sortOrder: "status" === false && "status" === true,
      shouldCellUpdate: (record, prevRecord) => true,
    },
    {
      title: "문의유형",
      dataIndex: "type",
      width: "12%",
      key: "id",
      align: "center",
    },
    {
      title: "제목",
      dataIndex: "title",
      width: "35%",
      key: "id",
      className: "titleTxt",
      render: (text) =>
        text ? (
          text.length > 40 ? (
            <a className="board-title-link">{text.slice(0, 40)}..</a>
          ) : (
            <a className="board-title-link">{text}</a>
          )
        ) : null,
    },
    {
      title: "작성자",
      dataIndex: "writer",
      width: "10%",
      align: "center",
      key: "id",
      className: "grayTxt",
    },
    {
      title: "날짜",
      dataIndex: "createdAt",
      render: (item) => moment(item).format("YY/MM/DD"),
      width: "10%",
      align: "center",
      key: "id",
      className: "grayTxt",
    },
  ];

  const onChange = (current) => {
    let crr = current - 1;
    return setPage(crr);
  };

  const onRow = (rowData, rowIndex) => {
    return {
      onClick: () => {
        history.push({
          pathname: `${pathName}/detail/${rowData.id}`,
        });
      },
    };
  };

  const onHandleBack = () => {
    history.push(`/mypage`);
  };

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <div>
          <div className="board-top-control-bt role-hide-bt">
            <div className="mycontainer myinfo">
              <Row justify="start" align="bottom">
                <Col span={4}>
                  <Button
                    onClick={onHandleBack}
                    type="text"
                    className="historyBtn"
                    ghost
                  >
                    <ArrowLeftOutlined
                      style={{
                        color: "#BDBDBD",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    />
                    <span
                      style={{
                        color: "#828282",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      목록
                    </span>
                  </Button>
                </Col>
                <Col span={4} offset={16}>
                  <Row justify="end">
                    <Button
                      onClick={() => history.push("/mypage/inquiry/write")}
                    >
                      문의하기
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="List"
            style={{
              maxWidth: "1200px",
              margin: "-5px auto",
              padding: "0 24px 24px",
            }}
          >
            <Space align="end" size="small" direction="vertical">
              {error === null ? (
                <Table
                  tableLayout="fixed"
                  expandRowByClick={true}
                  columns={columns}
                  dataSource={data}
                  onRow={onRow}
                  rowKey={totalCnt}
                  pagination={{
                    // defaultCurrent:1,
                    current: page + 1,
                    pageSize: 12,
                    total: totalCnt,
                    onChange: onChange,
                    size: "small",
                    showSizeChanger: false,
                    showQuickJumper: false,
                  }}
                />
              ) : (
                <>
                  <span>{error}</span>
                </>
              )}
            </Space>
          </div>
        </div>
      )}
    </>
  );
};

export default MyInquiry;
