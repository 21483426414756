import React from "react";
import auth from "services/firebase";
import { atom, selector } from "recoil";



// "common/state/ErrorModal" 경로에 있는 모달 컨트롤을 위한 atom.
export type modalType = {
  type?: "" | "error" | "success" | "fail" | "notfound" | "access"
  isActive: boolean;
  title: string,
  msg: string;
  redirect?: string;
  goBack?: Boolean;   // goBack: true면 redirect 무시하고 일단 전페이지로
};


export const modalState = atom<modalType>({
  key: "modalState",
  default: {
    isActive: false,
    type: "",
    title: "",
    msg: "",
    redirect: "",
    goBack: false,
  },
});
