import useGetList from "hooks/useGetList";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";
import { Card, Row, Col, Divider, Skeleton, Typography } from "antd";
import { defaultUrl } from "services/api.helpers";
import { PushpinTwoTone } from "@ant-design/icons";
import LoadingView from "common/view/LoadingView";

export default function NoticeBoard() {
  const { Title } = Typography;
  const setModal = useSetRecoilState(modalState);
  const { loading, data, error, refetch } = useGetList({
    url: `${defaultUrl}/boards?cate=notice&detail=true&rpp=3&page=0`,
  });

  return (
    <>
      <ErrorModal />
      {loading ? (
        <LoadingView />
      ) : error ? (
        setModal({
          type: "error",
          isActive: true,
          title: "게시글 정보를 가져올수 없습니다.",
          msg: `${error}`,
          redirect: `/`,
        })
      ) : (
        <Row className="card-container">
          <Col md={6} className="site-title-wrapper">
            <Title className="txt-page-title">공지사항</Title>
            <p className="txt-main-title">
              Adventure Design
              <br /> 강의에 관한 공지사항을 알려드립니다
            </p>
            <Link to="/notice" className="board-list-more">
              <img src="image/icon-plus.svg" alt="더보기" />
              더보기
            </Link>
          </Col>
          <Col md={18} className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={24} md={24} lg={8}>
                <Card bordered={false}>
                  <div className="card-wrap">
                    <PushpinTwoTone
                      className="card-pin"
                      twoToneColor="var(--point-color, #DDD)"
                    />
                    <div className="card-title">
                      {data[0] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[0]?.id}`}>
                          {data[0]?.title}
                        </Link>
                      )}
                    </div>
                    <div className="card-description">
                      {data[0] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[0]?.id}`}>
                          {data[0]?.description
                            .replace(/<[^>]+>/g, "")
                            .replace(/\&nbsp;/g, "")}
                        </Link>
                      )}
                    </div>
                    <div className="small-text">
                      {data[0] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[0]?.id}`}>
                          더보기 +
                        </Link>
                      )}
                      <Divider
                        style={{ margin: "2px 0", borderColor: "#DEDEDE" }}
                      />
                      {data[0] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <span>
                          {moment(data[0]?.createdAt).format("YY/MM/DD")}
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} md={24} lg={8}>
                <Card bordered={false}>
                  <div className="card-wrap">
                    <div className="card-title">
                      {data[1] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[1]?.id}`}>
                          {data[1]?.title}
                        </Link>
                      )}
                    </div>
                    <div className="card-description">
                      {data[1] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[1]?.id}`}>
                          {data[1]?.description
                            .replace(/<[^>]+>/g, "")
                            .replace(/\&nbsp;/g, "")}
                        </Link>
                      )}
                    </div>
                    <div className="small-text">
                      {data[1] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "20px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[1]?.id}`}>
                          더보기 +
                        </Link>
                      )}
                      <Divider
                        style={{ margin: "2px 0", borderColor: "#DEDEDE" }}
                      />
                      {data[1] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "50px" }}
                        />
                      ) : (
                        <span>
                          {moment(data[1]?.createdAt).format("YY/MM/DD")}
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} md={24} lg={8}>
                <Card bordered={false}>
                  <div className="card-wrap">
                    <div className="card-title">
                      {data[2] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[2]?.id}`}>
                          {data[2]?.title}
                        </Link>
                      )}
                    </div>
                    <div className="card-description">
                      {data[2] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[2]?.id}`}>
                          {data[2]?.description
                            .replace(/<[^>]+>/g, "")
                            .replace(/\&nbsp;/g, "")}
                        </Link>
                      )}
                    </div>
                    <div className="small-text">
                      {data[2] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "20px" }}
                        />
                      ) : (
                        <Link to={`/notice/detail/${data[2]?.id}`}>
                          더보기 +
                        </Link>
                      )}
                      <Divider
                        style={{ margin: "2px 0", borderColor: "#DEDEDE" }}
                      />
                      {data[2] == null ? (
                        <Skeleton.Input
                          size="small"
                          style={{ width: "50px" }}
                        />
                      ) : (
                        <span>
                          {moment(data[2]?.createdAt).format("YY/MM/DD")}
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
