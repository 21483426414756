import { useState, useEffect } from "react";
import auth from "services/firebase";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { authState, signUpUserEmail, userRoleState, accessEdit } from "recoil/authState";
import { defaultUrl } from "services/api.helpers";

const useAuthRecoil = () => ({
    user: useRecoilValue(authState),
    setUser: useSetRecoilState(authState),
    emailVeri: useSetRecoilState(signUpUserEmail),
    view: useRecoilValue(userRoleState),
    setView: useSetRecoilState(userRoleState),
    setaccessEdit: useSetRecoilState(accessEdit),
});

const useGetUserFirst = () => {

    const { user, setUser, emailVeri, setView, view, setaccessEdit } = useAuthRecoil();
    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null
    });

    const [trigger, setTrigger] = useState(0);

    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(Date.now());
    };


    const getUsertoDB = async (user) => {
        axios.get(`${defaultUrl}/users/${user.uid}`,{ 
            headers: { Authorization: user._lat },
          }).then(res => {
            const data = res.data;
            if(data.role === "ADM" || data.role === "CO" || data.role === "AS" || data.role === "PR"){
                setaccessEdit(true);
            }else{
                setaccessEdit(false);
            }
            const currentUser = {
                  id: data.id,
                  email: data.email,
                  role: data.role,
                  name: data.name,
                  idNumber: data.idNumber ?? "",
                  department: data.department ?? "",
                  emailVerified: data.emailVerified,
                  classList: data.classList ?? [],
                };
                setUser({...currentUser});
                setView(data.role);
                setState({
                    ...state,
                    loading: false,
                    data: data
                });
          }).catch(error =>{
            //   사용자 정보를 우리 DB에서 get 요청 오류.
            console.log(error);
            setState({
                ...state,
                loading: false,
                error: "사용자 정보를 받아오는데 실패했습니다.",
            });
            auth.signOut();
            return setView("ALL");
            });
        };


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if(user){
                if(user.emailVerified === false){
                    setView("ALL");
                    setState({
                        ...state,
                        loading: false,
                    });
                    return emailVeri(user.email);
                }
                else{
                    setView("");
                    return getUsertoDB(user);
                }
            }else{
                auth.signOut();
                setState({
                    ...state,
                    loading: false
                });
                setView("ALL");
                return;
            };
        });
    },[trigger]);

    return {...state, refetch};
};

export default useGetUserFirst;