import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ReactQuillEditor from "common/form/ReactQuillEditor";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Select, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createInquiryAnonymous } from "services/inquiryApi";
import "common/form/Form.css";
import useGetDetailWt from "hooks/useGetDetailWt";
import { defaultUrl } from "services/api.helpers";
import ErrorModal from "common/view/ErrorModal";

const InquiryAnonymous = ({ url, title }) => {
  const key = "updatable";
  const { Option } = Select;
  const [form] = Form.useForm();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _roleParam = !!params.get("role")
    ? params.get("role").toUpperCase()
    : null;
  const setModal = useSetRecoilState(modalState);

  const { bbsId } = useParams();
  const history = useHistory();

  const [quillData, setQuillData] = useState(null);
  const [apiLoading, setLoading] = useState(false);

  function onEditorChange(value) {
    setQuillData(value);
  }

  const HandleFinish = async (values) => {
    // console.log(values);
    let replaceQuillText = null;

    // console.log(quillData);

    if (quillData) {
      replaceQuillText = quillData.replace(/"/gi, `'`);
    }

    if (replaceQuillText) {
      const newValue = {
        title: values?.title,
        description: replaceQuillText,
        type: values?.type,
        category: "PRIVATE",
        classId: "",
      };

      // console.log(newValue);
      setLoading(true);
      return postDataApi(newValue);
    } else {
      const newValue = {
        title: values?.title,
        type: values?.type,
        category: "PRIVATE",
        classId: "",
      };
      setLoading(true);
      return postDataApi(newValue);
    }
  };

  const postDataApi = async (data) => {
    // console.log(`postDataApi: ${JSON.stringify(data)}`);
    createInquiryAnonymous(data)
      .then((e) => {
        setLoading(false);
        // console.log(`문의글이 정상적으로 작성되었습니다.`);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("inquiry create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `문의글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            // goBack: true
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("inquiry create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const openMessage = () => {
    message.success({
      content: "문의글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const handleChange = (...args) => {
    // searchInput.current.querySelector("input").value = "";
    // console.log("ARGS:", args);
    // console.log("CHANGE:");
    // setSelectValue(args[1].name);
    // props.setDepart(args[1].name);
  };
  const descriptionPresetSt =
    "<p>문제되는 내용을 아래 양식에 작성해 주세요.</p><p>------------------------------------------------------------</p><p>권한 : 수강생</p><p>이름 :  </p><p>학번 :  </p><p>메일 :  </p><p>학과 :  </p><p>남기고 싶은 말 :  </p>";
  const descriptionPresetAs =
    "<p>문제되는 내용을 아래 양식에 작성해 주세요.</p><p>------------------------------------------------------------</p><p>권한 : 조교</p><p>이름 :  </p><p>학번 :  </p><p>메일 :  </p><p>학과 :  </p><p>남기고 싶은 말 :  </p>";
  const descriptionPresetPr =
    "<p>문제되는 내용을 아래 양식에 작성해 주세요.</p><p>------------------------------------------------------------</p><p>권한 : 교수</p><p>이름 :  </p><p>메일 :  </p><p>학과 또는 소속 :  </p><p>남기고 싶은 말 :  </p>";
  const descriptionPresetCo =
    "<p>문제되는 내용을 아래 양식에 작성해 주세요.</p><p>------------------------------------------------------------</p><p>권한 : 기업멘토</p><p>이름 :  </p><p>메일 :  </p><p>학과 또는 소속 :  </p><p>남기고 싶은 말 :  </p>";

  let _description;
  switch (_roleParam) {
    case "ST":
      _description = descriptionPresetSt;
      break;
    case "AS":
      _description = descriptionPresetAs;
      break;
    case "PR":
      _description = descriptionPresetPr;
      break;
    case "CO":
      _description = descriptionPresetCo;
      break;
    default:
      _description = descriptionPresetSt;
      break;
  }

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          form={form}
          name="inquiryForm"
          layout="vertical"
          className="writeForm"
          onFinish={HandleFinish}
          onFinishFailed={HandleFinishFaild}
          initialValues={{
            type: "회원 가입",
            title: "",
            description: _description,
          }}
        >
          <Col span={2} style={{ paddingLeft: 20 }}>
            <Button onClick={onHandleBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
            </Button>
          </Col>
          <Row className="detailMainInfoWrap inquiryAnonymousFormWrap">
            <Col span={18} offset={3}>
              <h2>{title} 문의하기</h2>
            </Col>
          </Row>
          <Row style={{ padding: "0 20px" }}>
            <Col span={4} offset={0} xs={24} sm={24} md={4}>
              <Form.Item
                label="문의 유형"
                name="type"
                rules={[
                  { required: true, message: "제목을 입력하지 않았습니다." },
                ]}
              >
                <Select value="" onChange={handleChange}>
                  <Option value="회원 가입">회원 가입</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "0 20px", flexDirection: "column" }}>
            <Form.Item
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              rules={[
                { required: true, message: "제목을 입력하지 않았습니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
            >
              <ReactQuillEditor
                value={quillData}
                onEditorChange={onEditorChange}
              />
            </Form.Item>
            <Form.Item>
              {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 게시글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
              <Warning handleGoBack={onHandleBack} />
              <Button
                type="primary"
                htmlType="submit"
                className="write-submit-button"
              >
                작성
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default InquiryAnonymous;
