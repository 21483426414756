export const signInError = async (error, setModal) => {
    const errorCode = error.code;
    let errorMessage = error.message;
    // firebase의 errorCode.
    switch (errorCode) {
        case "auth/wrong-password":
            console.log(errorCode + errorMessage);
            errorMessage = "비밀번호를 잘못 입력하였습니다. 다시 시도하거나 비밀번호 찾기를 해주세요.";
            return setModal({
                type: "error",
                isActive: true,
                title: `잘못된 비밀번호`,
                msg: errorMessage,
                // redirect: `/auth/sign`
            });
        case "auth/user-not-found":
            console.log(errorCode + errorMessage);
            errorMessage = "해당 이메일로 가입된 사용자가 없습니다. 이메일 주소를 확인해주세요.";
            return setModal({
                type: "error",
                isActive: true,
                title: `가입되지 않은 사용자입니다.`,
                msg: errorMessage,
                redirect: `/auth/sign`
            });
        default:
            errorMessage = errorCode;
            return setModal({
                type: "error",
                isActive: true,
                title: error,
                msg: errorMessage,
                redirect: `/`
            });
    }
};
