import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "main/MainSlide.css";
import axios from "axios";
import { defaultUrl } from "services/api.helpers";

const MainSlide = () => {
  const location = useLocation();

  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [updateCount, setUpdateCount] = useState(0);
  const [slideIndex, setSlideIndex] = useState(1);
  const [mobile, setMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const slideImg = [
    {
      index: 0,
      url: "#",
      src: "/image/main-visual-desktop.png",
      srcM: "/image/main-visual-mobile.png",
      alt: "slide 01",
    },
  ];

  const [slideData, setSlideData] = useState(slideImg);
  const [bannerLoaded, setBannerLoaded] = useState(false);

  const slider = useRef();
  const slideMap = (slideNum) => {
    if (Array.isArray(slideNum)) {
      return slideNum.map((each, index) => (
        <div key={index}>
          <a href={!each.url ? "#" : each.url} key={each.index}>
            {mobile ? (
              <img
                src={
                  !bannerLoaded
                    ? slideImg[0].srcM
                    : !!each.src
                    ? (each.src !== slideImg[0].srcM ? defaultUrl + each.srcM : each.srcM)
                    : slideImg[0].srcM
                }
                alt={"메인배너" + (each.index + 1)}
                style={imgStyleM}
              />
            ) : (
              <img
                src={
                  !bannerLoaded
                    ? slideImg[0].src
                    : !!each.src
                    ? (each.src !== slideImg[0].src ? defaultUrl + each.src : each.src)
                    : slideImg[0].src
                }
                alt={"메인배너" + (each.index + 1)}
                style={imgStyle}
              />
            )}
          </a>
        </div>
      ));
    }
  };

  const slideLength = slideData.length;
  const getDataAPI = async () => {
    axios
      .get(`${defaultUrl}/banners`)
      .then((res) => {
        if (!!res.data && res.data?.length > 0) {
          setSlideData(res.data[0].info);
        }
        setBannerLoaded(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errorCode === "NOT_EXIST_TYPE_CATEGORY") {
          }
        }
      });
  };

  useEffect(() => {
    getDataAPI();
    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    if (width < 480) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    return () => {};
  }, [width]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onNext = () => {
    setNext(slider.current.slickNext());
  };

  const onPrevious = () => {
    setPrevious(slider.current.slickPrev());
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    swipe: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next + 1),
  };

  const imgStyle = {
    margin: "0 auto",
    width: "100%",
    maxWidth: "100%",
  };

  const imgStyleM = {
    margin: "0 auto",
    width: "100%",
    maxWidth: "100%",
  };

  return (
    <div className="poster">
      <Slider
        ref={(c) => {
          slider.current = c;
        }}
        {...settings}
      >
        {slideMap(slideData)}
      </Slider>
    </div>
  );
};

export default MainSlide;
