import { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import "common/header/HeaderMenu.css";
import MobileNav from "common/header/HeaderMenuMobile";
import { Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import auth from "services/firebase";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { authState, userRoleState } from "recoil/authState";
import { gnb } from "common/header/menuList";

const HeaderMenu = ({ data }) => {
  const userState = useRecoilValue(authState);
  const roleState = useSetRecoilState(userRoleState);
  const resetUserState = useResetRecoilState(authState);
  const history = useHistory();

  const signOut = async () => {
    roleState("ALL");
    await resetUserState;
    await auth.signOut();
    window.location.reload();
    return history.push("/");
  };

  const setSignInState = () => {
    // return userState.id == "" ? (
    return !data ? (
      <div className="textNav">
        <NavLink to="/auth/sign">
          <UserOutlined className="onlyMobile" />{" "}
          <span className="sign-text">로그인 / 회원가입</span>
        </NavLink>
      </div>
    ) : (
      <Row className="login" justify="end">
        <Col span="12" className="textNav">
          <NavLink to="/" onClick={() => signOut()}>
            로그아웃
          </NavLink>
        </Col>
        <Col span="12" xs={24} md={12} className="textNav">
          <NavLink to="/mypage">
            <UserOutlined className="onlyMobile" /> 마이페이지
          </NavLink>
        </Col>
      </Row>
    );
  };

  const DeskNav = () => {
    const [openList, setOpenList] = useState({
      display: "none",
    });
    const openMenu = (e) => {
      //props.style({ display: "block" });
      //setchild({ display: "block" });
      setOpenList({ display: "block" });
    };
    const closeMenu = () => {
      setOpenList({ display: "none" });
    };
    return (
      <Col
        className="main-menu-wrap"
        onMouseLeave={closeMenu}
        onMouseEnter={openMenu}
      >
        <Row justify="space-around">
          {gnb.map((section, index) => (
            <Col
              className="navMenu"
              span="4"
              key={section.key == null ? "0" : section.key}
            >
              {section.key == null ? null : (
                <Link to={section.menu1Link}>{section.cate}</Link>
              )}
              <div className="submenuDiv">
                <ul className={section.key} style={openList}>
                  <li>
                    {section.menu1Link == null ? null : (
                      <Link to={section.menu1Link}>{section.menu1}</Link>
                    )}
                  </li>
                  <li>
                    {section.menu2Link == null ? null : (
                      <Link to={section.menu2Link}>{section.menu2}</Link>
                    )}
                  </li>
                </ul>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    );
  };

  return (
    <header className="header-navigation">
      <nav className="gnb">
        <Row className="navUl" justify="space-between" align="middle">
          <Col span={7} md={5} sm={15} xs={14} className="logo">
            <NavLink to="/" className="logo-link">
              <img
                src="/image/logo.png"
                alt="동국대학교 어드벤처 디자인"
                className="logo-img"
              />
            </NavLink>
          </Col>
          <Col span={14} lg={14} md={0} sm={0} xs={0} className="deskNav">
            <DeskNav />
          </Col>
          <Col span={3} md={15} lg={3} sm={5} className="navtextUl">
            {setSignInState()}
          </Col>
          <Col xs={3} sm={3} lg={0}>
            <MobileNav />
          </Col>
        </Row>
      </nav>
    </header>
  );
};

export default HeaderMenu;
