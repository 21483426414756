import { useState, useEffect } from "react";
import { MailTwoTone, setTwoToneColor } from "@ant-design/icons";
import { Button, Col, Row, message } from "antd";
import ErrorModal from "common/view/ErrorModal";
import { modalState } from "recoil/modalState";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import auth from "services/firebase";

const SentEmail = () => {
  setTwoToneColor("#F3850E");
  const key = "updatable";
  const setModal = useSetRecoilState(modalState);
  const history = useHistory();
  const [user, setUser] = useState({});
  // 이 페이지에 접근할 때, 회원중에 인증안된 회원만 접근하도록 useEffect 사용해야 함.

  useEffect(() => {
    if (auth.currentUser == null || "") {
      return history.goBack();
    } else {
      setUser(auth.currentUser);
    }
  }, []);

  const onClickEmailVerification = async () => {
    user
      .sendEmailVerification({
        url:
          window.location.protocol +
          "//" +
          window.location.host +
          "/?verified=true",
      })
      // .sendEmailVerification({url: "https://default-dev-dot-donggukuniv.du.r.appspot.com/?verified=true"})
      .then(() => {
        // 위 url에서 도메인은 등록된 도메인만 가능하여 뒤쪽에 query를 넣어 이를 통해 리다이렉트
        return openMessage();
      })
      .catch((err) => {
        console.log(`이메일 전송 에러${err}`);
        setModal({
          type: "error",
          isActive: true,
          title: `이메일 전송 에러 발생.`,
          msg: `${err}`,
          redirect: `/`,
        });
        return history.replace("/auth/sign");
      });
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    message.success({
      content: "이메일 전송이 완료되었습니다.",
      key,
      duration: 2,
    });
    return history.replace("/");
  };

  return (
    <>
      <ErrorModal />
      <ul className="email-text-wrap">
        <li>
          <MailTwoTone style={{ fontSize: "82px" }} />
        </li>
        <li className="email-title-text">인증 메일을 발송했습니다.</li>
        <li>{user.email}</li>
        <li>이메일 인증 완료 후 로그인 해주세요.</li>
        <li>인증메일을 받지 못했다면, '인증메일 재전송' 버튼을 눌러</li>
        <li>메일 인증을 다시 해주세요.</li>
      </ul>
      <div className="email-button-wrap">
        <Row>
          <Col span={12}>
            <Button
              onClick={null}
              size="large"
              className="email-button"
              onClick={() => onClickEmailVerification()}
            >
              인증메일 재전송
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={() => history.replace("/auth/sign")}
              className="email-button"
            >
              확인
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SentEmail;
