import { useState, useEffect, useParams } from "react";
import { useHistory } from "react-router-dom";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Select, Checkbox, Typography, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createBoard } from "services/boardApi";
import "common/form/Form.css";
import ThumbUpload from "contest/best/component/ThumbUpload";
import { defaultUrl } from "services/api.helpers";
//import useAxiosList from "hooks/useGetList";
//import ThumbUpload from './ThumbUpload';
import axios from "axios";
import SearchInput from "common/board/SearchInput";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BestWrite = ({ url }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const history = useHistory();
  const [detailData, setDetailData] = useState({
    category: "contbest",
    title: "",
    writer: "",
    description: "",
    semesterCode: "",
    info: {
      year: null,
      major: null,
      exhibitor: null,
      thumbnail: null,
      workTitle: null,
    },
  });

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState({
    type: null,
  });
  const [depart, setDepart] = useState("");

  // 현재 연도부터 howLong개의 연도 리스트 생성해주는 함수 by Sarc
  const yrs = (howLong) => {
    const _nowYear = new Date().getFullYear();
    const result = [];
    for (let i = 0; i < howLong; i++) {
      result.push(
        <option key={i} value={_nowYear - i}>
          {_nowYear - i}년
        </option>
      );
    }
    return result;
  };

  // 업로드된 썸네일 url 가져오는거.
  const [thumbUrlData, setthumbUrlData] = useState(null);
  const getThumbData = (media) => {
    setthumbUrlData(media);
    //console.log(media);
  };

  const handleGoBack = () => {
    history.push(`/cont/best`);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const HandleFinish = (values) => {
    setLoading(true);
    // console.log(`HandleFinish: ${JSON.stringify(values)}`);

    const newValue = Object.assign(detailData, values);

    if (depart === "") {
      console.log("학과미선택");
      setModal({
        type: "error",
        isActive: true,
        title: `학과 미선택`,
        msg: `학과가 선택되지 않았습니다.`,
        // redirect: `/`
      });
      return;
    }

    newValue["info"] = {
      year: values.semesterCode,
      major: depart,
      exhibitor: values.exhibitor,
      thumbnail: thumbUrlData,
      workTitle: values.workTitle,
    };
    newValue["semesterCode"] = values.semesterCode;
    //console.log("value : ",values)
    //console.log("data : ", detailData)

    return postDataApi();
  };

  const postDataApi = async () => {
    // console.log(`postDataApi: ${JSON.stringify(detailData)}`);
    await createBoard(detailData)
        .then((e) => {
          setLoading(false);
          // console.log(`게시글이 정상적으로 작성되었습니다.`);
          if (e?.status === 'success') {
            openMessage();
            return onHandleBack();
          } else {
            console.log('board create failed - ', JSON.stringify(e?.result));
            return setModal({
              type: "error",
              isActive: true,
              title: "작성 실패",
              msg: `게시글 작성에 실패하였습니다.${(!!e.result?.errorCode && !!e.result?.message) ? '('+e.result?.errorCode+': '+e.result?.message+')' : ''}`,
              // redirect: `/`,
              goBack: true
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log('board create failed - ', JSON.stringify(e));
          onHandleBack();
        });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    setTimeout(() => {
      message.success({
        content: "새 게시글이 정상적으로 등록되었습니다.",
        key,
        duration: 2,
      });
    }, 2000);
  };

  const { Option } = Select;
  const { Text, Title } = Typography;
  const [errMsg, setErrMsg] = useState(null);

  function handleChange(year) {
    // console.log(`Selected year: ${year}`);
  }

  const [imgErr, setImgErr] = useState(false);
  useEffect(() => {
    if (thumbUrlData == null) {
      setImgErr(true);
    } else {
      setImgErr(false);
    }
  }, [thumbUrlData]);

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          name="writeForm"
          layout="vertical"
          className="writeForm"
          onFinish={HandleFinish}
          onFinishFailed={HandleFinishFaild}
        >
          <Col span={2}>
            <Button onClick={onHandleBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
              <span className="list-text-bt">목록</span>
            </Button>
          </Col>
          <Row className="detailMainInfoWrap">
            <Col span={18} offset={3}>
              <h2>우수작 소개 글쓰기</h2>
            </Col>
            <Col span={3} xs={24} md={3}>
              <Form.Item
                label="작성자"
                name="writer"
                initialValue="관리자"
                className="writer-input-title"
              >
                <Input value="관리자" className="writer-input" disabled bordered={false} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mainInfo">
            <Col span={24}>
              <Row gutter={16}>
                <Col span={8} xs={24} sm={12} md={10} lg={9} xl={8}>
                  <Form.Item
                    label="연도"
                    name="semesterCode"
                    rules={[{ required: true, message: "연도를 선택하지 않았습니다." }]}
                  >
                    <Select value="연도" onChange={handleChange}>
                      {yrs(5)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={16} xs={24} sm={12} md={14} lg={15} xl={16}>
                  <Form.Item
                    label="제목"
                    name="title"
                    placeholder="제목을 입력해 주세요."
                    rules={[{ required: true, message: "제목을 입력하지 않았습니다." }]}
                  >
                    <Input maxLength={50} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16} justify="start">
                <Col className="thumbCol" span={8} xs={24} sm={12} md={10} lg={9} xl={8}>
                  <Form.Item
                    label="썸네일 이미지"
                    name="thumbnail"
                    valuePropName="thumbnail"
                    value={detailData.info.thumbnail}
                  >
                    <ThumbUpload
                      mode={mode}
                      thumbUrlData={thumbUrlData}
                      value={detailData.info.thumbnail}
                      getThumbData={getThumbData}
                      detailData={detailData}
                    />
                  </Form.Item>
                  {imgErr ? (
                    <Text type="danger" className="imgErrMsg">
                      이미지를 등록해 주세요.
                    </Text>
                  ) : null}
                </Col>
                <Col span={16} xs={24} sm={12} md={14} lg={15} xl={16}>
                  <SearchInput setDepart={setDepart} />
                  <Form.Item
                    label="성명"
                    name="exhibitor"
                    placeholder="성명을 입력해 주세요."
                    rules={[{ required: true, message: "성명을 입력하지 않았습니다." }]}
                  >
                    <Input className="shortInput" />
                  </Form.Item>
                  <Form.Item
                    label="작품명"
                    name="workTitle"
                    rules={[{ required: true, message: "작품명을 입력하지 않았습니다." }]}
                  >
                    <Input className="workTitle" placeholder="작품명을 입력해 주세요." />
                  </Form.Item>
                  <Text
                    type="danger"
                    style={{
                      position: "absolute",
                      display: "inline-block",
                      maxWidth: "300px",
                      width: "100%",
                      bottom: "-25px",
                      left: 20,
                    }}
                  >
                    {errMsg !== null ? errMsg : null}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="description-wrap">
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
              rules={[{ required: true, message: "내용을 입력하지 않았습니다." }]}
            >
              <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
              {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 게시글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
              <Warning handleGoBack={handleGoBack} />
              <Button type="primary" htmlType="submit" className="write-submit-button">
                등록
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default BestWrite;
