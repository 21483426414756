import { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useParams } from "react-router-dom";
import BoardDetailContent from "common/board-api/BoardDetailContent";
import BoardDetailContentAttach from "common/board-api/BoardDetailContentAttach";
import WithSkeleton from "common/board/WithSkeleton";
import useGetDetail from "hooks/useGetDetail";
import { defaultUrl } from "services/api.helpers";

const BoardDetail = ({ type, url }) => {
  const { bbsId } = useParams();
  // console.log(`bbsId is ${type}`);
  const { loading, data, error, refetch } = useGetDetail({
    url: `${defaultUrl}/boards/${bbsId}`,
  });

  return (
    <>
      {loading ? (
        <WithSkeleton detailData={data} loading={loading} />
      ) : type === "basic" ? (
        <BoardDetailContent detailData={data} loading={loading} url={url} />
      ) : type === "attach" ? (
        <BoardDetailContentAttach
          detailData={data}
          loading={loading}
          url={url}
        />
      ) : (
        <BoardDetailContent detailData={data} loading={loading} url={url} />
      )}
    </>
  );
};

export default BoardDetail;
