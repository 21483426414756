import React, {useState, useEffect} from "react";
import {useRecoilValue} from "recoil";
import {accessEdit} from "recoil/authState";
import {useLocation, useHistory, useParams} from 'react-router-dom'
import {Space, Button, Typography, Row, Col, Form, Image, Skeleton, Alert} from 'antd';
import {defaultUrl, refreshToken} from "services/api.helpers";
import {ArrowLeftOutlined, RightCircleFilled} from '@ant-design/icons';
import {deleteBoard} from "services/boardApi";
import ReactQuill from 'react-quill';
import moment from 'moment';
import axios from "axios";
import DetailHeaderPublic from "common/board-api/DetailHeaderPublic";
import DetailHeaderAdm from "common/board-api/DetailHeaderAdm";

const {Title, Text} = Typography;

export default function BestDetailContent({detailData, url}) {
    const isEdit = useRecoilValue(accessEdit);
    let bbsNum = useParams();
    const bbsId = bbsNum?.bbsId;
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();

    const curpathpoint = location.pathname.indexOf("/detail/");
    const prevPath = location.pathname.slice(0, curpathpoint);
    const curpath = location.pathname.slice(0, curpathpoint + 8);
    const onHandleBack = () => {
        history.push(prevPath);
    };

    const handleDelete = async () => {
        const result = await deleteBoard(detailData.id);
        console.log(result);
        if (result === "success") {
            return history.push("/cont/best");
        } else {
            console.log(JSON.stringify(result));
        }
    };

    // date 포맷 state
    const [duration, setDuration] = useState(null);
    const [dayFormat, setDayFormat] = useState(null);
    const [localToken, setLocalToken] = useState();

    // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
    const [fee, setFee] = useState(0);

    // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
    function numberWithComma(x) {
        return setFee(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }

    const courseDeleteConfirm = async (event) => {
        console.log(event);
        console.log(event.target.value);
        await deleteCourse(event.target.value);
        return history.push({
            pathname: '/cont/best',
            state: {
                delete: true,
                id: bbsId
            }
        });
    };

    const deleteCourse = () => {
        return axios.delete(`${defaultUrl}/board/${bbsId}`, {
            headers: {
                "Authorization": localToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(() => {
                console.log('board deleted');
            });
    };

    const updateBoard = () => {
        history.push(`/cont/best/write/${bbsId}`);
    };


    return (
        <Row wrap align="middle" justify="start" style={{overflow: "hidden"}}>
            <Row className="programDetailInfoWrap"
                 style={{border: "none", margin: "0 auto"}}> {/*임시로 style 강제해둠. 추후 수정 요망 by Sarc*/}
                {isEdit ? (
                    <DetailHeaderAdm url={url} bbsId={detailData.id}/>
                ) : (
                    <DetailHeaderPublic url={url}/>
                )}
            </Row>
            <Row className="programDetailInfoWrap">
                <Col className="info-first-col" span={24} lg={24} xl={8} xxl={8}>
                    <Space align="center" direction="vertical">
                        <Image
                            className="card-thumbnail-img"
                            src={defaultUrl + detailData.info.thumbnail}
                            alt="thumbnail"
                            preview={false}/>
                    </Space>
                </Col>
                <Col className="info-second-col" span={24} lg={24} xl={16} xxl={16}>
                    <Row>
                        <Col span={24}>
                            <Title level={1}>{detailData.title}</Title>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="listCol" span={24}>
                            <ul>
                                <li><span className="listTitle">대회 연도</span><span
                                    className="listData">{detailData.info.year} 년 대회</span></li>
                                <li><span className="listTitle">학과명</span><span
                                    className="listData">{detailData.info.major}</span></li>
                                <li><span className="listTitle">성명</span><span
                                    className="listData">{detailData?.info?.exhibitor}</span></li>
                                <li><span className="listTitle">작품명</span><span
                                    className="listData">{detailData?.info?.workTitle}</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{width: '100%', maxWidth: 1000, margin: "30px auto 0", textAlign: 'left'}}>
                <RightCircleFilled style={{fontSize: 14, marginRight: 10}}/><Text strong
                                                                                  style={{fontSize: 16}}>작품소개</Text>
            </div>
            <Row className="qulii-body-row" align="middle" justify="center" style={{padding: "0 24px 24px"}}>
                {detailData ? (
                    <>
                        <ReactQuill
                            value={detailData.description}
                            readOnly={true}
                            theme={"bubble"}
                        />
                        <Col span={24} style={{margin: "25px 0"}}>
                            {detailData.body}
                        </Col>
                    </>
                ) : (
                    <>
                        <div>로딩중...</div>
                    </>
                )}
            </Row>
        </Row>
    );
}
