import { useState, useEffect } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  defalutValue_className_ArdMaterial,
  defalutValue_Sem_ArdMaterial,
  defalut_sem_Selector_ArdMaterial,
  semState_ArdMaterial,
  semState_Selector_ArdMaterial,
  classState_ArdMaterial,
  classListState_ArdMaterial,
  semListState_ArdMaterial,
} from "recoil/filterState";
import { defaultUrl } from "services/api.helpers";
import { Select, Row, Col, Button } from "antd";
import "common/board-api/BoardHeader.css";
import LoadingView from "common/view/LoadingView";
import WriteButton from "common/board/WriteButton";
import { useHistory, useLocation } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";

const SelectHeaderArduMaterial = ({ refetchList, parentLoading }) => {
  const { Option } = Select;
  const history = useHistory();
  const location = useLocation();

  const [defalut_className, setDefalut_className] = useRecoilState(
    defalutValue_className_ArdMaterial
  );
  const [defalut_sem_value, setDefalut_sem] = useRecoilState(
    defalutValue_Sem_ArdMaterial
  );
  const defalut_sem = useRecoilValue(defalut_sem_Selector_ArdMaterial);
  const [semList, setSemList] = useRecoilState(semListState_ArdMaterial);
  // const [semester, setSemester] = useState(null);
  const [loading, setLoading] = useState(true);
  const setSem = useSetRecoilState(semState_ArdMaterial);
  const sem = useRecoilValue(semState_Selector_ArdMaterial);
  const [classId, setClassId] = useRecoilState(classState_ArdMaterial);
  const [classList, setClassList] = useRecoilState(classListState_ArdMaterial);

  const getSemesterList = async () => {
    await axios.get(`${defaultUrl}/semesters`).then((data) => {
      setSemList(data.data);
      return setLoading(false);
    });
  };

  const getClassList = async (value) => {
    await axios.get(`${defaultUrl}/classes?sem=${value}`).then((data) => {
      if (data.data.totalCount === 0) {
        setClassList([]);
      }
      setClassList(data.data.results);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (semList == null) {
      setLoading(true);
      getSemesterList();
    } else {
      setLoading(false);
    }
    if (!classList) {
      getClassList(defalut_sem_value);
    }
  }, []);

  function onChangeSEM(value) {
    setClassId({
      id: "",
      className: "전체 분반",
    });
    setSem(value.value);
    return getClassList(value.value);
  }

  function onSelect(value) {
    if (value.value === "ALL") {
      if (sem) {
        setClassId({
          id: "",
          className: "전체 분반",
        });
        refetchList();
      } else {
        setClassId({
          id: "",
          className: "전체 분반",
        });
        setSem(defalut_sem_value);
      }
    } else {
      setClassId({
        id: value.value,
        className: value.label,
      });
    }
  }

  const resetState = async () => {
    setSem("");
    await setClassId({
      id: "",
      className: "",
    });
    return refetchList();
  };

  return (
    <>
      {parentLoading ? (
        <LoadingView />
      ) : (
        <div className="board-list-header-wrap">
          <Row justify="start" className="flilter-wrap">
            <Col sm={20} xs={18}>
              <Row>
                <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                  <Select
                    loading={loading}
                    labelInValue
                    defaultValue={sem ? sem : defalut_sem}
                    style={{ width: "90%" }}
                    onChange={onChangeSEM}
                  >
                    {semList !== null ? (
                      semList.map((item, index) => {
                        let i = item.semesterCode.toString();
                        let yr = i.substring(0, 4);
                        let semester = i.substring(4, 5);
                        return (
                          <Option
                            value={item.semesterCode}
                            key={index}
                            name={yr + "년" + semester + "학기"}
                          >
                            {yr}년 {semester}학기
                          </Option>
                        );
                      })
                    ) : (
                      <Option>학기를 선택해 주세요.</Option>
                    )}
                  </Select>
                </Col>
                <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                  <Select
                    loading={loading}
                    labelInValue
                    defaultValue={
                      classId.className ? classId.className : defalut_className
                    }
                    style={{ width: "90%" }}
                    onSelect={onSelect}
                  >
                    <Option key={0} value="ALL" name="전체 분반">
                      전체 분반
                    </Option>
                    {classList !== null ? (
                      classList.map((item, i) => (
                        <Option key={++i} value={item.id} name={item.className}>
                          {item.className}
                        </Option>
                      ))
                    ) : (
                      <Option>분반을 선택해 주세요.</Option>
                    )}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    icon={<SyncOutlined />}
                    className="filter-reset-bt"
                    type="ghost"
                    onClick={() => resetState()}
                  >
                    나의 분반
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={4} xs={6}>
              <Button
                onClick={() => history.push(`${location.pathname}/write`)}
              >
                글쓰기
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default SelectHeaderArduMaterial;
