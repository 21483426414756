import { useState, useEffect } from "react";
import axios from "axios";
import auth from "services/firebase";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { authState, idTokenState, uidState } from "recoil/authState";
import { viewRoleState } from "recoil/viewState";
import { refreshToken, defaultUrl } from "services/api.helpers";
import { useHistory } from "react-router-dom";


export const putUserInfo = async (user, userData) => {
  await axios
    .put(`${defaultUrl}/users/${userData.id}`, userData, {
      headers: { Authorization: user._lat },
    })
    .then((data) => {
      // console.log(`사용자 정보가 업데이트 되었습니다`);
    })
    .catch((error) => {
      if (error.response) {
        // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
        console.log("response data : " + error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
        // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
        // node.js에서는 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
      } else {
        // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};


export const getUserData = (userId, idToken) => {
 const result = axios.get(`${defaultUrl}/users/${userId}`,{
  headers: { Authorization: idToken },
    }).then(res => {
      // console.log(`getUserdata 는 : ${JSON.stringify(res.data)}`);
          return res.data;
    }).catch(error =>{
        console.log(error);
        return null;
      });
      return result;
};
