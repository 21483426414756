// services
import axios from "axios";
import auth from "services/firebase";

let defaultApiAddress;
let defaultHeaders;
let defaultHeadersImg;
let userApi = [];
let bbsApi = [];
let courseApi = [];
let imageApi = [];
let userToken = '';

export const defaultUrl = getDefaultUrl(window.location.hostname);
// export const defaultUrl = 'https://dongguk-api-total-dev-dot-donggukuniv.du.r.appspot.com';
// export const defaultUrl = 'https://dongguk-api-total-dot-donggukuniv.du.r.appspot.com';

function getDefaultUrl (url) {
    switch (url) {
        // case 'isbcc.or.kr':
        //     defaultApiAddress = '//dongguk-api-total-dot-donggukuniv.du.r.appspot.com';
        //     break;
        // case 'www.isbcc.or.kr':
        //     defaultApiAddress = '//dongguk-api-total-dot-donggukuniv.du.r.appspot.com';
        //     break;
        // case 'dev.isbcc.or.kr':
        //     defaultApiAddress = '//dongguk-api-total-dev-dot-donggukuniv.du.r.appspot.com';
        //     break;
        case 'donggukuniv.du.r.appspot.com':
            return '//dongguk-api-total-dot-donggukuniv.du.r.appspot.com';
        case 'default-dev-dot-donggukuniv.du.r.appspot.com':
            return '//dongguk-api-total-dev-dot-donggukuniv.du.r.appspot.com';
        case 'localhost':
            return 'https://dongguk-api-total-dev-dot-donggukuniv.du.r.appspot.com';
        case 'deec.dongguk.edu':
            return '//dongguk-api-total-dot-donggukuniv.du.r.appspot.com';
        default:
            return '//dongguk-api-total-dot-donggukuniv.du.r.appspot.com';
    }
}

auth.onAuthStateChanged((user) => {
    if (user) {
        userToken = user._lat;
        // console.log('로그인 됨 - '+userToken);
        defaultHeaders = {
            "Authorization" : userToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        };
        defaultHeadersImg = {
            "Authorization" : userToken,
            "Access-Control-Allow-Origin" : "*",
        };
    } else {
        // console.log('로그인 안됨');
        defaultHeaders = {
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        };
        defaultHeadersImg = {
            "Access-Control-Allow-Origin" : "*",
        };
    }
});

const refreshToken = ()=>{
    return new Promise(async (resolve,reject) => {
        if (!!auth.currentUser) {
            auth.currentUser.getIdToken(true).then(function (idToken) {
                // setToken(idToken);
                // window.localStorage.setItem("accessToken", JSON.stringify(idToken));
                // console.log('localStorage 저장(api helper) - ', window.localStorage.getItem("accessToken"));
                resolve(idToken);
            }).catch(function (error) {
                reject("만료됨");
            });
        }
    });
};

export const idToken = async() => await auth.currentUser?.getIdToken();

export {defaultApiAddress, defaultHeaders, defaultHeadersImg, userApi, bbsApi, courseApi, refreshToken}
