import { useState, useEffect } from "react";
import defaultAxios from "axios";

const useAxiosList = (opts, axiosInstance = defaultAxios) => {
    const [ totalCnt, setTotalCnt ] = useState("");
    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null
    });

    const [trigger, setTrigger] = useState(0);

    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(Date.now());
    };

    useEffect(() => {
        if(!opts.url){
            return;
        }else{
          axiosInstance(opts)
         .then(response => {
            setState({
                ...state,
                loading: false,
                data: response.data.results,
            });
            setTotalCnt(response.data.totalCount);
            return setNumbering(response.data.results);
        }).catch(error => {
            setState({
                ...state,
                loading: false,
                error
            });
        })  
        }
    },[trigger]);


    const setNumbering = (data) => {
        let newData = [];
        const reverseArray = data.reverse();
    
        reverseArray.map((list, index) => {
            let listData;
            return (
            (listData = Object.assign({ ...list, listNo: index + 1 })),
            newData.push(listData)
            );
        });
        const dataSort = newData.reverse();
    
        if (dataSort !== null) {
            return setState({...state, data:dataSort, loading:false});
        }
    };
    

    return {...state, refetch, totalCnt};
};

export default useAxiosList;