import React, { useState, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Form, Input, Row, Col, Button, message, Checkbox } from "antd";
import ErrorModal from "common/view/ErrorModal";
import {authState, User} from "recoil/authState";
import { modalState } from "recoil/modalState";
import { viewRoleState, viewSignUpState } from "recoil/viewState";
import AuthHeader from "auth/AuthHeader";
import axios from "axios";
import {defaultUrl} from "services/api.helpers";
import { termsText } from "auth/terms";

const UserCheck = () => {
  type status = "" | "success" | "warning" | "error" | "validating" | undefined;

  type userValue = {
    name: string;
    idNumber?: string;
    email?: string;
    checked?: boolean;
  };

  const roleView = useRecoilValue(viewRoleState);
  const setView = useSetRecoilState(viewSignUpState);
  const setModal = useSetRecoilState(modalState);

  const [userState, setUserState] = useRecoilState(authState);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isAgreeError, setIsAgreeError] = useState({
    height: 20,
    opacity: 0,
  });

  useEffect(() => {
    if(isAgree === true){
      setIsAgreeError({
        ...isAgreeError,
        opacity: 0,
      });
    }
  },[isAgree])

  const [roleTitle, setRoleTitle] = useState("");
  const [inputStatus, setStatus] = useState<status>("");

  useEffect(() => {
    switch (roleView) {
      case "ST":
        return setRoleTitle("수강생");
      case "PR":
        return setRoleTitle("교수님");
      case "AS":
        return setRoleTitle("조교");
      case "CO":
        return setRoleTitle("기업 멘토님");
    }
  }, [roleView]);

  const classUserExist = (role: string, name: string, idNumber: string, email: string) => {
    if (role === 'StAs') {
      const newUser = {
        name: name,
        idNumber: idNumber,
      };
      const newUserValue = Object.assign(userState, newUser);
      setUserState(newUserValue);
      return setView("SignUpStAo");
    } else if (role == 'PrCo') {
      const newUser = {
        name: name,
        email: email,
      };
      const newUserValue = Object.assign(userState, newUser);
      setUserState(newUserValue);
      return setView("SignUpMento");
    }
  }

  const checkStAs = (name: string, idNumber: string) => {
    axios
        .get(`${defaultUrl}/class-users?role=${'as,st'}&chkname=${name}&chkidnum=${idNumber}&chkemail=`, {
        })
        .then((res) => {
          const _totalCount = res.data.totalCount;
          const _email = res.data.email;
          const _verified = res.data.verified;
          if (_totalCount > 0 && !_email) {
            classUserExist('StAs', name, idNumber, "");
            setStatus("success");
            message.success("확인되었습니다.");
          } else if (_totalCount > 0 && !!_email) {
            setStatus("warning");
            switchToSignInTab();
            if (_verified) {
              return setModal({
                type: "error",
                isActive: true,
                title: `이미 가입된 학번입니다.`,
                msg: `해당 학번이 이미 가입되어 있습니다. ${_email} 메일로 로그인해주세요.`,
              });
            } else {
              return setModal({
                type: "error",
                isActive: true,
                title: `이미 가입된 학번`,
                msg: `${_email} 메일로 이메일 인증 후 로그인해주세요.`,
              });
            }
          } else if (_totalCount === 0) {
            setStatus("warning");
            return setModal({
              type: "error",
              isActive: true,
              title: `등록된 ${(roleView === 'ST'?'학생이':'조교가')} 아닙니다.`,
              msg: `이름과 학번을 확인해 주세요. 동일 오류가 반복되면 관련 담당자에게 문의해주시기 바랍니다.`,
            });
          }
        })
        .catch((error) => {
          setStatus("warning");
          console.log('error-'+JSON.stringify(error));
          return setModal({
            type: "error",
            isActive: true,
            title: `요청 실패`,
            msg: `서버 상태에 문제가 있습니다. 잠시 후에 다시 시도해 주시기 바랍니다.(error:${JSON.stringify(error?.message)})`,
          });
        });
  };

  const checkPrCo = (name: string, email: string) => {
    axios
        .get(`${defaultUrl}/class-users?role=${'pr,co'}&chkname=${name}&chkidnum=&chkemail=${email}`, {
        })
        .then((res) => {
          const _totalCount = res.data.totalCount;
          const _email = res.data.email;
          const _verified = res.data.verified;
          if (_totalCount > 0 && !_email) {
            classUserExist('PrCo', name, "", email);
            setStatus("success");
            message.success("확인되었습니다.");
          } else if (_totalCount > 0 && !!_email) {
            setStatus("warning");
            switchToSignInTab();
            if (_verified) {
              return setModal({
                type: "error",
                isActive: true,
                title: `이미 가입된 이메일`,
                msg: `이미 가입되어 있습니다. ${_email} 메일로 로그인해주세요.`,
              });
            } else {
              return setModal({
                type: "error",
                isActive: true,
                title: `이미 가입된 이메일`,
                msg: `${_email} 메일로 이메일 인증 후 로그인해주세요.`,
              });
            }
          } else if (_totalCount === 0) {
            setStatus("warning");
            return setModal({
              type: "error",
              isActive: true,
              title: `등록된 ${roleTitle}이 아닙니다.`,
              msg: `이름과 이메일을 확인해 주세요. 동일 오류가 반복되면 관련 담당자에게 문의해주시기 바랍니다.`,
            });
          }
        })
        .catch((error) => {
          setStatus("warning");
          console.log('error-'+JSON.stringify(error));
          return setModal({
            type: "error",
            isActive: true,
            title: `요청 실패`,
            msg: `서버 상태에 문제가 있습니다. 잠시 후에 다시 시도해 주시기 바랍니다.(error:${JSON.stringify(error?.message)})`,
          });
        });
  };

  const onFinish = (value: userValue) => {
    console.log(value);
    console.log(isAgree);
    if(isAgree === true){
      setStatus("validating");
      if (roleView === "ST" || roleView === "AS") {
        return checkStAs(value.name, value.idNumber!);
      } else {
        return checkPrCo(value.name, value.email!);
      }
    }else{
      setIsAgreeError({
        ...isAgreeError,
        opacity: 1,
      });
    }
  };

  const checkInput = ()=>{
    setStatus("");
  }

  // 로그인 회원가입 페이지가 동일한 페이지에서 토글되므로, 수동으로 탭전환하는 코드 활용 by Sarc
  const switchToSignInTab = ()=>{
    const _switchTab = document.querySelector("div.ant-tabs-tab:not(.ant-tabs-tab-active)") as HTMLElement;
    if (!!_switchTab) {
      _switchTab.click();
    }
  }

  return (
      <>
        {AuthHeader(`${roleTitle} 인증`)}
        <ErrorModal />
        <Form
            name="user-check-form"
            className="signin-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
          <Form.Item
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              label="이름"
              name="name"
              className="form-item"
              validateStatus={inputStatus}
              hasFeedback
              rules={[{ required: true, message: "이름을 입력해 주세요." }]}
          >
          <span className="ant-input-affix-wrapper" >
            <Input placeholder="이름" onChange={checkInput} />
          </span>
          </Form.Item>
          {roleView === "PR" || roleView === "CO" ? (
              // 권한이 교수 또는 멘토일 경우 학번이 아닌 이메일 정보를 받아옴.
              <Form.Item
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 20,
                  }}
                  label="이메일"
                  name="email"
                  className="form-item"
                  validateStatus={inputStatus}
                  hasFeedback
                  rules={[
                    {required: true, message: '이메일이 입력되지 않았습니다.'},
                    {type: 'email', message: '올바른 이메일 주소를 입력해주세요.'}
                  ]}
              >
                <Row className="form-row">
              <span className="ant-input-affix-wrapper" >
                <Input className="passw-input" placeholder="이메일" onChange={checkInput} />
              </span>
                </Row>
              </Form.Item>
          ) : (
              <Form.Item
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 20
                  }}
                  label="학번"
                  name="idNumber"
                  className="form-item"
                  validateStatus={inputStatus}
                  hasFeedback
                  rules={[{ required: true, message: "학번을 입력해 주세요." }]}
              >
                <Row className="form-row">
              <span className="ant-input-affix-wrapper" >
                <Input className="passw-input" placeholder="학번" onChange={checkInput} />
              </span>
                </Row>
              </Form.Item>
          )}
           <ul className="terms-check-wrap">
            <li className="terms-check-scroll-title">
                <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)}> 개인정보 수집 및 이용 동의<span className="terms-check-strong"> (필수)</span></Checkbox>
            </li>
            <li className="terms-check-scroll-box">
                 {termsText}
            </li>
            <li style={isAgreeError}>
               <span style={{color: 'tomato', fontWeight: 'bold'}}>개인정보 수집 및 이용 동의에 체크해 주세요.</span>
            </li>
          </ul>
          <Form.Item>
            <Row>
              <Col span={11}>
                <Button
                    type="ghost"
                    htmlType="submit"
                    className="login-form-button default-auth-bt"
                    onClick={() => setView("selectList")}
                >
                  이전
                </Button>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button default-auth-bt"
                >
                  다음
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <Row justify="center">
              <a key="Account Inquiry" href={`/inquiry?role=${roleView}`} style={{fontWeight:"bold",color:"#f1800a"}}>
                {"* 회원가입이 안되면 여기를 눌러주세요."}
              </a>
        </Row>
      </>
  );
};

export default UserCheck;
