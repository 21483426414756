import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "axios";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Upload, message } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { createBoard } from "services/boardApi";
import { defaultUrl, refreshToken } from "services/api.helpers";
import "common/form/Form.css";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";
import MaterialSelectHeader from "arduino/MaterialSelectHeader";

const MaterialWrite = ({ url, category, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const history = useHistory();
  const [classId, setClassId] = useState("");
  const [sem, setSem] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const config = {
    customRequest: ({ file, onSuccess, onError }) => {
      let formData = new FormData();
      formData.append("file", file);
      refreshToken().then((idToken) => {
        axios
          .post(`${defaultUrl}/uploads/`, formData, {
            headers: {
              Authorization: idToken,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((res) => {
            onSuccess(res.data.url);
          })
          .catch((error) => {
            onError({ event: error });
            if (error.response) {
              console.log("file upload error-", JSON.stringify(error.response));
              if (
                error.response.data.errorCode.code === "FILE_SIZE_OVER" ||
                error.response.data.errorCode.code === "LIMIT_FILE_SIZE"
              ) {
                message.error(`업로드 하려는 파일 용량이 20MB 이상입니다.`);
                onError();
              }
            }
          });
      });
    },
    listType: "text",
    maxCount: 10, // 최대 업로드 가능 파일 수
    onChange: function (files) {
      setFileList(files.fileList);
    },
    fileList: fileList,
    onPreview: (e) => {
      refreshToken().then((idToken) => {
        axios({
          url: defaultUrl + e.response, //your url
          headers: {
            Authorization: idToken,
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          let filename = e.name;
          let data = response.data;
          let blob = new Blob([data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            let elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.getElementsByTagName("body")[0].appendChild(elem);
            elem.click();
            document.getElementsByTagName("body")[0].appendChild(elem);
          }
        });
      });
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "",
      showRemoveIcon: true,
    },
  };

  const handleGoBack = () => {
    history.push(`${url}`);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const handleFinish = async (values) => {
    setLoading(true);
    const newData = await {
      title: values.title,
      classId: classId,
      semesterCode: sem,
      description: values.description,
      category: category,
    };

    if (fileList.length > 0) {
      let _fileList = [];

      await fileList.forEach((e) => {
        if (!!e.response) {
          let _wrap = { uid: e.uid, response: e.response, name: e.name };
          _fileList.push(_wrap);
        }
      });

      newData["info"] = {
        fileList: _fileList,
      };
    }
    return postDataApi(newData);
  };

  const postDataApi = async (newData) => {
    return createBoard(newData)
      .then((e) => {
        setLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `게시글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("board create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(`${url}`);
  };

  const handleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    message.success({
      content: "새 게시글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          name="writeForm"
          layout="vertical"
          className="writeForm"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFaild}
        >
          <Col span={2}>
            <Button onClick={onHandleBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
              <span className="list-text-bt">목록</span>
            </Button>
          </Col>
          <Row className="detailMainInfoWrap">
            <Col span={18} offset={3}>
              <h2>{title} 글쓰기</h2>
            </Col>
            <Col span={3}></Col>
          </Row>
          <MaterialSelectHeader setClassId={setClassId} setSem={setSem} />
          <Row className="edit-content-wrap">
            <Form.Item
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              rules={[
                { required: true, message: "제목을 입력하지 않았습니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item label="파일 첨부(20MB, 10개 제한)">
              <Upload {...config}>
                <Button
                  disabled={fileList.length >= 10}
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
              rules={[
                { required: true, message: "내용을 입력하지 않았습니다." },
              ]}
            >
              <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
              <Warning handleGoBack={handleGoBack} />
              <Button
                type="primary"
                htmlType="submit"
                className="write-submit-button"
              >
                등록
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default MaterialWrite;
