import React, { useState, useEffect } from "react";
import axios from "axios";
import auth from "services/firebase";
import { useHistory } from "react-router-dom";
import { defaultUrl } from "services/api.helpers";

export const putUser = async(id ,data) => {
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);

    return await axios
        .put(`${defaultUrl}/users/${id}`, data, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then((response) => {
            // console.log('userInfo changed - '+response.data);
            return {
                status: 'success',
                result: response.data
            };
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'failed',
                result: err.response.data
            };
        });
};
