export const gnb = [
    {
      key: "multi-step-first-menu-0",
      cate: "강좌소개",
      menu1: "교과목 소개",
      menu1Link: "/info/introduce",
      menu2: "강의 구성",
      menu2Link: "/info/compose",
    },
    {
      key: "multi-step-first-menu-1",
      cate: "공지사항",
      menu1: "공지사항",
      menu1Link: "/notice",
    },
    {
      key: "multi-step-first-menu-2",
      cate: "강의자료",
      menu1: "교과목 게시판",
      menu1Link: "/lect/board",
      menu2: "강의 자료실",
      menu2Link: "/lect/material",
    },
    {
      key: "multi-step-first-menu-3",
      cate: "아두이노 실습",
      menu1: "실습 자료실",
      menu1Link: "/ardu/material",
      menu2: "실습 Q&A",
      menu2Link: "/ardu/qna",
    },
    {
      key: "multi-step-first-menu-4",
      cate: "설계 프로젝트",
      menu1: "멘토링 Q&A",
      menu1Link: "/designQna",
    },
    {
      key: "multi-step-first-menu-5",
      cate: "경진대회",
      menu1: "경진대회 공지",
      menu1Link: "/cont/noti",
      menu2: "우수작 소개",
      menu2Link: "/cont/best",
    },
  ];