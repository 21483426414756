import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import useGetUserFirst from "hooks/useGetUserFirst";
import LoadingView from "common/view/LoadingView";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSetRecoilState } from 'recoil';
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";
import "custom-antd.css";
import HeaderMenu from "common/header/HeaderMenu";
import Main from "main/Main";
import Info from "info/Info";
import Notice from "notice/Notice";
import Lecture from "lecture/Lecture";
import Arduino from "arduino/Arduino";
import DesignQna from "design/DesignQna";
import Contest from "contest/Contest";
import NotFound from "common/view/NotFound";
import MyPageList from "mypage/MyPageList";
import Auth from "auth/Auth";
import Error from "common/view/Error";
import NoAccess from "common/view/NoAccess";
import InquiryAnonymous from "common/inquiry/InquiryAnonymous";


const App = () => {
    const {loading, error, data, refetch} = useGetUserFirst();
    const setModal = useSetRecoilState(modalState);
    useEffect(() => {
        // if(user.id !== ""){
        //     if(user.emailVerified === true){
        //         setroleState(user.role);
        //         console.log(`사용자의 권한은 :${user.role}`);
        //     }else{
        //         console.log(`App.js 의 사용자 권한 recoil: ${roleState}`);
        //         return;
        //     }
        // }else{
        //
        // }
        return refetch();
    },[]);

    return (
       <>
       <ErrorModal />
        { loading ? <LoadingView /> :
            error ? (
                setModal({
                    type: "error",
                    isActive: true,
                    title: "사용자 로그인 정보를 가져올수 없습니다.",
                    msg: `${error}`,
                    redirect: `/`,
                  })
            ) :
            <div className="App">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>동국대학교</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="동국대학교, 동국대학교 어드벤쳐 디자인" />
                    <link rel="icon" type="image/x-icon" href="/image/favicon.png" />
                    <link rel="shortcut icon" type="image/x-icon" href="/image/favicon.png" />
                </Helmet>
                <HeaderMenu data={data} />
                <Switch>
                    <Route path="/" component={Main} exact />
                    <Route path="/info/:value" render={() => <Info />} />
                    <Route path="/notice" component={Notice} />
                    <Route path="/lect/:value" render={() => <Lecture />} />
                    <Route path="/ardu/:value" render={() => <Arduino />}/>
                    <Route path="/cont/:value" render={() => <Contest />}/>
                    <Route path="/designQna" render={() => <DesignQna />}/>
                    <Route path="/mypage" component={MyPageList} />
                    <Route path="/auth/:value" render={() => <Auth />}/>
                    <Route path="/inquiry" render={() => <InquiryAnonymous url="/" />}/>
                    <Route path="/error" component={Error} />
                    <Route path="/noaccess" component={NoAccess} exact />
                    <Route path="/adms" exact render={() => {
                        if (window.location.host === "deec.dongguk.edu") {
                            window.location.href = window.location.protocol + "//" + window.location.host + "/adms/"
                        } else if (window.location.host === "donggukuniv.du.r.appspot.com") {
                            window.location.href = "https://dongguk-web-admin-dot-donggukuniv.du.r.appspot.com/adms/"
                        } else if (window.location.host === "default-dev-dot-donggukuniv.du.r.appspot.com") {
                            window.location.href = "https://dongguk-web-admin-dev-dot-donggukuniv.du.r.appspot.com/adms/"
                        }
                    }
                    }/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            </div>
        }
        </>
    );
}

export default App;
