import useGetDetailWt from "hooks/useGetDetailWt";
import { defaultUrl } from "services/api.helpers";
import { Comment, Avatar, Tooltip } from "antd";
import moment from "moment";
import LoadingFlex from "common/view/LoadingFlex";
import { useEffect } from "react";

const HasComment = (props) => {
  const {
    changeEditMode,
    showModal,
    bbsId,
    edit,
    setState,
    reload,
    setReload,
  } = props;
  const { loading, error, data, refetch, userId } = useGetDetailWt({
    url: `${defaultUrl}/inquiries/${bbsId}/answers`,
  });

  useEffect(() => {
    // console.log(`HasComment data : ${JSON.stringify(data)}`);
    setState({
      value: data?.description,
      commentId: data?.id,
      error: error,
    });
  }, [edit, data]);

  useEffect(() => {
    if (reload) {
      refetch();
      setReload(false);
      return setState({
        loading: false,
      });
    }
  }, [reload]);

  const actions = [
    <>
      {userId === data?.createdUserId ? (
        <>
          <span onClick={changeEditMode} className="editBt">
            수정
          </span>
          <span onClick={showModal}>삭제</span>
        </>
      ) : null}
    </>,
  ];

  return (
    <Comment
      style={{ textAlign: "center" }}
      actions={actions}
      author={
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          {data?.createdBy?.name}
        </span>
      }
      content={
        <>
          {loading ? (
            <LoadingFlex />
          ) : (
            <p style={{ textAlign: "left" }}>{data?.description}</p>
          )}
        </>
      }
      datetime={
        <Tooltip>
          <span style={{ fontSize: "0.9rem" }}>
            {moment().format("YY/MM/DD HH:mm:ss")}
          </span>
        </Tooltip>
      }
    />
  );
};

export default HasComment;
