import SelectRole from "auth/SelectRole";
import UserCheck from "auth/UserCheck";
import SignUpStAo from "auth/SignUpStAo";
import SignUpMento from "auth/SignUpMento";
import { useRecoilValue } from "recoil";
import { viewSignUpState } from "recoil/viewState";

const SignUp = () => {
  const view = useRecoilValue(viewSignUpState);

  function switchView() {
    switch (view) {
      case "selectList":
        return <SelectRole />;
      case "userCheck":
        return <UserCheck />;
      case "SignUpStAo":
        return <SignUpStAo />;
      case "SignUpMento":
        return <SignUpMento />;
      default:
        return <SelectRole />;
    }
  }

  return <>{switchView()}</>;
};

export default SignUp;
