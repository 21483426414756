import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { Button, Row, Col, Modal, Result, message } from "antd";
import { ArrowLeftOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { deleteBoard } from "services/boardApi";
import "common/board-api/BoardHeader.css";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const DetailHeaderAdm = ({ bbsId, url }) => {
  const setModal = useSetRecoilState(modalState);
  const location = useLocation();
  const history = useHistory();
  const key = "updatable";

  const [isModalVisible, setIsModalVisible] = useState(false);

  // console.log(`location.pathname : ${location.pathname}`);

  const onHandleBack = () => {
    history.push(url);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const boardDelete = async () => {
    deleteBoard(bbsId)
      .then((e) => {
        setIsModalVisible(false);
        // console.log(`게시글이 정상적으로 삭제되었습니다.`);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board delete failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "삭제 실패",
            msg: `게시글 삭제에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            // goBack: true
          });
        }
      })
      .catch((e) => {
        setIsModalVisible(false);
        console.log("board delete failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    message.success({
      content: "게시글이 삭제되었습니다.",
      key,
      duration: 2,
    });
  };

  const deleteCancled = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row className="detailHeaderWrap">
        <Col span={2} className="detail-list-wrap">
          <Button onClick={onHandleBack} type="text">
            <ArrowLeftOutlined
              style={{ color: "#BDBDBD", fontSize: 20, fontWeight: "bold" }}
            />
            <span className="detailHeader-list-text">목록</span>
          </Button>
        </Col>
        <Col span={2} offset={18} className="detail-edit-col">
          <Button onClick={() => history.push(`${url}/edit/${bbsId}`)}>
            수정
          </Button>
        </Col>

        <Col span={2} offset={0} className="detail-delete-col">
          <Button onClick={() => showModal()} danger>
            삭제
          </Button>
        </Col>
      </Row>
      <Modal
        visible={isModalVisible}
        onOk={boardDelete}
        onCancel={deleteCancled}
        okText="확인"
        cancelText="취소"
        okButtonProps={{ size: "large", danger: "true" }}
        cancelButtonProps={{ size: "large" }}
      >
        <Result
          icon={<DeleteTwoTone />}
          title="해당 게시글이 삭제됩니다."
          subTitle="게시글을 삭제하겠습니까?"
        />
      </Modal>
      <ErrorModal />
    </>
  );
};

export default DetailHeaderAdm;
