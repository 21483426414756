import {Result, Button, Typography} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
//import image from "/image/security.png";

const {Paragraph, Text} = Typography;

const NoAccess = () => {
    const history = useHistory();
    return (
        <> 
            <div id = "access" > 
              <div className="contents-wrap">
                <div>
                    <img src="/image/security.png" alt="잠김" />
                </div>
                <div className="txt-wrap">
                    <h6 className="txt-para-title">접근 권한이 필요합니다</h6>
                    <p className="txt-main-txt">권한이 있는 아이디로 로그인 또는 회원가입을 해주세요</p>
                </div>
                <div>
                    <Button type="primary" key="console" onClick={() => history.push("/auth/sign")} className="txt-main-title">
                          확 인
                    </Button>
                </div>
              </div>
            </div>
        </>
    );
};

export default NoAccess;
