import React from "react";
import axios from "axios";
import auth from "services/firebase";
import {defaultUrl} from "services/api.helpers";

export const deleteBoard = async (id) => {
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);
    return await axios.delete(`${defaultUrl}/boards/${id}`, {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    })
    .then((response) => {
        // console.log(response.data);
        // console.log("board created");
        return {
            status: 'success',
            result: response.data
        };
    })
    .catch((err) => {
        // console.log(err);
        return {
            status: 'failed',
            result: err.response.data
        };
    });
};


export const createBoard = async (data) => {
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);
    return await axios
        .post(defaultUrl + "/boards", data, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then((response) => {
            // console.log(response.data);
            // console.log("board created");
            return {
                status: 'success',
                result: response.data
            };
        })
        .catch((err) => {
            // console.log(err);
            return {
                status: 'failed',
                result: err.response.data
            };
        });
};



export const putBoard = async(id ,data) => {
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);
    return await axios
        .put(`${defaultUrl}/boards/${id}`, data, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then((response) => {
            // console.log(response.data);
            // console.log("board updated");
            return {
                status: 'success',
                result: response.data
            };
        })
        .catch((err) => {
            // console.log(err);
            return {
                status: 'failed',
                result: err.response.data
            };
        });
}
