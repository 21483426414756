import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import useGetDetail from "hooks/useGetDetail";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { defaultUrl } from "services/api.helpers";
import "common/form/Form.css";
import { putBoard } from "services/boardApi";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BoardEdit = ({ url, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const { bbsId } = useParams();
  const history = useHistory();

  const { data, loading, error, refetch } = useGetDetail({
    url: `${defaultUrl}/boards/${bbsId}`,
  });

  const [detailData, setDetailData] = useState({
    id: "",
    info: {},
    semesterCode: null,
    classId: null,
    title: "",
    writer: "",
    description: "",
  });

  const [form] = Form.useForm();
  const [editLoading, setEditLoading] = useState(false);

  const setFormValues = () => {
    form.setFieldsValue({
      id: data.id,
      info: { ...data?.info } ?? {},
      semesterCode: data?.semesterCode ?? null,
      classId: data?.classId ?? null,
      title: data.title,
      writer: data?.writer ?? "관리자",
      description: data.description,
    });
  };

  useEffect(() => {
    if (!bbsId) {
      return;
    } else if (!!data) {
      setFormValues();
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(url);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const HandleFinish = (values) => {
    setEditLoading(true);
    const newValue = Object.assign(detailData, values);
    setDetailData(newValue);

    newValue.classId = data?.classId;
    return putDataApi();
  };

  const putDataApi = async () => {
    await putBoard(bbsId, detailData)
      .then((e) => {
        setEditLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board edit failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "수정 실패",
            msg: `게시글 수정에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setEditLoading(false);
        console.log("board edit failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const openMessage = () => {
    message.loading({ content: "Loading...", key });
    message.success({
      content: "게시글이 정상적으로 수정되었습니다.",
      key,
      duration: 2,
    });
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <>
          <Row justify="center" className="detailMainInfoWrap">
            <Form
              form={form}
              name="writeForm"
              layout="vertical"
              className="writeForm"
              onFinish={HandleFinish}
              onFinishFailed={HandleFinishFaild}
            >
              <Col span={2} className="detail-list-wrap">
                <Button onClick={onHandleBack} type="text">
                  <ArrowLeftOutlined className="list-arrow-bt" />
                  <span className="list-text-bt">목록</span>
                </Button>
              </Col>
              <Row className="detailMainInfoWrap">
                <Col span={18} offset={3} className="detail-edit-col">
                  <h2>{title} 수정</h2>
                </Col>
                <Col span={3}></Col>
              </Row>
              <Row className="edit-content-wrap">
                <Form.Item
                  label="제목"
                  name="title"
                  placeholder="제목을 입력해주세요."
                  rules={[
                    { required: true, message: "제목을 입력하지 않았습니다." },
                  ]}
                >
                  <Input maxLength={50} />
                </Form.Item>
                <Form.Item
                  label="내용"
                  name="description"
                  className="form-editor-item"
                  placeholder="내용을 입력해주세요."
                  rules={[
                    { required: true, message: "내용을 입력하지 않았습니다." },
                  ]}
                >
                  <WriteEditor value={desc} onChange={onEditorChange} />
                </Form.Item>
                <Form.Item>
                  <Warning handleGoBack={handleGoBack} />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="write-submit-button"
                  >
                    수정
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Row>
          <ErrorModal />
        </>
      )}
    </>
  );
};

export default BoardEdit;
