import React, {useEffect, useState} from "react";
import moment from "moment";
import { Col, Card, Skeleton, Image } from "antd";
import { Link } from "react-router-dom";
import { defaultUrl } from "services/api.helpers";
import "contest/best/best.css";

const CardItem = (props) => {
  const { res, index, value } = props;

//
  return (
        <>
          <Col span={6} xs={24} sm={12} md={8} lg={8} xl={6} key={index} value={res.key} id="cardBox" >
                  <Link to={{
                    pathname:`/cont/best/detail/${res.id}`,
                    state: {
                      title:`${res.title}`,
                      value: `${value}`,
                      id: `${res.id}`
                    }
                  }}>
                  <Card
                      key={res.index}
                      className="program-list-card"
                      cover={res.info.thumbnail == null ? <Skeleton.Image style={{width: '100%', minWidth: '230px', minHeight: '230px'}} /> : 
                              <Image
                                  placeholder={<Skeleton.Image style={{ marginTop:"-20px",width:"255px", height:"235px" }} />}
                                  className="card-thumbnail-img"
                                  alt="example"
                                  src={defaultUrl+res.info.thumbnail}
                                  size="contain"
                                  preview={false}
                                />}>
                      <div className="program-title">
                          <span>{res.title}</span>
                      </div>
                      <div className="program-info">
                          <ul className="program-info-title">
                              <li>학과</li>
                              <li>성명</li>
                              <li>작품</li>
                          </ul>
                          <ul className="program-info-text">
                              <li>{res.info.major}</li>
                              <li>{res.info.exhibitor}</li>
                              <li>{res.info.workTitle}</li>
                          </ul>
                      </div>
                  </Card>
                  </Link>
        </Col>
    </>
  );
}


  export default CardItem;
