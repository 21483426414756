import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import FooterBottom from "common/footer/FooterBottomWhite";
import Slides from "main/Slides";
import NoticeBoard from "main/NoticeBoard";
import AdventureService from "main/AdventureService";
import "main/Main.css";

const { Content } = Layout;

const Main = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _verifiedParam = params.get("verified");
  const _emailVerifiedRedirect =
    !!_verifiedParam &&
    (_verifiedParam == true ||
      (typeof _verifiedParam == "string" && _verifiedParam == "true"));
  useEffect(() => {
    if (_emailVerifiedRedirect == true) {
      history.replace("/auth/sign");
    }
  }, [_emailVerifiedRedirect]);

  return (
    <div className="Main">
      <Layout style={{ margin: "0 auto" }}>
        <Row justify="center">
          <Col span={24}>
            <Slides />
          </Col>
        </Row>
      </Layout>
      <Layout style={{ margin: "0 auto", maxWidth: 1440 }}>
        <Content>
          <Row
            justify="center"
            className="sm-view-padding"
            style={{ minHeight: "554.39px" }}
          >
            <Col span={24} className="boardTabMain">
              <NoticeBoard />
            </Col>
          </Row>
          <Row justify="center" className="sbcBoard">
            <Col span={24}>
              <AdventureService />
            </Col>
          </Row>
        </Content>
      </Layout>
      <FooterBottom />
    </div>
  );
};

export default Main;
