import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Space,
  Pagination,
  Row,
  Col,
  Select,
} from "antd";
import { defaultUrl } from "services/api.helpers";
import CardItem from "contest/best/component/CardItem";
import useGetList from "hooks/useGetList";
import LoadingView from "common/view/LoadingView";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { accessEdit } from "recoil/authState";
import WriteButtonForBest from "common/board/WriteButtonForBest";

const BestList = () => {
  const isEdit = useRecoilValue(accessEdit);
  const history = useHistory();
  const pathName = history.location.pathname;
  const [page, setPage] = useState(0);
  const [yearList, setYearList] = useState([]);
  const [_loading, setLoading] = useState(true);
  const [url, setUrl] = useState(`${defaultUrl}/boards?cate=contbest&rpp=8&page=${page}`);
  const [currentYearValue, setCurrentYearValue] = useState("전체보기");
  const { loading, data, error, refetch, totalCnt } = useGetList({
    url: `${url}`,
  });

  const getYearList = async () => {
    await axios.get(`${defaultUrl}/boards?cate=contbest&rpp=1`).then(async (data) => {
      // console.log('data-',data);
      if (!!data.data?.yearList) {
        await setYearList(data.data.yearList); // yearList 키값은 constBest에서만 내려받는 키값. 타 카테고리에 적용시 주의 by Sarc
      }
      // await console.log('year-',data, data.data, data.data.yearList);
      return setLoading(false);
    });
  };

  useEffect(() => {
    if (yearList.length === 0) {
      getYearList();
    }
    if (currentYearValue === "all" || currentYearValue === "전체보기") {
      // console.log('all')
      setUrl(`${defaultUrl}/boards?cate=contbest&rpp=8&page=${page}`);
      setCurrentYearValue("전체보기");
    } else {
      setUrl(`${defaultUrl}/boards?cate=contbest&yr=${currentYearValue}&rpp=8&page=${page}`);
      setCurrentYearValue(currentYearValue);
    }
    setLoading(false);
  }, [page]);

  useEffect(() => {
    refetch();
  }, [url]);

  useEffect(() => {
    if (currentYearValue === "all" || currentYearValue === "전체보기") {
      // console.log('all')
      setUrl(`${defaultUrl}/boards?cate=contbest&rpp=8&page=${page}`);
      setCurrentYearValue("전체보기");
    } else {
      setUrl(`${defaultUrl}/boards?cate=contbest&yr=${currentYearValue}&rpp=8&page=${page}`);
      setCurrentYearValue(currentYearValue);
    }
  }, [currentYearValue]);

  //const YearList = data.map((data) => (<optoin>{data.title}</optoin>));

  const { Option } = Select;

  function HandleChange(value) {
    // console.log(`selected ${value}`);
    setCurrentYearValue(value);
    setPage(0); // 연도 변하면 페이지 처음으로
  }

  function WorksCard(props) {
    const { value, data, reload } = props;

    // 카테고리 전체 프로그램 카드 map함수
    const totalCardMapFilter = (data) => {
      if (Array.isArray(data)) {
        return data.map((res, index) => {
          return <CardItem res={res} value={value} key={index} />;
        });
      }
    };

    return (
      <Row gutter={[24, 24]} style={{ marginTop: 30 }}>
        {totalCardMapFilter(data)}
      </Row>
    );
  }

  const onChange = (current) => {
    let crr = current - 1;
    // console.log(crr);
    return setPage(crr);
  };
  // console.log('url', url)
  // console.log('page',page)

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <div id="contbest">
          <div className="board-top-control-bt role-hide-bt">
            <Row justify="space-between">
              <Col>
                <span style={{ verticalAlign: "super", marginRight: "1em" }}>연도별 우수작 </span>
                <Select defaultValue={currentYearValue} onChange={HandleChange} loading={_loading}>
                  <Option value="all">전체보기</Option>
                  {yearList !== null ? (
                    yearList.map((item) => <Option value={item}>{item}년</Option>)
                  ) : (
                    <Option>연도를 선택해 주세요.</Option>
                  )}
                </Select>
              </Col>
              {isEdit ? (
                  <WriteButtonForBest />
              ) : (
                  <></>
              )}
            </Row>
          </div>
          <div className="List">
            <Space align="center" size="small" direction="vertical">
              {error === null ? (
                <>
                  <WorksCard data={data} />
                  <Pagination
                    defaultCurrent={1}
                    current={page + 1}
                    pageSize={8}
                    total={totalCnt}
                    onChange={onChange}
                    size="small"
                    showSizeChanger={false}
                    showQuickJumper={false}
                  />
                </>
              ) : (
                <>
                  <span>{error}</span>
                </>
              )}
            </Space>
          </div>
        </div>
      )}
    </>
  );
};

export default BestList;
