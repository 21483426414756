import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, message, Modal, Result } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";
import auth from "services/firebase";
import useGetUser from "hooks/useGetUser";
import { putUser } from "services/userApi";
import { useRecoilValue } from "recoil";
import { userRoleState } from "recoil/authState";
import SearchInput from "common/board/SearchInput";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";
import "mypage/MyInfoStAs.css";
import LoadingView from "common/view/LoadingView";

const MyInfoStAs = (props) => {
  const [loading, setLoading] = useState(true);
  const viewState = useRecoilValue(userRoleState);
  const setModal = useSetRecoilState(modalState);
  let m = moment();
  m.format("[Time: ] h:mm:ss a");

  const [applyform] = Form.useForm();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const history = useHistory();
  const [showMessageErr, setShowMessageErr] = useState(false);
  const [messageErr, setMessageErr] = useState(null);
  const { error, data, refetch } = useGetUser();
  const [depart, setDepart] = useState("");

  const onHandleBack = () => {
    history.push(`/mypage`);
  };

  const errorAlert = () => {
    setTimeout(() => {
      setShowMessageErr(false);
    }, 4000);
    return (
      <Alert
        className="alert-result-true"
        message="오류"
        description={messageErr}
        type="error"
        showIcon
        closable={true}
      />
    );
  };

  const handleCancel = () => {
    setErrorModal(false);
    auth.signOut();
    history.push("/signin");
  };
  const handleSuccess = () => {
    setSuccessModal(false);
    refetch();
  };
  const userInfoUpdate = async function () {
    const _updateInfo = await applyform.getFieldsValue();
    _updateInfo.department = await depart;

    if (depart === "") {
      console.log("학과미선택");
      setModal({
        type: "error",
        isActive: true,
        title: `학과 미선택`,
        msg: `학과가 선택되지 않았습니다.`,
      });
      return;
    }

    await putUser(data.id, _updateInfo).then((e) => {
      if (e?.status === "success") {
        message.success("개인정보가 수정되었습니다.");
      } else {
        console.log("userInfo update failed - ", JSON.stringify(e?.result));
        return setModal({
          type: "error",
          isActive: true,
          title: `수정 실패`,
          msg: `내 정보 수정에 실패하였습니다.${
            !!e.result?.errorCode && !!e.result?.message
              ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
              : ""
          }`,
        });
      }
    });
  };

  const updateRevert = () => {
    history.push("/mypage");
  };
  const updateFailed = (errorInfo) => {
    setModal({
      type: "error",
      isActive: true,
      title: `수정 실패`,
      msg: `${
        errorInfo.errorFields.length !== 0
          ? errorInfo.errorFields[0].errors
          : JSON.stringify(errorInfo)
      }`,
    });
  };

  useEffect(() => {
    if (!data) {
      refetch();
    }

    applyform.setFieldsValue({
      name: data ? data.name : "",
      idNumber: data ? data.idNumber : "",
    });
    if (!!data && !!data.department) {
      setDepart(data.department);
    }
    setLoading(false);
  }, [data]);

  return (
    <>
      {!loading ? (
        error ? (
          setModal({
            type: "error",
            isActive: true,
            title: `오류가 발생했습니다.`,
            msg: `사용자 정보를 받아올 수 없습니다.`,
          })
        ) : (
          <>
            <div className="mycontainer myinfo">
              <Button
                onClick={onHandleBack}
                type="text"
                className="historyBtn"
                ghost
              >
                <ArrowLeftOutlined
                  style={{ color: "#BDBDBD", fontSize: 20, fontWeight: "bold" }}
                />
                <span
                  style={{ color: "#828282", fontWeight: 600, fontSize: 16 }}
                >
                  목록
                </span>
              </Button>
              <h2>기본 정보 수정</h2>
              <Form
                className="mypageBox"
                form={applyform}
                layout="vertical"
                onFinish={userInfoUpdate}
                onFinishFailed={updateFailed}
              >
                <table>
                  <colgroup>
                    <col width="180px" />
                    <col width="70%" />
                  </colgroup>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>
                        <div>E-mail</div>
                      </th>
                      <td>
                        <span>{data ? data.email : ""}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div>이름</div>
                      </th>
                      <td className="changeinput">
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "이름을 입력하지 않았습니다.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <sub>{/*에시 문구*/}</sub>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div>학번</div>
                      </th>
                      <td>
                        <span>
                          {!!data && !!data.idNumber ? data.idNumber : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div>학과</div>
                      </th>
                      <td>
                        <SearchInput
                          noLabel={true}
                          depart={depart}
                          setDepart={setDepart}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div>가입일</div>
                      </th>
                      <td>
                        {data
                          ? moment(data.createdAt).format("YYYY년 MM월 DD일")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div>권한</div>
                      </th>
                      <td>
                        {viewState === "ST"
                          ? "수강생"
                          : viewState === "AS"
                          ? "조교"
                          : viewState === "PR"
                          ? "교수"
                          : viewState === "CO"
                          ? "기업멘토"
                          : viewState === "ADM"
                          ? "관리자"
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="ButtonWrapper">
                  <Button
                    className="btnL"
                    onClick={(e) => {
                      e.preventDefault();
                      updateRevert();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btnL"
                    key="submit"
                  >
                    수정
                  </Button>
                </div>
              </Form>
            </div>
            <Modal
              visible={successModal}
              className="applyModal"
              style={{ top: "25vh" }}
              width="100%"
              footer={null}
            >
              <Result
                status="success"
                title="수정되었습니다."
                extra={[
                  <Button
                    className="btnApply"
                    type="primary"
                    key="확인"
                    onClick={handleSuccess}
                  >
                    확인
                  </Button>,
                ]}
              />
            </Modal>
            <Modal
              visible={errorModal}
              className="applyModal"
              style={{ top: "25vh" }}
              width="100%"
              footer={null}
            >
              <Result
                status="error"
                // status="warning"
                title="로그인 오류!"
                subTitle="로그인 후 다시 시도해 주세요."
                extra={[
                  <Button
                    className="btnApply"
                    type="primary"
                    key="확인"
                    onClick={handleCancel}
                  >
                    확인
                  </Button>,
                ]}
              />
            </Modal>
            {showMessageErr ? errorAlert() : null}
            <ErrorModal />
          </>
        )
      ) : (
        <LoadingView />
      )}
    </>
  );
};

export default MyInfoStAs;
