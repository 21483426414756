import auth from "services/firebase";
import { actionCodeSettings } from "common/constants";
import { LockFilled } from "@ant-design/icons";
import { Button, Col, Row, Form, Input } from "antd";
import ErrorModal from "common/view/ErrorModal";
import { modalState } from "recoil/modalState";
import { useSetRecoilState } from "recoil";

const ResetPassword = (props) => {
  const { signinView, setSigninView, setEmail } = props;
  const setModal = useSetRecoilState(modalState);

  const fireResetPassword = (email) => {
    setEmail(email);
    auth
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then((res) => {
        // console.log(res);
        setSigninView("resetPasswordSuccess");
      })
      .catch((err) => {
        console.log(err);
        setModal({
          type: "error",
          isActive: true,
          title: `이메일 전송 에러 발생.`,
          msg: `${err}`,
        });
      });
  };

  const onFinish = (value) => {
    // console.log(value);
    if (!!value.email) {
      fireResetPassword(value.email);
    }
  };

  return (
    <div>
      <ErrorModal />
      <div className="signin-header">
        <p>
          <LockFilled style={{ fontSize: "48px", color: "rgb(221,221,221)" }} />
        </p>
        <p
          style={{
            paddingTop: "20px",
          }}
        >
          <span>
            비밀번호 재설정 안내링크를 보낼 가입된
            <br />
            이메일을 입력 후 전송을 눌러주세요.
          </span>
        </p>
      </div>
      <Form
        name="user-check-form"
        className="signin-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 19,
          }}
          label="이메일"
          name="email"
          className="form-item"
          rules={[
            { required: true, message: "이메일이 입력되지 않았습니다." },
            { type: "email", message: "올바른 이메일 주소를 입력해주세요." },
          ]}
        >
          <Row>
            <Col span={24}>
              <span className="ant-input-affix-wrapper">
                <Input placeholder="이메일 계정" />
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={11}>
              <Button
                type="ghost"
                className="login-form-button default-auth-bt"
                onClick={() => setSigninView("signinInput")}
              >
                이전
              </Button>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button default-auth-bt"
              >
                전송
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;
