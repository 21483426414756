import { useState, useEffect } from "react";
import SigninInput from "auth/SigninInput";
import ResetPassword from "auth/ResetPassword";
import ResetPasswordSuccess from "auth/ResetPasswordSuccess";

const SignIn = () => {

  const [signinView, setSigninView] = useState("signinInput");
  const [email, setEmail] = useState("Email sent successfully");
  
  useEffect(() => {
    // console.log(signinView);
    // console.log(email);
  }, [signinView, email]);


  return <>{
    signinView === "signinInput" ? (
      <SigninInput
        signinView={signinView}
        setSigninView={setSigninView}
        />
    ): signinView === "resetPassword" ? (
      <ResetPassword signinView={signinView}
        setSigninView={setSigninView}
        setEmail={setEmail} />

    ): signinView === "resetPasswordSuccess" ? (
      <ResetPasswordSuccess signinView={signinView}
        setSigninView={setSigninView}
        email={email}
        />
    ) :
    (
      <SigninInput
      signinView={signinView}
      setSigninView={setSigninView}
      />
    )
  }</>;
};

export default SignIn;
