import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import useGetDetail from "hooks/useGetDetail";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Upload, message } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultUrl, refreshToken } from "services/api.helpers";
import "common/form/Form.css";
import { putBoard } from "services/boardApi";
import axios from "axios";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BoardEditAttach = ({ url, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const { bbsId } = useParams();
  const history = useHistory();

  const { data, error, refetch, loading } = useGetDetail({
    url: `${defaultUrl}/boards/${bbsId}`,
  });

  const [detailData, setDetailData] = useState({
    id: "",
    info: {},
    semesterCode: null,
    classId: null,
    title: "",
    writer: "",
    description: "",
  });

  const [form] = Form.useForm();
  const [editLoading, setEditLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const config = {
    customRequest: ({ file, onSuccess, onError }) => {
      let formData = new FormData();
      formData.append("file", file);
      refreshToken().then((idToken) => {
        axios
          .post(`${defaultUrl}/uploads/`, formData, {
            headers: {
              Authorization: idToken,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((res) => {
            onSuccess(res.data.url);
          })
          .catch((error) => {
            onError({ event: error });
            if (error.response) {
              console.log("file upload error-", JSON.stringify(error.response));
              if (
                error.response.data.errorCode.code === "FILE_SIZE_OVER" ||
                error.response.data.errorCode.code === "LIMIT_FILE_SIZE"
              ) {
                message.error(`업로드 하려는 파일 용량이 20MB 이상입니다.`);
                onError();
              }
            }
          });
      });
    },
    listType: "text",
    maxCount: 10,
    onChange: function (files) {
      setFileList(files.fileList);
    },
    fileList: fileList,
    onPreview: (e) => {
      refreshToken().then((idToken) => {
        axios({
          url: defaultUrl + e.response,
          headers: {
            Authorization: idToken,
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          let filename = e.name;
          let data = response.data;
          let blob = new Blob([data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            let elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.getElementsByTagName("body")[0].appendChild(elem);
            elem.click();
            document.getElementsByTagName("body")[0].appendChild(elem);
          }
        });
      });
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "",
      showRemoveIcon: true,
    },
  };

  const setFormValues = () => {
    form.setFieldsValue({
      id: data.id,
      info: { ...data?.info } ?? {},
      semesterCode: data?.semesterCode ?? null,
      classId: data?.classId ?? null,
      title: data.title,
      writer: data?.writer ?? "관리자",
      description: data.description,
    });

    if (!!data?.info?.fileList) {
      setFileList(data?.info?.fileList);
    }
  };

  useEffect(() => {
    if (!bbsId) {
      return;
    } else if (!!data) {
      setFormValues();
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(`${url}`);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const HandleFinish = async (values) => {
    setEditLoading(true);

    const newValue = Object.assign(detailData, values);

    if (fileList.length > 0) {
      let _fileList = [];

      await fileList.forEach((e) => {
        if (!!e.response) {
          let _wrap = { uid: e.uid, response: e.response, name: e.name };
          _fileList.push(_wrap);
        }
      });
      newValue["info"] = {
        fileList: _fileList,
      };
    }
    newValue.classId = data?.classId;

    setDetailData(newValue);
    return putDataApi();
  };

  const putDataApi = async () => {
    await putBoard(bbsId, detailData)
      .then((e) => {
        setEditLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board edit failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "수정 실패",
            msg: `게시글 수정에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setEditLoading(false);
        console.log("board edit failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(`${url}`);
  };

  const openMessage = () => {
    message.success({
      content: "게시글이 정상적으로 수정되었습니다.",
      key,
      duration: 2,
    });
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <Row justify="center" className="detailMainInfoWrap">
          <Form
            form={form}
            name="writeForm"
            layout="vertical"
            className="writeForm"
            onFinish={HandleFinish}
            onFinishFailed={HandleFinishFaild}
          >
            <Col span={2}>
              <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined className="list-arrow-bt" />
                <span className="list-text-bt">목록</span>
              </Button>
            </Col>
            <Row className="detailMainInfoWrap">
              <Col span={18} offset={3}>
                <h2>{title} 수정</h2>
              </Col>
              <Col span={3}></Col>
            </Row>
            <Row className="edit-content-wrap">
              <Form.Item
                label="제목"
                name="title"
                placeholder="제목을 입력해주세요."
                rules={[
                  { required: true, message: "제목을 입력하지 않았습니다." },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item label="파일 첨부(20MB, 10개 제한)">
                <Upload {...config}>
                  <Button
                    disabled={fileList.length >= 10}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="내용"
                name="description"
                className="form-editor-item"
                placeholder="내용을 입력해주세요."
                rules={[
                  { required: true, message: "내용을 입력하지 않았습니다." },
                ]}
              >
                <WriteEditor value={desc} onChange={onEditorChange} />
              </Form.Item>
              <Form.Item>
                <Warning handleGoBack={handleGoBack} />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="write-submit-button"
                >
                  수정
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      )}
      <ErrorModal />
    </>
  );
};

export default BoardEditAttach;
