import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import WriteEditor from "common/form/WriteEditorClass";

//import useFireToken from "hooks/useFireToken";
import useGetDetail from "hooks/useGetDetail";
import Warning from "common/view/Warning";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Typography,
  Spin,
  message,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { defaultUrl } from "services/api.helpers";
import "common/form/Form.css";
import ThumbUpload from "contest/best/component/ThumbUpload";
import "common/form/Form.css";
import { putBoard } from "services/boardApi";
import LoadingView from "common/view/LoadingView";
import SearchInput from "common/board/SearchInput";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BestEdit = ({ url }) => {
  const key = "updatable";
  const { bbsId } = useParams();
  const history = useHistory();
  const { Option } = Select;
  const { Text, Title } = Typography;
  const [errMsg, setErrMsg] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [depart, setDepart] = useState("");
  const setModal = useSetRecoilState(modalState);

  //const { token, errorMsg } = useFireToken();
  const { data, error, refetch, loading } = useGetDetail({
    url: `${defaultUrl}/boards/${bbsId}`,
  });
  const [detailData, setDetailData] = useState({
    id: "",
    info: {
      year: null,
      major: null,
      exhibitor: null,
      thumbnail: null,
      workTitle: null,
    },
    semesterCode: null,
    classId: null,
    title: "",
    writer: "",
    description: "",
  });

  // 현재 연도부터 howLong개의 연도 리스트 생성해주는 함수 by Sarc
  const yrs = (howLong) => {
    const _nowYear = new Date().getFullYear();
    const result = [];
    for (let i = 0; i < howLong; i++) {
      result.push(
        <option key={i} value={_nowYear - i}>
          {_nowYear - i}년
        </option>
      );
    }
    return result;
  };

  // 업로드된 썸네일 url 가져오는거.
  const [thumbUrlData, setThumbUrlData] = useState(null);
  const getThumbData = (media) => {
    setThumbUrlData(media);
    //console.log("media",media);
  };

  const [form] = Form.useForm();

  const setFormValues = () => {
    setThumbUrlData(data.info.thumbnail);
    form.setFieldsValue({
      id: data.id,
      //info: { ...data?.info } ?? {},
      department: data.info.major,
      exhibitor: data.info.exhibitor,
      workTitle: data.info.workTitle,
      thumbnail: data.info.thumbnail,
      semesterCode: data?.semesterCode ?? null,
      classId: data?.classId ?? null,
      title: data.title,
      writer: data?.writer ?? "관리자",
      description: data.description,
    });
    setDepart(data.info.major);
  };

  useEffect(() => {
    // console.log(data);
    if (!bbsId) {
      return;
    } else if (!!data) {
      setFormValues();
    }
  }, [data]);
  const handleGoBack = () => {
    history.push(`/cont/best`);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const [mode, setMode] = useState({
    type: null,
  });

  //const [loading, setLoading] = useState(false);
  const HandleFinish = (values) => {
    setEditLoading(true);
    // console.log(`HandleFinish: ${JSON.stringify(values)}`);
    const newValue = Object.assign(detailData, values);

    if (depart === "") {
      console.log("학과미선택");
      setModal({
        type: "error",
        isActive: true,
        title: `학과 미선택`,
        msg: `학과가 선택되지 않았습니다.`,
        // redirect: `/`
      });
      return;
    }

    newValue["info"] = {
      year: values.semesterCode,
      major: depart,
      exhibitor: values.exhibitor,
      thumbnail: thumbUrlData,
      workTitle: values.workTitle,
    };
    newValue["semesterCode"] = values.semesterCode;
    setDetailData(newValue);
    if (mode.type === "new") {
    } else if (mode.type !== "new") {
      newValue.classId = data?.classId; // 수정값에 classId를 넣어두기
      return putDataApi();
    }
  };

  const putDataApi = async () => {
    console.log(`putDataApi: ${JSON.stringify(detailData)}`);
    await putBoard(bbsId, detailData)
      .then((e) => {
        setEditLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board edit failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "수정 실패",
            msg: `게시글 수정에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setEditLoading(false);
        console.log("board edit failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const openMessage = () => {
    message.success({
      content: "게시글이 수정되었습니다.",
      key,
      duration: 2,
    });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };
  const handleChange = (value) => {};

  const [imgErr, setImgErr] = useState(false);
  useEffect(() => {
    if (thumbUrlData == null) {
      setImgErr(true);
    } else {
      setImgErr(false);
    }
  }, [thumbUrlData]);

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <Row justify="center" className="detailMainInfoWrap">
          <Form
            form={form}
            name="writeForm"
            layout="vertical"
            className="writeForm"
            onFinish={HandleFinish}
            onFinishFailed={HandleFinishFaild}
          >
            <Col span={2}>
              <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined className="list-arrow-bt" />
                <span className="list-text-bt">목록</span>
              </Button>
            </Col>
            <Row className="detailMainInfoWrap">
              <Col span={18} offset={3}>
                <h2>우수작 소개</h2>
              </Col>
              <Col span={3} xs={24} md={3}>
                <Form.Item
                  label="작성자"
                  name="writer"
                  initialValue="관리자"
                  className="writer-input-title"
                >
                  <Input
                    value="관리자"
                    className="writer-input"
                    disabled
                    bordered={false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mainInfo">
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={8} xs={24} sm={12} md={10} lg={9} xl={8}>
                    <Form.Item
                      label="연도"
                      name="semesterCode"
                      rules={[
                        {
                          required: true,
                          message: "연도를 선택하지 않았습니다.",
                        },
                      ]}
                    >
                      <Select value="연도" onChange={handleChange}>
                        {yrs(5)}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16} xs={24} sm={12} md={14} lg={15} xl={16}>
                    <Form.Item
                      label="제목"
                      name="title"
                      placeholder="제목을 입력해 주세요."
                      rules={[
                        {
                          required: true,
                          message: "제목을 입력하지 않았습니다.",
                        },
                      ]}
                    >
                      <Input maxLength={50} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} justify="start">
                  <Col
                    className="thumbCol"
                    span={8}
                    xs={24}
                    sm={12}
                    md={10}
                    lg={9}
                    xl={8}
                  >
                    <Form.Item
                      label="썸네일 이미지"
                      name="thumbnail"
                      valuePropName="thumbnail"
                      value={detailData.info.thumbnail}
                      rules={[
                        {
                          required: true,
                          message: "제목을 입력하지 않았습니다.",
                        },
                      ]}
                    >
                      <ThumbUpload
                        thumbUrlData={thumbUrlData}
                        value={data.info.thumbnail}
                        getThumbData={getThumbData}
                        detailData={data}
                      />
                    </Form.Item>
                    {imgErr ? (
                      <Text type="danger" className="imgErrMsg">
                        이미지를 등록해 주세요.
                      </Text>
                    ) : null}
                  </Col>
                  <Col span={16} xs={24} sm={12} md={14} lg={15} xl={16}>
                    <SearchInput depart={depart} setDepart={setDepart} />
                    <Form.Item
                      label="성명"
                      name="exhibitor"
                      placeholder="성명을 입력해 주세요."
                      rules={[
                        {
                          required: true,
                          message: "성명을 입력하지 않았습니다.",
                        },
                      ]}
                    >
                      <Input className="shortInput" />
                    </Form.Item>
                    <Form.Item
                      label="작품명"
                      name="workTitle"
                      rules={[
                        {
                          required: true,
                          message: "작품명을 입력하지 않았습니다.",
                        },
                      ]}
                    >
                      <Input
                        className="workTitle"
                        placeholder="작품명을 입력해 주세요."
                      />
                    </Form.Item>
                    <Text
                      type="danger"
                      style={{
                        position: "absolute",
                        display: "inline-block",
                        maxWidth: "300px",
                        width: "100%",
                        bottom: "-25px",
                        left: 20,
                      }}
                    >
                      {errMsg !== null ? errMsg : null}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="내용"
                name="description"
                className="form-editor-item"
                placeholder="내용을 입력해주세요."
                rules={[
                  { required: true, message: "내용을 입력하지 않았습니다." },
                ]}
              >
                <WriteEditor value={desc} onChange={onEditorChange} />
              </Form.Item>
              <Form.Item>
                {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 게시글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
                <Warning handleGoBack={handleGoBack} />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="write-submit-button"
                >
                  등록
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      )}
      <ErrorModal />
    </>
  );
};

export default BestEdit;
