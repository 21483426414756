import React, { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import "mypage/Mypage.css";
import {
  Link,
  Route,
  Router,
  useRouteMatch,
  useParams,
  Switch,
  useHistory,
} from "react-router-dom";
import FooterBottomWhite from "common/footer/FooterBottomWhite";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import MyInfoStAs from "mypage/MyInfoStAs";
import MyInfoPrCo from "mypage/MyInfoPrCo";
import MyInquiry from "mypage/MyInquiry";
import { Col, Layout, Row } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { userRoleState, authState } from "recoil/authState";
import MyInquiryDetail from "mypage/MyInquiryDetail";
import MyInquiryEdit from "mypage/MyInquiryEdit";
import MyInquiryWrite from "mypage/MyInquiryWrite";
const { Content } = Layout;

const MyPageList = () => {
  const viewState = useRecoilValue(userRoleState);
  const [userState, setUserState] = useRecoilState(authState);
  const title = "마이페이지";
  const [subTitle, setSubTitle] = useState("");

  const history = useHistory();

  useEffect(() => {
    const pathname =
      typeof history.location.pathname == "string"
        ? history.location.pathname.toLowerCase()
        : "";

    if (pathname.includes("/mypage")) {
      if (pathname.includes("/mypage/info")) {
        return setSubTitle("기본정보");
      } else if (pathname.includes("/mypage/inquiry")) {
        return setSubTitle("1:1 문의");
      } else {
        return setSubTitle("마이페이지");
      }
    }
  }, [history.location]);

  return (
    <>
      <Layout>
        <BoardHeader subTitle={subTitle} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <Layout style={{ marginTop: "50px" }}>
            <Content className="board-content-wrap">
              <Switch>
                <Route path="/mypage" exact>
                  <div className="mycontainer">
                    <h1 style={{ fontWeight: "bold" }}>
                      안녕하세요
                      {userState ? ", " + userState.name + " 님." : "."}
                    </h1>
                    <ul className="mypageNav mypageBox">
                      <li>
                        <Link to={`${history.location.pathname}/info`}>
                          <RightOutlined />
                          <h3>기본정보</h3>
                          <p>
                            이름, 연락처 등 기본 정보를 확인 · 수정할 수
                            있습니다
                          </p>
                        </Link>
                      </li>
                      {viewState !== "ADM" ? (
                        <li>
                          <Link to={`${history.location.pathname}/inquiry`}>
                            <RightOutlined />
                            <h3>1:1 문의</h3>
                            <p>
                              남겨주신 문의에 대한 답변 · 진행상황을 확인할 수
                              있습니다
                            </p>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </Route>
                <Route exact path="/mypage/info">
                  {viewState === "ST" || viewState === "AS" ? (
                    <MyInfoStAs />
                  ) : (
                    <MyInfoPrCo />
                  )}
                </Route>
                <Route exact path="/mypage/inquiry">
                  <MyInquiry />
                </Route>
                <Route exact path="/mypage/inquiry/detail/:bbsId">
                  <MyInquiryDetail url="/mypage/inquiry" />
                </Route>
                <Route exact path="/mypage/inquiry/edit/:bbsId">
                  <MyInquiryEdit url="/mypage/inquiry" />
                </Route>
                <Route exact path="/mypage/inquiry/write">
                  <MyInquiryWrite url="/mypage/inquiry" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </>
  );
};

export default MyPageList;
