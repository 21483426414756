import { Comment, Form, Button, Input } from "antd";

const Editcomment = (props) => {
  const { TextArea } = Input;
  const { setEdit, setState, state } = props;

  const onEditorChange = (event) => {
    // console.log(event.target.value);
    setState({
      ...state,
      description: event.target.value,
      value: event.target.value,
    });
  };

  return (
    <Comment
      content={
        <>
          <Form.Item>
            <TextArea
              rows={10}
              onChange={onEditorChange}
              value={state.value}
              defaultValue={state.value}
              loading={state.loading}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              onClick={() => setEdit(true)}
              type="primary"
            >
              답변 수정
            </Button>
          </Form.Item>
        </>
      }
    />
  );
};

export default Editcomment;
