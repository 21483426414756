export const termsText = <span>개인정보 보호법에 따라 동국대 어드벤처 디자인에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.

1. 수집하는 개인정보
이용자는 회원가입을 하지 않아도 강좌소개, 공지사항 등 대부분의 동국대 어드벤처 디자인 서비스를 회원과 동일하게 이용할 수 있습니다. 이용자가 강의자료, 아두이노 실습, 설계 프로젝트 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 동국대 어드벤처 디자인은 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
회원가입 시점에 동국대 어드벤처 디자인이 이용자로부터 수집하는 개인정보는 아래와 같습니다.
•	회원 가입 시에 ‘이메일, 비밀번호, 이름, 학번(학생의 경우)’를 필수항목으로 수집합니다. 만약 이용자가 입력하는 소속을 추가로 수집합니다.
서비스 이용 과정에서 IP 주소, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 
이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.

2. 수집한 개인정보의 이용
동국대 어드벤처 디자인 및 동국대 어드벤처 디자인 관련 제반 서비스(모바일 웹/Web 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.
•	회원 가입 대상자 여부 확인 등 회원관리를 위하여 개인정보를 이용합니다.
•	법령 및 동국대 어드벤처 디자인 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
•	보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
3. 개인정보의 보관기간
서비스는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.

참고로 동국대 어드벤처 디자인은 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
4. 개인정보 수집 및 이용 동의를 거부할 권리
이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.</span>;