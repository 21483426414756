import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle  } from "react";
import { NavLink, Link } from "react-router-dom";
import { Col, Row,  Menu, Dropdown, message, Space } from "antd";
import "common/footer/Footer.css";
import "common/footer/common.css";

function FooterBottom() {



  return (

      <footer className="footer">
        <Row className="terms">
          <Row className="container">
            <div>동국대학교 Adventure Design</div>
            <div><Link to="/info/introduce">교과목 소개</Link></div>
            <div className="terms-link"><a href="https://www.dongguk.edu/resources/pdf/securityGuide_new.pdf">개인정보처리방침</a></div>
          </Row>
        </Row>
        <Row className="container">
          <Row className="contents-wrap" gutter={[16]}>
            <Col sm={7} xs={12} className="logo" >
            <NavLink to="/" className="logo-link">
              <img src="/image/logo-img-solid.svg" alt="동국대학교 어드벤처 디자인" className="logo-img"/>
              홈
              </NavLink>
            </Col>
            <Col md={10} sm={24} className="copyright">
              <p>04620 서울특별시 중구 필동로 1길 30 동국대학교</p>
              <p>Copyright ⓒ 2022 동국대학교 어드벤처 디자인. ALL RIGHTS RESERVED.</p>
            </Col>
            <Col md={7} sm={24} >
              <SiteMenu/>
            </Col>
          </Row>
        </Row>
      </footer>
  );
}

const SiteMenu = () => {
  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
  const menu = (
    <Menu onClick={onClick} className="site-menu">
    <Menu.Item key="1"><a href="https://www.dongguk.edu/mbs/kr/index.jsp" target="_blank" rel="noopener noreferrer">동국대학교</a></Menu.Item>
    <Menu.Item key="2"><a href="https://engineer.dongguk.edu/" target="_blank" rel="noopener noreferrer">공과대학</a></Menu.Item>
  </Menu>
  );
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown overlay={menu} visible={visible} onVisibleChange={isVisible => setVisible(isVisible)}>
      <div className="link-box">
        <div className="ant-dropdown-link " onClick={e => e.preventDefault()}>
          <span>관련 사이트</span> <img src="/image/icon-arrow_drop_up.svg" alt="화살표" className="drop-up"/>
        </div>
      </div>
    </Dropdown>
  )
}
export default FooterBottom;
