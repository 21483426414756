import { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "antd";
import SelectSearch from "react-select-search";

// 학생/조교용 회원가입/마이페이지, 우수작 작성/수정용 학과 search select input
const SearchInput = (props) => {

  const searchInput = useRef();
  const [selectValue, setSelectValue] = useState("학부/학과를 선택해 주세요.");
  const options = [
    {
      type: "group",
      name: "공과대학",
      items: [
        { name: "건설환경공학과", value: "건설환경공학과" },
        { name: "건축공학부", value: "건축공학부" },
        { name: "건축공학전공", value: "건축공학전공" },
        { name: "건축학전공", value: "건축학전공" },
        { name: "기계로봇에너지공학과", value: "기계로봇에너지공학과" },
        { name: "멀티미디어공학과", value: "멀티미디어공학과" },
        { name: "산업시스템공학과", value: "산업시스템공학과" },
        { name: "융합에너지신소재공학과", value: "융합에너지신소재공학과" },
        { name: "전자전기공학부", value: "전자전기공학부" },
        { name: "정보통신공학전공", value: "정보통신공학전공" },
        { name: "컴퓨터공학전공", value: "컴퓨터공학전공" },
        { name: "컴퓨터정보통신공학부", value: "컴퓨터정보통신공학부" },
        { name: "화공생물공학과", value: "화공생물공학과" }
      ],
    },
    {
      type: "group",
      name: "기타학과",
      items: [
        { name: "가정교육과", value: "가정교육과" },
        { name: "경영정보학과", value: "경영정보학과" },
        { name: "경영학과", value: "경영학과" },
        { name: "경제학과", value: "경제학과" },
        { name: "경찰학전공", value: "경찰학전공" },
        { name: "경찰행정학부", value: "경찰행정학부" },
        { name: "광고홍보학과", value: "광고홍보학과" },
        { name: "교육학과", value: "교육학과" },
        { name: "교정학전공", value: "교정학전공" },
        { name: "국어교육과", value: "국어교육과" },
        { name: "국어국문·문예창작학부", value: "국어국문·문예창작학부" },
        { name: "국어국문학전공", value: "국어국문학전공" },
        { name: "국제통상학과", value: "국제통상학과" },
        { name: "글로벌무역학과", value: "글로벌무역학과" },
        { name: "문예창작학전공", value: "문예창작학전공" },
        { name: "문화재학과", value: "문화재학과" },
        { name: "물리·반도체과학부", value: "물리·반도체과학부" },
        { name: "물리학전공", value: "물리학전공" },
        { name: "뮤지컬전공", value: "뮤지컬전공" },
        { name: "미디어커뮤니케이션학전공", value: "미디어커뮤니케이션학전공" },
        { name: "미술학부", value: "미술학부" },
        { name: "바이오환경과학과", value: "바이오환경과학과" },
        { name: "반도체과학전공", value: "반도체과학전공" },
        { name: "범죄과학전공", value: "범죄과학전공" },
        { name: "법학과", value: "법학과" },
        { name: "북한학전공", value: "북한학전공" },
        { name: "불교미술전공", value: "불교미술전공" },
        { name: "불교학부", value: "불교학부" },
        { name: "사학과", value: "사학과" },
        { name: "사회·언론정보학부", value: "사회·언론정보학부" },
        { name: "사회복지상담학과", value: "사회복지상담학과" },
        { name: "사회복지학과", value: "사회복지학과" },
        { name: "사회학전공", value: "사회학전공" },
        { name: "산업보안전공", value: "산업보안전공" },
        { name: "생명과학과", value: "생명과학과" },
        { name: "서양화전공", value: "서양화전공" },
        { name: "수학과", value: "수학과" },
        { name: "수학교육과", value: "수학교육과" },
        { name: "스포츠문화학과", value: "스포츠문화학과" },
        { name: "식품산업관리학과", value: "식품산업관리학과" },
        { name: "식품생명공학과", value: "식품생명공학과" },
        { name: "약학과", value: "약학과" },
        { name: "역사교육과", value: "역사교육과" },
        { name: "연극전공", value: "연극전공" },
        { name: "연극학부", value: "연극학부" },
        { name: "영어문학전공", value: "영어문학전공" },
        { name: "영어영문학부", value: "영어영문학부" },
        { name: "영어통번역학전공", value: "영어통번역학전공" },
        { name: "영화영상학과", value: "영화영상학과" },
        { name: "융합보안학과", value: "융합보안학과" },
        { name: "의생명공학과", value: "의생명공학과" },
        { name: "일본학과", value: "일본학과" },
        { name: "정치외교학전공", value: "정치외교학전공" },
        { name: "정치행정학부", value: "정치행정학부" },
        { name: "조소전공", value: "조소전공" },
        { name: "중어중문학과", value: "중어중문학과" },
        { name: "지리교육과", value: "지리교육과" },
        { name: "철학과", value: "철학과" },
        { name: "체육교육과", value: "체육교육과" },
        { name: "통계학과", value: "통계학과" },
        { name: "한국화전공", value: "한국화전공" },
        { name: "행정학전공", value: "행정학전공" },
        { name: "화학과", value: "화학과" },
        { name: "회계학과", value: "회계학과" }
      ],
    },
  ];

  const handleChange = (...args) => {
    searchInput.current.querySelector("input").value = "";
    // console.log("ARGS:", args);
    // console.log("CHANGE:");
    setSelectValue(args[1].name);
    props.setDepart(args[1].name);
  };

  const resetValue = () => {
    // console.log("search input clicked: reset value");
    setSelectValue("학부/학과를 선택해 주세요.");
    props.setDepart("");
  };

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return options;
      }
      const updatedItems = items.map((list) => {
        const newItems = list.items.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        // setSelectValue(newItems);
        return { ...list, items: newItems };
      });
      return updatedItems;
    };
  };
  return (
    <Form.Item name="department" className="form-item" style={{width:"100%"}}>
      <Row className="add-form-row">
         <Col span={6}>
          {/* search select 쪽 라벨 스타일 - 수동으로 기존 input 스타일과 통일화 - by Sarc */}
          <span className="input-label ant-form-item-label" style={{paddingRight:0}} >
            <span
                style={{
                  display:"inline-block",
                  marginRight:4,
                  color:"#ff4d4f",
                  fontSize:14,
                  lineHeight:1,
                  fontWeight:"bolder"
                }}
            >* </span>
            <label className="ant-form-item" style={{marginBottom:0}}>학과</label>
          </span>
        </Col>
        <Col span={18} className="ant-input-affix-wrapper" >
          <SelectSearch
            ref={searchInput}
            options={options}
            filterOptions={handleFilter}
            value={props.depart || ''}
            name="department"
            placeholder={selectValue}
            search
            onChange={handleChange}
            className="select-search"
            onFocus={resetValue}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};

export default SearchInput;
