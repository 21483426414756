import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import "common/board-api/BoardHeader.css";

const DetailHeaderPublic = ({ url }) => {
  const location = useLocation();
  const history = useHistory();

  const onHandleBack = () => {
    history.push(url);
  };

  return (
    <>
      <Row className="detailHeaderWrap">
        <Col span={2} className="detail-list-wrap">
          <Button onClick={onHandleBack} type="text">
            <ArrowLeftOutlined
              style={{ color: "#BDBDBD", fontSize: 20, fontWeight: "bold" }}
            />
            <span className="detailHeader-list-text">목록</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DetailHeaderPublic;
