import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userRoleState } from "recoil/authState";
import { semState, classState } from "recoil/filterState";
import { defaultUrl } from "services/api.helpers";
import useGetListWt_Select_Ardu from "arduino/useGetListWt_Select_Ardu";
import LoadingView from "common/view/LoadingView";
import SelectHeaderArduQnA from "arduino/SelectHeaderArduQnA";
import QnAList from "common/inquiry/QnAList";
import QnAListST from "common/inquiry/QnAListST";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const QnA = () => {
  const roleState = useRecoilValue(userRoleState);
  const [page, setPage] = useState(0);
  const setModal = useSetRecoilState(modalState);
  const sem = useRecoilValue(semState);
  const classId = useRecoilValue(classState);

  const { loading, data, error, refetch, totalCnt } = useGetListWt_Select_Ardu({
    url: `${defaultUrl}/inquiries?cate=arduQna&clsid=${classId.id}&sem=${sem}&rpp=12&page=${page}`,
  });

  useEffect(() => {
    return refetch();
  }, [page]);

  useEffect(() => {
    setPage(0);
    return refetch();
  }, [classId, sem]);

  return (
    <>
      <ErrorModal />
      {roleState === "ST" ? (
        <QnAListST
          url="/ardu/qna"
          data={data}
          page={page}
          setPage={setPage}
          totalCnt={totalCnt}
          loading={loading}
          error={error}
        />
      ) : (
        <>
          {loading ? (
            <LoadingView />
          ) : error ? (
            setModal({
              type: "error",
              isActive: true,
              title: "게시글 정보를 가져올수 없습니다.",
              msg: `${error}`,
              goBack: true,
            })
          ) : (
            <>
              <SelectHeaderArduQnA
                refetchList={refetch}
                parentLoading={loading}
              />
              <QnAList
                data={data}
                page={page}
                setPage={setPage}
                totalCnt={totalCnt}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default QnA;
