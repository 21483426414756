import React, { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Layout, Row, Col, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
const { Sider } = Layout;

const BoardSidebar = ({ title, menuList }) => {
  return (
    <Sider>
      <div className="siderWrap" key="sider-menu">
        <ul className="first-side-menu" key="">
          <li className="first-side-title" key="side-step-first-menu-0">
            <span>{title}</span>
          </li>
        </ul>
        <ul className="second-side-menu" key="side-step-first-ul-1">
          <div className="second-wrap-div">
            <>
              {menuList.map((item, i) => {
                return (
                  <li className="second-side-title" key={i + 1}>
                    <Link to={item.link}>{item.name}</Link>
                  </li>
                );
              })}
            </>
          </div>
        </ul>
      </div>
    </Sider>
  );
};

export default BoardSidebar;
