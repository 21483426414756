import { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "common/header/HeaderMenu.css";
import { Col, Row, Drawer, Button } from "antd";
import { MenuOutlined, CopyOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/authState";
import { gnb } from "common/header/menuList";

const MobileNav = () => {
  const useTabs = (initialTabs, allTabs) => {
    const [gnbIndex, setgnbIndex] = useState(initialTabs);
    return {
      gnbItem: allTabs[gnbIndex],
      gnbChange: setgnbIndex,
    };
  };

  const { gnbItem, gnbChange } = useTabs(0, gnb);
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div id="Mobile-nav">
      <Button onClick={showDrawer} className="movile-nav-openBtn">
        <MenuOutlined />
      </Button>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        onClick={onClose}
      >
        <div className="nav-wrapper">
          <div className="submenuDiv">
            {gnb.map((section, index) => (
              <Row gutter={{ sm: 32, xs: 8 }} key={section.key}>
                <Col
                  span={10}
                  className="navMenu"
                  key={section.key == null ? "0" : section.key}
                >
                  {section.key == null ? null : (
                    <Link
                      style={{ opacity: 1 }}
                      to={section.menu1Link}
                      onMouseEnter={() => gnbChange(index)}
                      onClick={() => gnbChange(index)}
                    >
                      {section.cate}
                    </Link>
                  )}
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={14}>
                      {section.menu1Link == null ? null : (
                        <Link to={section.menu1Link}>{section.menu1}</Link>
                      )}
                    </Col>
                    <Col span={10}>
                      {section.menu2Link == null ? null : (
                        <Link to={section.menu2Link}>{section.menu2}</Link>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNav;
