import { Spin, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "common/view/View.css";

const LoadingFlex = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="loading-flex-wrap">
      <Spin
        size="large"
        indicator={antIcon}
        className="spinner-flex-box"
        tip="Loading..."
      />
    </div>
  );
};

export default LoadingFlex;
