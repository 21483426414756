import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Layout, Row, Col, Select } from "antd";
import { gnb } from "common/header/menuList";


const { Header } = Layout;

const BoardHeader = ({ subTitle }) => {

  return (
    <Header>
      <Row className="bw-titleWrap" justify="center" align="middle">
        <Col span={24}>
          <span className="bw-title">{subTitle}</span>
        </Col>
      </Row>
    </Header>
  );
};

export default BoardHeader;
