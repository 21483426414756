import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzsgQK2MDrXUzy6UrRKmxTbE5CrusPlUg",
  authDomain: "donggukuniv.firebaseapp.com",
  projectId: "donggukuniv",
  storageBucket: "donggukuniv.appspot.com",
  messagingSenderId: "673576794273",
  appId: "1:673576794273:web:41c34aa33f22da97b058e0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore(); // NoSQL 데이터베이스
export const storage = firebase.storage(); // 이미지 저장 스토리지
const auth = firebase.auth();

export const getUserIdToken = () => {
  return firebase.auth().currentUser.getIdToken().then(function(idToken) {
    // Send token to your backend via HTTPS
    // userIdToken = idToken;
    // console.log("getUserIdToken"+idToken);

  }).catch(function(error) {
    // Handle error
    console.error(error);
    return null;
  });
}

export default auth;