import React from "react";
import { Button, Typography, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "common/view/View.css";

const { Paragraph, Text } = Typography;

const Error = ({ title, message }) => {
  return (
    <div className="error-wrap">
      <Result
        status="error"
        title={title}
        subTitle={message}
        extra={[
          <Button type="primary" key="console">
            확인
          </Button>,
        ]}
      ></Result>
    </div>
  );
};

export default Error;
