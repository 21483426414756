import { useState, useEffect } from "react";
import auth from "services/firebase";
import axios from "axios";
import { defaultUrl } from "services/api.helpers";



const useGetUser = () => {

    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null
    });

    const [trigger, setTrigger] = useState(0);

    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(Date.now());
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if(user){
                axios.get(`${defaultUrl}/users/${user.uid}`,{
                    headers: { Authorization: user._lat },
                  }).then(res => {
                    const data = res.data;
                        setState({
                            ...state,
                            loading: false,
                            data: data
                        });
                  }).catch(error =>{
                    //   사용자 정보 get 오류.
                      console.log(error);
                      setState({
                        ...state,
                        loading: false,
                        error: error
                    });
                });
            }else{
                // logged out
                auth.signOut();
                setState({
                    ...state,
                    loading: false
                });
                return;
            };
        });
    },[trigger]);

    return {...state, refetch};
};

export default useGetUser;