import { Table, Tag, Space } from 'antd';
import { min } from 'moment';

export default function Compose() {
    const columnSubject = [
        {
          title: '강의 내용',
          dataIndex: 'columns1',
          key: '1',
          align: 'center',
          width: "16%",
        },
        {
          title: '강의 형태',
          dataIndex: 'columns2',
          key: '2',
          align: 'center',
          width: "16%",
        },
        {
          title: '설명',
          dataIndex: 'columns3',
          key: '3',
          render: (text, record) => (
            <ul className='list-style'>
              <li>{record.columns3List1}</li>
              <li>{record.columns3List2}</li>
              <li>{record.columns3List3}</li>
            </ul>
          ),
        },
      ];
    const dataSubject = [
        {
          key: '1',
          columns1: '공학 설계 이론',
          columns2: '플립드러닝',
          columns3List1: '• 공학 설계를 위한 문제 인식, 창의적 발상도구 사용법, 문제 정의문 작성법, 아이디어 도출/평가 방법 등에 대하여 이론 교육을 실시함.',
          columns3List2: '• 매주 첫시간은 이론교육으로 이루어지면, 두번째시간은 이론 내용을 실습하는 형태의 플립드 러닝으로 진행함.',
        },
        {
            key: '2',
            columns1: '아두이노 실습',
            columns2: '강의 및 실습',
            columns3List1: '• 아두이노 기본 원리와 간단한 센서/장치 사용방법에 대하여 강의함.',
            columns3List2: '• 조별로 아두이노 센서/장치 활용 방법을 실습하며, 구동원리 및 확장 가능성을 체험함.',
        },
        {
            key: '3',
            columns1: '프로젝트 설계',
            columns2: '프로젝트',
            columns3List1: '• 공학 설계 이론으로부터 문제를 정의하고, 해결 대상을 구체화함.',
            columns3List2: '• 아두이노를 도구로 하여 주어진 제한조건 내에서문제를 해결하는 최적의 방법을 도출함.',
            columns3List3: '• 하나의 시스템으로 설계프로젝트 결과물을 마무리함.'
        },
        {
            key: '4',
            columns1: '연말경진대회',
            columns2: '설계 작품 제출',
            columns3List1: '• 지원자에 한하여, 설계프로젝트 결과물을 경진대회에 제출할 수 있음.',
            columns3List2: '• 경진대회 제출 시 설계프로젝트 작품을 설명할 수있는 별도의 보고서 제출이 필요함.',
        },
    ]
    const columnCourse = [
        {
          title: '주(week)',
          dataIndex: 'key',
          key: '1',
          align: 'center',
          width: "15%",
        },
        {
          title: '강의내용',
          dataIndex: 'columns2',
          key: '2',
          align: 'center',
          render: (text, record) => (
            <ul>
              <li>{record.columns2}</li>
              <li>{record.columns2List1}</li>
              <li>{record.columns2List2}</li>
              <li>{record.columns2List3}</li>
            </ul>
          ),
          width: "55%",
        },
        {
          title: '비고',
          dataIndex: 'columns3',
          key: '3',
          width: "30%",
          align: 'center',
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index === 0) {
              obj.props.rowSpan = 5;
            }
            if (index === 6) {
              obj.props.rowSpan = 3;
            }
            return obj;
          },
        },
      ];
    const dataCourse = [
        {
          key: '1',
          columns2: '강의소개, 공학이란, 창의성, 팀워크',
          columns3: '공학설계 이론 / FL 방식 이론 교육',
        },
        {
          key: '2',
          columns2: '공학설계과정, 마인드맵, 자료조사 방법',
        },
        {
          key: '3',
          columns2: '문제정의, 시스템사고, 원인결과도표, 기능분석도',
        },
        {
          key: '4',
          columns2: '아이디어 도출, 스캠퍼, 발명원리',
        },
        {
          key: '5',
          columns2: '아이디어 평가, K-T 프로세스',
        },
        {
          key: '6',
          columns2: '필드트립',
          columns3: '문제인식 및 정의를 위한 현장 탐방',
        },
        {
          key: '7',
          columns2: '아두이노 이론/실습 1,2',
          columns2List1: '- 택트스위치로 LED 켜기',
          columns2List2: '- 가변저항으로 LED 밝기 조절하기',
          columns3: '아두이노 실습 강의 및 실습으로 구성',
        },
        {
          key: '8',
          columns2: '아두이노 이론/실습 3,4',
          columns2List1: '- LCD 1602(12C)에 문자/센서값 출력',
          columns2List2: '- 온습도 센서 이용하기',
        },
        {
          key: '9',
          columns2: '아두이노 이론/실습 5,6',
          columns2List1: '- 서보모터 사용하기',
          columns2List2: '- 블루투스 모듈, 초음파 센서 사용하기',
        },
        {
          key: '10 ~ 14',
          columns2: '아두이노를 활용한 설계 프로젝트 진행',
          columns2List1: '1) 학생들이 스스로 전공 관련 설계 주제를 선정 (적절한 설계 주제인지 교강사가 점검)',
          columns2List2: '2) 선정한 문제를 해결할 수 있는 아이디어를 도출하고 아두이노를 활용하여 이를 구현 (아두이노 활용 능력보다는 문제해결능력, 창의성 등을 평가)',
          columns2List3: '3) 설계 보고서 작성 및 작품 완성',
          columns3: '주차별로 설계 내용 설계보고서 작성',
        },
        {
            key: '15',
            columns2: '설계프로젝트 최종 발표',
            columns3: '분반별 진행',
        },
    ]

    return(
        <>
        <div className="compose info">
            <div className='txt-main-title'>
              <i className="para-line"/>강의내용<br/><br/>
              <span className='txt-main-txt'>어드벤처디자인 교과목은 크게 아래의 3가지 요소로 구분됩니다.</span>
            </div>
            <Table columns={columnSubject} dataSource={dataSubject} pagination={false} className="table-subject" bordered/>
            <div className='txt-main-title'>
              <i className="para-line"/>15주 차 강의계획<br/><br/>
              <span className='txt-main-txt'>15주차 강의의 경우 아래와 같이 강의가 구성될 수 있으며, 각 분반별로 이론/실습/설계에 배정되는 시간은 유연하게 조절될 수 있음.</span>
            </div>
            <Table columns={columnCourse} dataSource={dataCourse} pagination={false} bordered className="table-course"/>
        </div>
        </>
    )
}