import React from "react";
import axios from "axios";
import auth from "services/firebase";
import {defaultUrl} from "services/api.helpers";


export const createInquiry = async (data) => {
    const token = await auth.currentUser
    .getIdToken()
    .then((idToken) => idToken);

    return await axios
    .post(`${defaultUrl}/inquiries`, data, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
        // console.log(response.data);
        // console.log("board created");
        return {
            status: 'success',
            result: response.data
        };
    })
    .catch((err) => {
        // console.log(err);
        return {
            status: 'failed',
            result: err.response.data
        };
    });
};

export const createInquiryAnonymous = async (data) => {
    return await axios
        .post(`${defaultUrl}/inquiries-private`, data, {
            headers: {
                // Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then((response) => {
            // console.log(response.data);
            // console.log("board created");
            return {
                status: 'success',
                result: response.data
            };
        })
        .catch((err) => {
            // console.log(err);
            return {
                status: 'failed',
                result: err.response.data
            };
        });
};

export const deleteInquiry = async (id) => {
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);

    return await axios.delete(`${defaultUrl}/inquiries/${id}`, {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    })
    .then((response) => {
        // console.log(response.data);
        // console.log("board created");
        return {
            status: 'success',
            result: response.data
        };
    })
    .catch((err) => {
        // console.log(err);
        return {
            status: 'failed',
            result: err.response.data
        };
    });
};



export const putInquiry = async(id ,data) => {
    // console.log(data);
    const token = await auth.currentUser
        .getIdToken()
        .then((idToken) => idToken);

    return await axios
        .put(`${defaultUrl}/inquiries/${id}`, data, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then((response) => {
            // console.log(response.data);
            // console.log("board updated");
            return {
                status: 'success',
                result: response.data
            };
        })
        .catch((err) => {
            // console.log(err);
            return {
                status: 'failed',
                result: err.response.data
            };
        });
}



export const createComment = async (data) => {
  // console.log(data);
  const token = await auth.currentUser
  .getIdToken()
  .then((idToken) => idToken);

  return await axios.post(`${defaultUrl}/inquiries/${data.inquiryId}/answers`,
  { description: data.description}, {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
  .then((response) => {
      // console.log(response.data);
      // console.log("board created");
      return {
          status: 'success',
          result: response.data
      };
  })
  .catch((err) => {
      // console.log(err);
      return {
          status: 'failed',
          result: err.response.data
      };
  });
};


export const putComment = async (data) => {
  console.log(data);
  const token = await auth.currentUser
  .getIdToken()
  .then((idToken) => idToken);

  return await axios
  .put(
    `${defaultUrl}/inquiries/${data.inquiryId}/answers/${data.commentId}`,
   { description: data.description},
   {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
  .then((response) => {
      // console.log(response.data);
      // console.log("board updated");
      // window.location.reload();
      return {
          status: 'success',
          result: response.data
      };
  })
  .catch((err) => {
      // console.log(err);
      return {
          status: 'failed',
          result: err.response.data
      };
  });
};


export const deleteComment = async (bbsId, id) => {
  const token = await auth.currentUser
    .getIdToken()
    .then((idToken) => idToken);

  return await axios.delete(`${defaultUrl}/inquiries/${bbsId}/answers/${id}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
          // console.log(response.data);
          // console.log("board created");
          return {
              status: 'success',
              result: response.data
          };
      })
      .catch((err) => {
          // console.log(err);
          // window.location.reload();
          return {
              status: 'failed',
              result: err.response.data
          };
      });
};

