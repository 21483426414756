import { Col, Row } from  "antd";

export default function Introduce() {
    return(
        <>
        <div className="introduce info">
            <div className="title-wrap">
                <h3 className="txt-para-title">어드벤처 디자인</h3>
                <h4 className="txt-main-title">Adventure Design</h4> 
            </div>
            <Row gutter={[24, 24]}>
                <Col md={12} sm={24}>            
                    <div className="introduce-img"></div>
                </Col>
                <Col md={12} sm={24}>
                    <div className="txt-main-txt">
                    <i className="para-line"/>21세기는 바야흐로 실용학문인 공학을 바탕으로 국가의 성장동력이 창출
                    되는 시대입니다. 동국대학교 공과대학은 전국에서 가장 먼저 공학교육인
                    증을 실시한 학과로서 학년 및 전공별 맞춤형 공학교육을 시행해 왔습니다. 
                    이를 위한 첫번째 단계로서 동국대학교 공과대학은 1학년을 대상으로
                    공학기초설계 교과목을 운영하여 공학문제를 인식하는 방법에서부터 최적해를 
                    구하는 방법에 이르기까지 공학 문제 해결의 전반을 경험할 수 있도록 교육해 왔습니다.
                    <br/><br/>
                    <i className="para-line"/>최근 4차산업 혁명시대의 도래에 따라 정보통신, 코딩 등 컴퓨터 및 소프트웨어 
                    관련된 전공에서는 변화하는 추세에 발맞추어 공학 설계 측면에서
                    다양한 교육 프로그램을 개발하고 있으나, 그 외의 공학 전공에서는 공학
                    문제 인식과 해결 측면에서 소프트웨어 관련 도구를 사용한 교육 프로그램이 제한적이었습니다.
                    <br/><br/>
                    <i className="para-line"/>동국대학교 공과대학은 공학이 중심이 되는 21세기, 그리고 4차산업혁명이 
                    꽃을 피울 미래에 사회가 요구하는 인재를 양성하기 위하여 그동안 학과별로 
                    전공 맞춤형 공학기초설계로 운영된 교과목을 “어드벤처디자인”이라는 이름하에 재탄생시키고자 합니다.
                    <br/><br/>
                    <i className="para-line"/>어드벤처디자인 교과목에서는 기존 공학문제 인식과 해결을 위한 기초 교육은 물론이고, 
                    문제 해결을 위한 도구로서 아두이노 교육을 실시함으로서, 
                    공과대학 신입생들로 하여금 각자의 전공분야에 맞는 문제를 하드웨어와 
                    소프트웨어로 직접 해결할 수 있는 올인원 설계를 구현하고자 합니다. 
                    이 교과목을 통해 공학자로서의 기본 소양을 함양하는 한편, 4차산업혁명 
                    시대 대응형 교육을 실시하여, 공학 설계 교육을 내실화하고자 합니다.
                    </div>
                </Col>
            </Row>
        </div>
        </>
    )
}