import moment from "moment";
import { useHistory } from "react-router-dom";
import { Space, Table, Spin, Tag, Row, Col, Button } from "antd";
import LoadingView from "common/view/LoadingView";

const ArduMaterialList = ({ data, page, setPage, totalCnt }) => {
  const history = useHistory();
  const pathName = history.location.pathname;
  const columns = [
    {
      title: "번호",
      width: "8%",
      dataIndex: "listNo",
      align: "center",
      className: "noTxt",
      key: "listNo",
      render: (number) =>
        parseInt(totalCnt / 12) > page
          ? totalCnt - 12 * (page + 1) + number
          : number,
    },
    {
      title: "학기",
      dataIndex: "semesterCode",
      width: "12%",
      key: "semesterCode",
      align: "center",
      render: (i) => {
        if (i) {
          let data = `${i}`;
          let yearAndMonth = data.substring(0, 4);
          let semester = data.substring(4, 5);
          return `${yearAndMonth}년 ${semester}학기`;
        } else {
          return "";
        }
      },
    },
    {
      title: "분반명",
      width: "15%",
      dataIndex: "className",
      align: "center",
      key: "className",
      render: (item) =>
        item ? (
          item.length > 12 ? (
            <a>{item.slice(0, 12)}..</a>
          ) : (
            <a>{item}</a>
          )
        ) : null,
    },
    {
      title: "제목",
      dataIndex: "title",
      width: "39%",
      key: "title",
      className: "titleTxt",
      render: (text) =>
        text ? (
          text.length > 40 ? (
            <a className="board-title-link">{text.slice(0, 40)}..</a>
          ) : (
            <a className="board-title-link">{text}</a>
          )
        ) : null,
    },
    {
      title: "작성자",
      dataIndex: "createdBy",
      width: "8%",
      key: "writer",
      align: "center",
      render: (item) => <span>{item.name}</span>,
      className: "writerTxt",
    },
    {
      title: "날짜",
      dataIndex: "createdAt",
      render: (item) => moment(item).format("YY/MM/DD"),
      width: "10%",
      align: "center",
      key: "createdAt",
      className: "grayTxt",
    },
  ];

  const onChange = (current) => {
    let crr = current - 1;
    // console.log(crr);
    return setPage(crr);
  };

  const onRow = (rowData, rowIndex) => {
    return {
      onClick: () => {
        history.push({
          pathname: `${pathName}/detail/${rowData.id}`,
        });
      },
    };
  };

  return (
    <>
      <div>
        <div
          className="List"
          style={{
            maxWidth: "1200px",
            margin: "-5px auto",
            padding: "0 24px 24px",
          }}
        >
          <Space align="end" size="small" direction="vertical">
            <Table
              tableLayout="fixed"
              expandRowByClick={true}
              columns={columns}
              dataSource={data}
              onRow={onRow}
              rowKey={totalCnt}
              pagination={{
                current: page + 1,
                pageSize: 12,
                total: totalCnt,
                onChange: onChange,
                size: "small",
                showSizeChanger: false,
                showQuickJumper: false,
              }}
            />
          </Space>
        </div>
      </div>
    </>
  );
};

export default ArduMaterialList;
