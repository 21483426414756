import React, { useState, useEffect } from "react";
import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { Layout } from "antd";
import "react-quill/dist/quill.snow.css";
import "common/board/Board.css";
import "info/info.css";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import { useParams, Switch, Route } from "react-router-dom";
import Introduce from "info/Introduce";
import Compose from "info/Compose";

const { Content } = Layout;

const Info = () => {
  let { value } = useParams();
  const title = "강좌소개";
  const [subTitle, setSubTitle] = useState("");
  const menuList = [
    { name: "교과목 소개", link: "/info/introduce" },
    { name: "강의 구성", link: "/info/compose" },
  ];

  useEffect(() => {
    switch (value) {
      case "introduce":
        return setSubTitle("교과목 소개");
      case "compose":
        return setSubTitle("강의 구성");
      default:
        return setSubTitle("교과목 소개");
    }
  }, [value]);

  return (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle}/>
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content style={{ marginBottom: 50, paddingBottom: "50px" }}>
              <Switch>
                <Route exact path="/info/introduce">
                  <Introduce />
                </Route>
                <Route exact path="/info/compose">
                  <Compose />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );
};

export default Info;
