import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import auth from "services/firebase";
import {putUserInfo} from "auth/AuthController";
import ErrorModal from "common/view/ErrorModal";
import { modalState } from "recoil/modalState";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { authState, User, signUpUserEmail } from "recoil/authState";
import { viewRoleState, viewSignUpState } from "recoil/viewState";
import AuthHeader from "auth/AuthHeader";
import { useHistory } from "react-router-dom";
import { actionCodeSettings } from "common/constants";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {signInError} from "auth/SignUpError";

const SignUpMento = () => {
    type inputStatus =  "" | "success" | "warning" | "error" | "validating" | undefined;
    type userValue = {
        department: string;
        email: string,
        password: string,
        passwordChk: string,
    }

    // 로그인 회원가입 페이지가 동일한 페이지에서 토글되므로, 수동으로 탭전환하는 코드 활용 by Sarc
    const switchToSignInTab = ()=>{
        const _switchTab = document.querySelector("div.ant-tabs-tab:not(.ant-tabs-tab-active)") as HTMLElement;
        if (!!_switchTab) {
            _switchTab.click();
        }
    }

    // 권한에 따른 전역 view state 값을 받아오고 셋팅하는 부분.
    const emailVeri = useSetRecoilState(signUpUserEmail);
    const roleView= useRecoilValue(viewRoleState);
    const setModal = useSetRecoilState(modalState);
    const history = useHistory();

    const [ userState, setUserState ] = useRecoilState(authState);
    const [ status, setStatus] = useState<inputStatus>("");

    const createEmailAccount = async(value:userValue) => {
        auth
            .createUserWithEmailAndPassword(value.email, value.password)
            .then((userCredential) => {
                const user = auth.currentUser!;
                // console.log(user.refreshToken);

                const currentUser:User = {
                    id: user.uid,
                    email: value.email,
                    emailVerified: user.emailVerified,
                    name: userState.name,
                    idNumber: userState.idNumber ?? "",
                    role: roleView ?? "ST",
                    refreshToken: user.refreshToken,
                    department: value.department
                };

                // setUserState({...userState, ...currentUser});
                // console.log('new user id 는 : '+ userState.id);
                emailVeri(value.email);

                putUserInfo(user, currentUser);

                user
                    .sendEmailVerification({url: window.location.protocol+"//"+window.location.host+"/?verified=true"})
                    // .sendEmailVerification({url: "https://default-dev-dot-donggukuniv.du.r.appspot.com/?verified=true"})
                    .then(() => {
                        return history.replace("/auth/email");
                    }).catch((err) => {
                    console.log(`이메일 전송 에러${err}`);
                    setModal({
                        type: "error",
                        isActive: true,
                        title: `이메일 전송 에러 발생.`,
                        msg: `${err}`,
                        redirect: `/auth/sign`}
                    );
                });

            }).catch((error) => {
            const errorCode:string = error.code;
            const errorMessage:string = error.message;
            console.log(
                errorCode + errorMessage,
            );
            if (errorCode === 'auth/email-already-in-use') {        // 특정 에러일때(가입된 이메일 존재) 로그인 페이지 탭으로 전환해주는 함수 by Sarc
                switchToSignInTab();
            }
            return signInError(error, setModal)
        });
    }

    const onFinish = async (value:userValue) => {
        setStatus("validating");
        // validating 하고 나서 리턴해주기.
        // console.log("사용자 입력 정보는 : ", value );
        // validating 끝나고 input 상태 변경해줌.
        setStatus("");
        return createEmailAccount(value);
    };
    return (
        <>
            { roleView === "PR" ?
                AuthHeader(`교수님 회원가입`) :
                AuthHeader(`기업 멘토님 회원가입`)
            }
            <ErrorModal />
            <Form
                name="normal_signin"
                className="signin-form"
                initialValues={{
                    email: userState.email
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="이메일"
                    name="email"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                >
            <span className="ant-input-affix-wrapper" >
              <Input
                  value={userState.email}
                  placeholder={userState.email}
                  disabled
              />
            </span>
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="비밀번호"
                    rules={[
                        { required: true, message: "비밀번호를 입력해 주세요." },
                        { required: false, min: 8, message: "비밀번호는 최소 8자 이상 입력해주세요" }
                    ]}
                    name="password"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                >
                    <Input.Password
                        className="passw-input"
                        placeholder="비밀번호 설정 ( *8자 이상의 문자 + 숫자 조합 )"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="비밀번호확인"
                    name="passwordChk"
                    className="form-item"
                    validateStatus={status}
                    hasFeedback
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: '비밀번호를 다시 입력해주세요.',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        className="passw-input"
                        placeholder="비밀번호확인"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18
                    }}
                    label="소속"
                    className="form-item"
                    name="department"
                    rules={[{required: true, message: '소속을 입력하지 않았습니다.'}]}
                >
            <span className="ant-input-affix-wrapper" >
                <Input
                    placeholder="소속(학과/기업명)"
                />
            </span>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button default-auth-bt"
                        // onClick={() => setError(false)}
                    >
                        회원가입
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
};

export default SignUpMento;
