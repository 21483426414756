import React from "react";
import { atom, selector } from "recoil";



export const defalutValue_className = atom<String | null>({
  key: "defalutValue_className",
  default: null,
});

export const defalutValue_Sem = atom<String | null>({
  key: "defalutValue_Sem",
  default: null,
});

export const defalut_sem_Selector = selector({
  key: "defalut_sem_Selector",
  get: ({ get }) => {
    const values = get(defalutValue_Sem);

    if(!values){
      return null;
    }else if(values === "전체 학기"){
      return "전체 학기";
    }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const semState = atom<String>({
  key: "semState",
  default: "",
});

export const semState_Selector = selector({
  key: "semState_Selector",
  get: ({ get }) => {
    const values = get(semState);

    if(!values){
      return null;
    }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const classState = atom<Object>({
  key: "classState",
  default: {
    id: "",
    className: "",
  },
});

// sem이 바뀔때마다 저장해놓을 분반 리스트
export const classListState = atom<any>({
  key: "classListState",
  default: null,
});

export const semListState = atom<any>({
  key: "semListState",
  default: null,
});








export const defalutValue_className_ArdMaterial = atom<String | null>({
  key: "defalutValue_className_ArdMaterial",
  default: null,
});

export const defalutValue_Sem_ArdMaterial = atom<String | null>({
  key: "defalutValue_Sem_ArdMaterial",
  default: null,
});

export const defalut_sem_Selector_ArdMaterial = selector({
  key: "defalut_sem_Selector_ArdMaterial",
  get: ({ get }) => {
    const values = get(defalutValue_Sem_ArdMaterial);

    if(!values){
      return null;
    }else if(values === "전체 학기"){
        return "전체 학기";
      }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const semState_ArdMaterial = atom<String>({
  key: "semState_ArdMaterial",
  default: "",
});

export const semState_Selector_ArdMaterial = selector({
  key: "semState_Selector_ArdMaterial",
  get: ({ get }) => {
    const values = get(semState_ArdMaterial);

    if(!values){
      return null;
    }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const classState_ArdMaterial = atom<Object>({
  key: "classState_ArdMaterial",
  default: {
    id: "",
    className: "",
  },
});

// sem이 바뀔때마다 저장해놓을 분반 리스트
export const classListState_ArdMaterial = atom<any>({
  key: "classListState_ArdMaterial",
  default: null,
});

export const semListState_ArdMaterial = atom<any>({
  key: "semListState_ArdMaterial",
  default: null,
});


export const classUserState = atom<Object>({
  key: "classUserState",
  default: {
    classId: "",
    className: "",
    sem: null,
  },
});


export const defalutValue_className_Design = atom<String | null>({
  key: "defalutValue_className_Design",
  default: null,
});

export const defalutValue_Sem_Design = atom<String | null>({
  key: "defalutValue_Sem_Design",
  default: null,
});

export const defaultValue_Sem_Selector = selector({
  key: "defaultValue_Sem_Selector",
  get: ({ get }) => {
    const values = get(defalutValue_Sem_Design);

    if(!values){
      return null;
    }else if(values === "전체 학기"){
      return "전체 학기";
    }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const semState_Des = atom<String>({
  key: "semState_Des",
  default: "",
});

export const semState_Des_Selector = selector({
  key: "semState_Des_Selector",
  get: ({ get }) => {
    const values = get(semState_Des);

    if(!values){
      return null;
    }else{
      let i = values.toString();
      let yr = i.substring(0, 4);
      let semester = i.substring(4, 5);

      return `${yr}년 ${semester}학기`;
    }
  },
});

export const classState_Des = atom<Object>({
  key: "classState_Des",
  default: {
    id: "",
    className: "",
  },
});

// sem이 바뀔때마다 저장해놓을 분반 리스트
export const classListState_Des = atom<any>({
  key: "classListState_Des",
  default: null,
});

export const semListState_Des = atom<any>({
  key: "semListState_Des",
  default: null,
});