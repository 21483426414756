import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import ReactQuillEditor from "common/form/ReactQuillEditor";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Select, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createInquiry } from "services/inquiryApi";
import "common/form/Form.css";
import useGetDetailWt from "hooks/useGetDetailWt";
import { defaultUrl } from "services/api.helpers";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const MyInquiryWrite = ({ url, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const { Option } = Select;
  const [form] = Form.useForm();

  const { bbsId } = useParams();
  const history = useHistory();

  const [quillData, setQuillData] = useState(null);
  const [apiLoading, setLoading] = useState(false);

  function onEditorChange(value) {
    setQuillData(value);
  }

  const HandleFinish = async (values) => {
    // console.log(values);
    let replaceQuillText = null;

    // console.log(quillData);

    if (quillData) {
      replaceQuillText = quillData.replace(/"/gi, `'`);
    }

    if (replaceQuillText) {
      const newValue = {
        title: values?.title,
        description: replaceQuillText,
        type: values?.type,
        category: "PRIVATE",
      };

      // console.log(newValue);
      setLoading(true);
      return postDataApi(newValue);
    } else {
      const newValue = {
        title: values?.title,
        type: values?.type,
        category: "PRIVATE",
      };
      setLoading(true);
      return postDataApi(newValue);
    }
  };

  const postDataApi = async (data) => {
    // console.log(`postDataApi: ${JSON.stringify(data)}`);
    createInquiry(data)
      .then((e) => {
        setLoading(false);
        // console.log(`문의글이 정상적으로 작성되었습니다.`);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("inquiry create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `문의글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("inquiry create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const handleChange = (...args) => {};

  const openMessage = () => {
    message.success({
      content: "문의글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          form={form}
          name="inquiryForm"
          layout="vertical"
          className="writeForm"
          onFinish={HandleFinish}
          onFinishFailed={HandleFinishFaild}
          initialValues={{
            type: "기타 문의",
            title: "",
            description: "",
          }}
        >
          <Col span={2}>
            <Button onClick={onHandleBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
              <span className="list-text-bt">목록</span>
            </Button>
          </Col>
          <Row className="detailMainInfoWrap">
            <Col span={18} offset={3}>
              <h2>{title} 문의하기</h2>
            </Col>
          </Row>
          <Row>
            <Col span={4} offset={0}>
              <Form.Item
                label="문의 유형"
                name="type"
                rules={[
                  { required: true, message: "제목을 입력하지 않았습니다." },
                ]}
              >
                <Select value="" onChange={handleChange}>
                  <Option value="분반 선택">분반 선택</Option>
                  <Option value="기타 문의">기타 문의</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              rules={[
                { required: true, message: "제목을 입력하지 않았습니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
            >
              <ReactQuillEditor
                value={quillData}
                onEditorChange={onEditorChange}
              />
            </Form.Item>
            <Form.Item>
              {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 문의글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
              <Warning handleGoBack={onHandleBack} />
              <Button
                type="primary"
                htmlType="submit"
                className="write-submit-button"
              >
                작성
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default MyInquiryWrite;
