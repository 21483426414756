import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accessEdit } from "recoil/authState";
import { Comment, Form, message, Modal, Result } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import Editcomment from "common/inquiry/Editcomment";
import HasComment from "common/inquiry/HasComment";
import NewComment from "common/inquiry/NewComment";
import { createComment, deleteComment, putComment } from "services/inquiryApi";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const QnAComment = (props) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const { bbsId, status, refetch } = props;
  const isEdit = useRecoilValue(accessEdit);
  const [isReplyEmpty, setIsReplyEmpty] = useState(true);
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [state, setState] = useState({
    commentId: null,
    inquiryId: bbsId,
    description: null,
    loading: false,
    error: null,
    value: null,
  });

  useEffect(() => {
    if (status) {
      setIsReplyEmpty(false);
    } else {
      setIsReplyEmpty(true);
    }
  }, [status]);

  const [form] = Form.useForm();

  const postApi = async () => {
    await createComment(state)
      .then((e) => {
        if (e?.status === "success") {
          openMessageAnswerPostSuccess();

          setState({ ...state, loading: false });
          setIsReplyEmpty(false);
          refetch(); // 답변 생성시 detail에 status 갱신을 위한 refetch by Sarc
          return setReload(true);
        } else {
          console.log("answer create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "등록 실패",
            msg: `답변 등록에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
          });
        }
      })
      .catch((e) => {
        console.log("answer create failed - ", JSON.stringify(e));
      });
  };

  const putApi = async () => {
    await putComment(state)
      .then((e) => {
        if (e?.status === "success") {
          openMessageAnswerPutSuccess();

          setEdit(false);
          return setReload(true);
        } else {
          console.log("answer edit failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "수정 실패",
            msg: `답변 수정에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
          });
        }
      })
      .catch((e) => {
        console.log("answer edit failed - ", JSON.stringify(e));
      });
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      inquiryId: bbsId,
      loading: true,
    });

    if (state.description !== null && state.inquiryId !== null) {
      if (edit) {
        return putApi();
      } else {
        return postApi();
      }
    }
  };

  const changeEditMode = async () => {
    setEdit(true);
    setState({ ...state, inquiryId: bbsId });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      description: event.target.value,
      value: event.target.value,
    });
  };

  // 삭제 모달
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const answerDelete = async () => {
    if (state.commentId != null) {
      await deleteComment(bbsId, state.commentId)
        .then((e) => {
          if (e?.status === "success") {
            openMessageAnswerDeleteSuccess();
          } else {
            console.log("answer delete failed - ", JSON.stringify(e?.result));
            return setModal({
              type: "error",
              isActive: true,
              title: "삭제 실패",
              msg: `답변 삭제에 실패하였습니다.${
                !!e.result?.errorCode && !!e.result?.message
                  ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                  : ""
              }`,
            });
          }
        })
        .catch((e) => {
          console.log("answer delete failed - ", JSON.stringify(e));
        });
      setReload(true);
      setIsModalVisible(false);
      refetch(); // 답변 삭제시 detail에 status 갱신을 위한 refetch by Sarc
      return setIsReplyEmpty(true);
    }
  };

  const answerDeleteCancled = () => {
    setIsModalVisible(false);
  };

  const openMessageAnswerPostSuccess = () => {
    message.success({
      content: "답변이 등록되었습니다.",
      key,
      duration: 2,
    });
  };
  const openMessageAnswerPutSuccess = () => {
    message.success({
      content: "답변이 수정되었습니다.",
      key,
      duration: 2,
    });
  };
  const openMessageAnswerDeleteSuccess = () => {
    message.success({
      content: "답변이 삭제되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <div className="comment-wrapper">
      {isEdit ? (
        <Form
          name="replyform"
          form={form}
          style={{ border: "1px solid #e0e0e0", padding: "24px" }}
          onFinish={handleSubmit}
        >
          <div className="comment-box">
            {!isReplyEmpty ? (
              edit ? (
                <Editcomment
                  bbsId={bbsId}
                  setEdit={setEdit}
                  state={state}
                  setState={setState}
                />
              ) : (
                <HasComment
                  showModal={showModal}
                  bbsId={bbsId}
                  edit={edit}
                  setState={setState}
                  reload={reload}
                  setReload={setReload}
                  changeEditMode={changeEditMode}
                />
              )
            ) : (
              <Comment
                content={
                  <NewComment
                    onChange={handleChange}
                    state={state}
                    setState={setState}
                  />
                }
              />
            )}
          </div>

          <Form.Item>
            <Modal
              visible={isModalVisible}
              onOk={answerDelete}
              onCancel={answerDeleteCancled}
              okText="확인"
              cancelText="취소"
              okButtonProps={{ size: "large", danger: "true" }}
              cancelButtonProps={{ size: "large" }}
            >
              <Result
                icon={<DeleteTwoTone />}
                title="해당 답변이 삭제됩니다."
                subTitle="답변을 삭제하겠습니까?"
              />
            </Modal>
          </Form.Item>
        </Form>
      ) : !isReplyEmpty ? (
        <div className="comment-box">
          <HasComment
            showModal={showModal}
            bbsId={bbsId}
            edit={edit}
            setState={setState}
            reload={reload}
            setReload={setReload}
            changeEditMode={changeEditMode}
          />
        </div>
      ) : (
        <div className="st-empty-reply-box">
          <span>아직 등록된 답변이 없습니다.</span>
        </div>
      )}
      <ErrorModal />
    </div>
  );
};

export default QnAComment;
