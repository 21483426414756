import { Button, Row, Col } from "antd";
import { useLocation, useHistory } from "react-router-dom";

const WriteButtonForBest = () => {
    const location = useLocation();
    const history = useHistory();

    return (
        <Col className="write-btn-wrap" span={3} justify="end">
            <Button onClick={() => history.push(`${location.pathname}/write`)}>
                글쓰기
            </Button>
        </Col>
    );
};

export default WriteButtonForBest;
