import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { accessEdit } from "recoil/authState";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import {
  Space,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  Skeleton,
  Upload,
  List,
  Modal,
  Result,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteTwoTone,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { deleteBoard } from "services/boardApi";
import ReactQuill from "react-quill";
import moment from "moment";
import { defaultUrl, refreshToken } from "services/api.helpers";
import DetailHeaderPublic from "common/board-api/DetailHeaderPublic";
import DetailHeaderAdm from "common/board-api/DetailHeaderAdm";

const { Title, Text } = Typography;

const BoardDetailContentAttach = (props) => {
  const { loading, url, detailData } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isEdit = useRecoilValue(accessEdit);
  const location = useLocation();
  const history = useHistory();

  const [prevLink, setprevLink] = useState({
    link: null,
    title: null,
  });

  const [nextLink, setnextLink] = useState({
    link: null,
    title: null,
  });
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!!detailData?.info?.fileList) {
      setFileList(detailData?.info?.fileList);
    }
    return () => null;
  }, [location.pathname]);

  const curpathpoint = location.pathname.indexOf("/detail/");
  const curpath = location.pathname.slice(0, curpathpoint + 8);

  const prevFunction = () => {
    if (detailData?.nextData !== null) {
      setprevLink({
        link: curpath + detailData?.nextData.id,
        title: detailData?.nextData.title,
      });
    }
    if (detailData?.nextData === null) {
      setprevLink({
        link: null,
        title: "이전 게시물이 존재하지 않습니다.",
      });
    }
  };

  const nextFunction = () => {
    if (detailData?.prevData !== null) {
      setnextLink({
        link: curpath + detailData?.prevData.id,
        title: detailData?.prevData.title,
      });
    } else {
      setnextLink({
        link: null,
        title: "다음 게시물이 존재하지 않습니다.",
      });
    }
  };

  useEffect(() => {
    prevFunction();
    nextFunction();
    return () => null;
  }, [detailData?.nearRow]);

  const onHandleBack = () => {
    // console.log(url);
    history.push(`${url}`);
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  // 업로드 모듈용 세팅
  const config = {
    fileList: fileList,
    onPreview: (e) => {
      // 파일을 클릭했을때, 주소를 받아서 api 주소로 재조합->클릭(안하면 호스트 주소대로 다운로드 됨)
      // if (cate == 'reference') {      // 일단 api상에선 토큰검증을 풀어두고, 클라이언트에서 막아둠(개발자툴 네트워크탭을 확인하지 않는 한 다이렉트 다운로드 링크가 노출되진 않음) by Sarc 210528
      //   if (userObj == null || userObj == undefined) {
      //     setShowErrMsg(true);
      //     return false
      //   }
      // }
      refreshToken().then(idToken=>{
        axios({
          url: defaultUrl + e.response, //your url
          headers: {
            "Authorization" : idToken,
            "Access-Control-Allow-Origin" : "*",
          },
          method: "GET",
          responseType: "blob", // important
        })
            .then((response) => {
              let filename = e.name;
              let data = response.data;
              let blob = new Blob([data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
              } else {
                let elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;
                document.getElementsByTagName("body")[0].appendChild(elem);
                elem.click();
                document.getElementsByTagName("body")[0].appendChild(elem);
              }
              // setShowSucMsg(true);
            })
            .catch((error) => {
              // if (!localToken) {
              //   setShowErrMsg(true);
              // } else {
              if (error.response) {
                console.log(error.response.data);
              }
              // }
            });
      })
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "",
      showRemoveIcon: false, // 삭제 버튼 비활성화 !!중요!!
    },
    listType: "text",
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const boardDelete = async () => {
    setIsModalVisible(false);
    deleteBoard(detailData.id).then(() => {
      return onHandleBack();
    });
  };

  const deleteCancled = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row wrap align="middle" justify="start" style={{ overflow: "hidden" }}>
        {isEdit ? (
            <DetailHeaderAdm url={url} bbsId={detailData.id} />
        ) : (
            <DetailHeaderPublic url={url} />
        )}
        <Row>
          <Col span={24} style={{ margin: "50px 0 25px", padding: "0 20px" }}>
            <Title className="titleText">{detailData.title}</Title>
            <Text type="secondary" className="secondaryText">
              <Space>
                {(detailData?.category === 'NOTICE'|| detailData?.category === 'CONTNOTI') ? '관리자' : detailData?.createdBy?.name}
                {moment(detailData.createdAt).format("YY/MM/DD")}ㅤ조회수
                {detailData.views}
              </Space>
            </Text>
            <Divider className="titleDivider" />
          </Col>
        </Row>
        <Row
          style={{ padding: "0 36px", marginTop: "-40px", marginBottom: 20 }}
        >
          <Upload {...config} />
        </Row>
        <Row align="middle" justify="center">
          {detailData ? (
            <>
              <ReactQuill
                value={detailData.description}
                readOnly={true}
                theme={"bubble"}
              />
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
          {/*아래가 게시글 내용 보여주는 방식*/}
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
          <Divider className="titleDivider" />
          <Col span={24} style={{ margin: "0 0 25px" }}>
            <List className="prevnextList" itemLayout="horizontal">
              <List.Item
                actions={[
                  <a key="list-loadmore-edit" href={nextLink.link}>
                    <UpOutlined />
                    ㅤ다음글
                  </a>,
                  <a key="list-loadmore-more" href={nextLink.link}>
                    {nextLink.title}
                  </a>,
                ]}
              />
              <List.Item
                actions={[
                  <a key="list-loadmore-edit" href={prevLink.link}>
                    <DownOutlined />
                    ㅤ이전글
                  </a>,
                  <a key="list-loadmore-more" href={prevLink.link}>
                    {prevLink.title}
                  </a>,
                ]}
              />
            </List>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default BoardDetailContentAttach;
