import { Button, Row, Col } from "antd";
import { useLocation, useHistory } from "react-router-dom";

const WriteButton = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <div className="board-top-control-bt role-hide-bt">
        <Row justify="end">
          <Col span={4}>
            <Button onClick={() => history.push(`${location.pathname}/write`)}>
              글쓰기
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WriteButton;
