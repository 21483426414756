import React, { useState, useEffect } from "react";
import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { Layout } from "antd";
import "common/board/Board.css";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import { useParams, Switch, Route, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userRoleState } from "recoil/authState";
import LoadingView from "common/view/LoadingView";
import NoAccess from "common/view/NoAccess";
import BoardWrite from "common/board-api/BoardWrite";
import BoardDetail from "common/board-api/BoardDetail";
import BoardListWt from "common/board-api/BoardListWt";
import BoardWriteAttach from "common/board-api/BoardWriteAttach";
import BoardEditAttach from "common/board-api/BoardEditAttach";
import BoardEdit from "common/board-api/BoardEdit";

const { Content } = Layout;

const Lecture = () => {
  let { value } = useParams();
  const viewState = useRecoilValue(userRoleState);
  const title = "강의자료";
  const [subTitle, setSubTitle] = useState("");
  const menuList = [
    { name: "교과목 게시판", link: "/lect/board" },
    { name: "강의 자료실", link: "/lect/material" },
  ];

  const history = useHistory();

  useEffect(() => {
    switch (value) {
      case "board":
        return setSubTitle("교과목 게시판");
      case "material":
        return setSubTitle("강의 자료실");
      default:
        return setSubTitle("강의자료");
    }
  }, [value]);

  const userLayout = (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content className="board-content-wrap">
              <Switch>
                <Route path="/lect/board" exact>
                  <BoardListWt category="lectBoard" />
                </Route>
                <Route path="/lect/board/write" exact>
                  <BoardWrite
                    category="lectBoard"
                    url="/lect/board"
                    title="교과목 게시판"
                  />
                </Route>
                <Route path="/lect/board/edit/:bbsId" exact>
                  <BoardEdit url="/lect/board" title="교과목 게시판" />
                </Route>
                <Route path="/lect/board/detail/:bbsId" exact>
                  <BoardDetail type="basic" url="/lect/board" />
                </Route>
                <Route exact path="/lect/material">
                  <BoardListWt category="lectMaterial" />
                </Route>
                <Route exact path="/lect/material/write">
                  <BoardWriteAttach
                    url="/lect/material"
                    category="lectMaterial"
                    title="강의 자료실"
                  />
                </Route>
                <Route exact path="/lect/material/detail/:bbsId">
                  <BoardDetail
                    type="attach"
                    url="/lect/material"
                    category="lectMaterial"
                  />
                </Route>
                <Route exact path="/lect/material/edit/:bbsId">
                  <BoardEditAttach url="/lect/material" title="강의 자료실" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );

  const switchView = () => {
    switch (viewState) {
      case "ST":
        return userLayout;
      case "PR":
        return userLayout;
      case "AS":
        return userLayout;
      case "CO":
        return userLayout;
      case "ADM":
        return userLayout;
      case "":
        return <LoadingView />;
      case "ALL":
        return <NoAccess />;
      default:
        return <LoadingView />;
    }
  };

  return <>{switchView()}</>;
};

export default Lecture;
