import { useState, useEffect } from "react";
import useGetUser from "hooks/useGetUser";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import ReactQuillEditor from "common/form/ReactQuillEditor";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createInquiry } from "services/inquiryApi";
import "common/form/Form.css";
import "common/board-api/BoardHeader.css";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const QnAWrite = ({ category, url, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const history = useHistory();
  const { loading, error, data, refetch } = useGetUser();
  const [quillData, setQuillData] = useState(null);
  const [apiLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(url);
  };

  function onEditorChange(value) {
    setQuillData(value);
  }

  const HandleFinish = async (values) => {
    const replaceQuillText = quillData.replace(/"/gi, `'`);

    const newValue = {
      category: category,
      title: values?.title,
      description: replaceQuillText,
    };

    setLoading(true);
    return postDataApi(newValue);
  };

  const postDataApi = async (data) => {
    createInquiry(data)
      .then((e) => {
        setLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("inquiry create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `문의글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("inquiry create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const convertSemesterCode = (code) => {
    if (code) {
      let data = `${code}`;
      let yearAndMonth = data.substring(0, 4);
      let semester = data.substring(4, 5);
      return `${yearAndMonth}년 ${semester}학기`;
    } else {
      return "";
    }
  };

  const openMessage = () => {
    message.success({
      content: "문의글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      {data !== null ? (
        <Row justify="center" className="detailMainInfoWrap">
          <Form
            name="inquiryForm"
            layout="vertical"
            className="writeForm"
            onFinish={HandleFinish}
            onFinishFailed={HandleFinishFaild}
            initialValues={{
              writer: data?.name,
              className: data?.classList[0]?.className,
              semesterCode: convertSemesterCode(
                data?.classList[0]?.semesterCode
              ),
            }}
          >
            <Col span={2} className="detail-list-wrap">
              <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined className="list-arrow-bt" />
                <span className="list-text-bt">목록</span>
              </Button>
            </Col>
            <Row className="detailHeaderWrap">
              <Col span={18} offset={3}>
                <h2>{title} 문의하기</h2>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              <Col span={15}>
                <Form.Item label="분반명" name="className">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="학기" name="semesterCode">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="제목"
                name="title"
                placeholder="제목을 입력해주세요."
                rules={[
                  { required: true, message: "제목을 입력하지 않았습니다." },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                label="내용"
                name="description"
                className="form-editor-item"
                placeholder="내용을 입력해주세요."
              >
                <ReactQuillEditor
                  value={quillData}
                  onEditorChange={onEditorChange}
                />
              </Form.Item>
              <Form.Item>
                <Warning handleGoBack={handleGoBack} />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="write-submit-button"
                >
                  등록
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      ) : (
        <LoadingView />
      )}
      <ErrorModal />
    </>
  );
};

export default QnAWrite;
