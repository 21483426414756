export const actionCodeSettings = {
    url: window.location.protocol+"//"+window.location.host+"/?verified=true",
    // This must be true.
    // handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'www.naver.com'
  };