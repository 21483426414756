import "auth/Auth.css";
import { authState, idTokenState } from "recoil/authState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Route, Switch } from "react-router-dom";
import SignTapView from "auth/SignTapView";
import SentEmail from "auth/SentEmail";

const Auth = () => {
  const [userState, setUserState] = useRecoilState(authState);
  const [idToken, setIdToken] = useRecoilState(idTokenState);

  // const user = auth.currentUser;
  // console.log(`user is : ${JSON.stringify(userState)}`);

  return (
    <div className="auth-wrap">
      <Switch>
        <Route path="/auth/sign" component={SignTapView} exact />
        <Route path="/auth/email" component={SentEmail} exact />
      </Switch>
    </div>
  );
};

export default Auth;
