// 22/02/10 13:19경 \src\arduino에서 복사

import { useState, useEffect } from "react";
import { Form, Modal, Result } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import HasComment from "mypage/components/HasComment";
import { createComment, deleteComment, putComment } from "services/inquiryApi";

const QnAComment = (props) => {
  const { bbsId, status } = props;
  const [edit, setEdit] = useState(false);
  const [refetchState, setRefetchState] = useState(false);
  const [state, setState] = useState({
    commentId: null,
    inquiryId: bbsId,
    description: null,
    loading: false,
    error: null,
    value: null,
  });

  const [form] = Form.useForm();

  // post & edit 동시 이벤트.
  const handleSubmit = async () => {
    setState({
      ...state,
      inquiryId: bbsId,
      loading: true,
    });

    if (state.description !== null && state.inquiryId !== null) {
      if (edit) {
        await putComment(state);
        setEdit(false);
        return setRefetchState(true);
      } else {
        createComment(state);
        return setRefetchState(true);
      }
    }
  };

  // 삭제 모달
  const [isModalVisible, setIsModalVisible] = useState(false);

  const boardDeleteConfirm = async () => {
    if (state.commentId != null) {
      deleteComment(bbsId, state.commentId);
      return setIsModalVisible(false);
    }
  };

  const boardDeleteCancled = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="comment-wrapper">
      <Form
        name="replyform"
        form={form}
        style={{ border: "1px solid #e0e0e0", padding: "24px" }}
        onFinish={handleSubmit}
      >
        <div className="comment-box">
          {status ? (
            edit ? null : (
              <HasComment
                bbsId={bbsId}
                edit={edit}
                setState={setState}
                refetchState={refetchState}
                setRefetchState={setRefetchState}
              />
            )
          ) : (
            <span>아직 등록된 답변이 없습니다.</span>
          )}
        </div>

        <Form.Item>
          <Modal
            visible={isModalVisible}
            onOk={boardDeleteConfirm}
            onCancel={boardDeleteCancled}
            okText="확인"
            cancelText="취소"
            okButtonProps={{ size: "large", danger: "true" }}
            cancelButtonProps={{ size: "large" }}
          >
            <Result
              icon={<DeleteTwoTone />}
              title="해당 답변이 삭제됩니다."
              subTitle="답변을 삭제하겠습니까?"
            />
          </Modal>
        </Form.Item>
      </Form>
    </div>
  );
};

export default QnAComment;
