import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import axios from "axios";
import WriteEditor from "common/form/WriteEditorClass";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, Upload, message } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { createBoard } from "services/boardApi";
import { defaultUrl, refreshToken } from "services/api.helpers";
import "common/form/Form.css";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const BoardWriteAttach = ({ url, category, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const history = useHistory();
  const [data, setData] = useState({
    title: "",
    writer: "",
    description: "",
    info: {},
    category: category,
  });
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const config = {
    customRequest: ({ file, onSuccess, onError }) => {
      let formData = new FormData();
      formData.append("file", file);
      refreshToken().then((idToken) => {
        axios
          .post(`${defaultUrl}/uploads/`, formData, {
            headers: {
              Authorization: idToken,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((res) => {
            onSuccess(res.data.url); // fileList에 각 배열의 response에 url 반환
          })
          .catch((error) => {
            onError({ event: error });
            if (error.response) {
              console.log("file upload error-", JSON.stringify(error.response));
              if (
                error.response.data.errorCode.code === "FILE_SIZE_OVER" ||
                error.response.data.errorCode.code === "LIMIT_FILE_SIZE"
              ) {
                message.error(`업로드 하려는 파일 용량이 20MB 이상입니다.`);
                onError();
              }
            }
          });
      });
    },
    listType: "text",
    maxCount: 10, // 최대 업로드 가능 파일 수
    onChange: function (files) {
      setFileList(files.fileList);
    },
    fileList: fileList,
    onPreview: (e) => {
      // 파일을 클릭했을때, 주소를 받아서 api 주소로 재조합->클릭(안하면 호스트 주소대로 다운로드 됨)
      // console.log(e);
      refreshToken().then((idToken) => {
        axios({
          url: defaultUrl + e.response, //your url
          headers: {
            Authorization: idToken,
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          let filename = e.name;
          let data = response.data;
          let blob = new Blob([data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            let elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.getElementsByTagName("body")[0].appendChild(elem);
            elem.click();
            document.getElementsByTagName("body")[0].appendChild(elem);
          }
        });
      });
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "",
      showRemoveIcon: true,
      // removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
    },
  };

  const handleGoBack = () => {
    history.push(`${url}`);
  };

  const [desc, setDesc] = useState("");

  function onEditorChange(value) {
    setDesc(value);
  }

  const HandleFinish = async (values) => {
    setLoading(true);
    const newValue = Object.assign(data, values);
    if (fileList.length > 0) {
      let _fileList = [];

      await fileList.forEach((e) => {
        if (!!e.response) {
          let _wrap = { uid: e.uid, response: e.response, name: e.name }; // onSuccess(res.data.url) 에서 받아온 url값 ex) "/uploads/58d56dc2-202f-472a-b309-c21e74f05a4c/1%EF%BF%BD%EF%BF%BD03.wtc"
          _fileList.push(_wrap);
        }
      });

      newValue["info"] = {
        fileList: _fileList,
      };
    }
    setData(newValue);
    return postDataApi();
  };

  const postDataApi = async () => {
    data.category = category;
    return createBoard(data)
      .then((e) => {
        setLoading(false);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("board create failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "작성 실패",
            msg: `게시글 작성에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("board create failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(`${url}`);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const openMessage = () => {
    message.success({
      content: "새 게시글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      <Row justify="center" className="detailMainInfoWrap">
        <Form
          name="writeForm"
          layout="vertical"
          className="writeForm"
          onFinish={HandleFinish}
          onFinishFailed={HandleFinishFaild}
        >
          <Col span={2}>
            <Button onClick={onHandleBack} type="text">
              <ArrowLeftOutlined className="list-arrow-bt" />
              <span className="list-text-bt">목록</span>
            </Button>
          </Col>
          <Row className="detailMainInfoWrap">
            <Col span={18} offset={3}>
              <h2>{title} 글쓰기</h2>
            </Col>
            <Col span={3}></Col>
          </Row>
          <Row className="edit-content-wrap">
            <Form.Item
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              rules={[
                { required: true, message: "제목을 입력하지 않았습니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item label="파일 첨부(20MB, 10개 제한)">
              <Upload {...config}>
                <Button
                  disabled={fileList.length >= 10}
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              className="form-editor-item"
              placeholder="내용을 입력해주세요."
              rules={[
                { required: true, message: "내용을 입력하지 않았습니다." },
              ]}
            >
              <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
              <Warning handleGoBack={handleGoBack} />
              <Button
                type="primary"
                htmlType="submit"
                className="write-submit-button"
              >
                등록
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
      <ErrorModal />
    </>
  );
};

export default BoardWriteAttach;
