import { useState, useEffect } from "react";
import SignUpStAo from "auth/SignUpStAo";
import { TeamOutlined } from "@ant-design/icons";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { viewSignUpState, viewRoleState } from "recoil/viewState";
import AuthHeader from "auth/AuthHeader";
import { Button } from "antd";

const SelectRole = () => {
  const [roleView, setRoleView] = useRecoilState(viewRoleState);
  const setView = useSetRecoilState(viewSignUpState);
  useEffect(() => {
    console.log("atom role : " + roleView);
  }, [roleView]);

  function setSelectValue(role) {
    setRoleView(role);
    return setView("userCheck");
  }
  return (
    <>
      {AuthHeader(`* 회원 유형을 선택해 주세요.`)}
      <div className="select-role-wrap">
        <ul className="select-ul">
          <li>
            <Button
              className="select-role-bt"
              onClick={() => setSelectValue("ST")}
            >
              <span className="select-bt-title">수강생</span>ㅤ어드벤처 디자인
              강의를 수강하고 있습니다.
            </Button>
          </li>
          <li>
            <Button
              className="select-role-bt"
              onClick={() => setSelectValue("PR")}
            >
              <span className="select-bt-title">교수</span>ㅤ어드벤처 디자인
              담당 교수로 있습니다.
            </Button>
          </li>
          <li>
            <Button
              className="select-role-bt"
              onClick={() => setSelectValue("AS")}
            >
              <span className="select-bt-title">조교</span>ㅤ어드벤처 디자인
              관련 학과 조교로 있습니다.
            </Button>
          </li>
          <li>
            <Button
              className="select-role-bt"
              onClick={() => setSelectValue("CO")}
            >
              <span className="select-bt-title">기업멘토</span>ㅤ어드벤처 디자인
              연계 기업의 멘토입니다.
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SelectRole;
