import { Card, Col, Row, Typography } from "antd";

function AdventureService() {
  const { Title } = Typography;

  return (
    <>
      <div className="board-card-wrapper">
        <Row gutter={[0, 24]} justify="space-between">
          <Col span={24} sm={24} md={6} className="AdventureService">
            <Title>
              Adventure
              <br />
              Design
            </Title>
            <Title level={2}>교육 지원을 위한 서비스</Title>
            <hr />
            <Title level={5} style={{ textAlign: "justify" }}>
              공과대학 신입생들이 설계 교과목에서 아두이노와 같은 4차산업혁명
              맞춤형 도구를 사용함에 있어서 최대한의 강의 지원과 피드백 제공
            </Title>
          </Col>
          <Col span={24} sm={24} md={18}>
            <Row>
              <Col span={12}>
                <Card></Card>
              </Col>
              <Col span={6}>
                <Card></Card>
              </Col>
              <Col span={6}>
                <Card></Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdventureService;
