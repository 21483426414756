import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { accessEdit } from "recoil/authState";
import { useLocation } from "react-router-dom";
import { Space, Typography, Row, Col, Divider, Skeleton, List } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import moment from "moment";
import DetailHeaderPublic from "common/board-api/DetailHeaderPublic";
import DetailHeaderAdm from "common/board-api/DetailHeaderAdm";

const { Title, Text } = Typography;

const BoardDetailContent = (props) => {
  const { loading, url, detailData } = props;
  const isEdit = useRecoilValue(accessEdit);
  const location = useLocation();

  const [prevLink, setprevLink] = useState({
    link: null,
    title: null,
  });

  const [nextLink, setnextLink] = useState({
    link: null,
    title: null,
  });

  const curpathpoint = location.pathname.indexOf("/detail/");
  const curpath = location.pathname.slice(0, curpathpoint + 8);

  const prevFunction = () => {
    if (detailData?.nextData !== null) {
      setprevLink({
        link: curpath + detailData?.nextData.id,
        title: detailData?.nextData.title,
      });
    }
    if (detailData?.nextData === null) {
      setprevLink({
        link: null,
        title: "이전 게시물이 존재하지 않습니다.",
      });
    }
  };

  const nextFunction = () => {
    if (detailData?.prevData !== null) {
      setnextLink({
        link: curpath + detailData?.prevData.id,
        title: detailData?.prevData.title,
      });
    } else {
      setnextLink({
        link: null,
        title: "다음 게시물이 존재하지 않습니다.",
      });
    }
  };

  useEffect(() => {
    prevFunction();
    nextFunction();
    return () => null;
  }, [detailData?.nearRow]);

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <>
      <Row wrap align="middle" justify="start" style={{ overflow: "hidden" }}>
        {isEdit ? (
          <DetailHeaderAdm url={url} bbsId={detailData.id} />
        ) : (
          <DetailHeaderPublic url={url} />
        )}
        <Row>
          <Col span={24} style={{ margin: "50px 0 25px", padding: "0 20px" }}>
            <Title className="titleText">{detailData.title}</Title>
            <Text type="secondary" className="secondaryText">
              <Space>
                {detailData?.category === "NOTICE" ||
                detailData?.category === "CONTNOTI"
                  ? "관리자"
                  : detailData?.createdBy?.name}
                {moment(detailData.createdAt).format("YY/MM/DD")}ㅤ조회수
                {detailData.views}
              </Space>
            </Text>
            <Divider className="titleDivider" />
          </Col>
        </Row>
        <Row align="middle" justify="center" style={{ padding: "0 24px 24px" }}>
          {detailData ? (
            <>
              <ReactQuill
                value={detailData.description}
                readOnly={true}
                theme={"bubble"}
              />
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
          {/*아래가 게시글 내용 보여주는 방식*/}
        </Row>

        <Row align="middle" justify="center" className="detailFooterWrap">
          <Divider className="titleDivider" />
          <Col span={24} style={{ margin: "0 0 25px" }}>
            <List className="prevnextList" itemLayout="horizontal">
              <List.Item
                actions={[
                  <a key="list-loadmore-edit" href={nextLink.link}>
                    <UpOutlined />
                    ㅤ다음글
                  </a>,
                  <a key="list-loadmore-more" href={nextLink.link}>
                    {nextLink.title}
                  </a>,
                ]}
              />
              <List.Item
                actions={[
                  <a key="list-loadmore-edit" href={prevLink.link}>
                    <DownOutlined />
                    ㅤ이전글
                  </a>,
                  <a key="list-loadmore-more" href={prevLink.link}>
                    {prevLink.title}
                  </a>,
                ]}
              />
            </List>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default BoardDetailContent;
