import { useState, useEffect } from "react";
import auth from "services/firebase";
import defaultAxios from "axios";

const useGetDetailWt = (opts, axiosInstance = defaultAxios) => {
    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null,
        userId: null,
    });

    const [trigger, setTrigger] = useState(0);

    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(Date.now());
    };

    useEffect(async () => {
        if(!opts.url){
            setState({
                ...state,
                error:"api 요청 url이 없습니다."
            });
            return;
        }
        else{
            const token = await auth.currentUser.getIdToken().then((idToken) => {
                return idToken;
            }).catch((err) => {
                console.log(err)
                setState({
                    ...state,
                    loading: false,
                    error: err,
                });
                return;
            });
                if(token){
                    const userId = auth.currentUser.uid;
                    const apiProps = Object.assign({...opts, headers: {Authorization: token}});
                    // console.log(apiProps);
                        axiosInstance(apiProps)
                    .then(response => {
                        // console.log(response);
                        setState({
                            ...state,
                            loading: false,
                            data: response.data,
                            userId: userId,
                        });
                    }).catch(error => {
                        setState({
                            ...state,
                            loading: false,
                            error
                        });
                    });
                    }else{
                        setState({
                            ...state,
                            loading: false,
                            error: "사용자 token이 유효하지 않습니다."
                        });
                    }
        }
    },[trigger]);



    return {...state, refetch};
};

export default useGetDetailWt;
