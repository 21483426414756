import { useState, useEffect } from "react";
import axios from "axios";
import { defaultUrl } from "services/api.helpers";
import { useRecoilValue } from "recoil";
import { classUserState } from "recoil/filterState";
import { Select, Row, Col, Form } from "antd";
import "common/board-api/BoardHeader.css";
import LoadingView from "common/view/LoadingView";

const MaterialSelectHeader = (props) => {
  const { Option } = Select;
  const { setClassId, setSem, data } = props;
  const classUser = useRecoilValue(classUserState);
  const [semList, setSemList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState(null);

  const getSemesterList = async () => {
    await axios.get(`${defaultUrl}/semesters`).then((data) => {
      setSemList(data.data);
      return setLoading(false);
    });
  };

  const getClassList = async (value) => {
    setLoading(true);
    await axios.get(`${defaultUrl}/classes?sem=${value}`).then((data) => {
      if (data.data.totalCount === 0) {
        setClassList([]);
      }
      setClassList(data.data.results);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (data?.semesterCode) {
      getClassList(data?.semesterCode);
    } else {
      setClassId(classUser?.classId);
      setSem(classUser?.sem);
      getClassList(classUser?.sem);
    }
    getSemesterList();
  }, []);

  function onChangeSEM(value) {
    setSem(value.value);
    return getClassList(value.value);
  }

  function onSelect(value) {
    setClassId(value.value);
  }
  return (
    <div
      style={{
        margin: "45px auto 35px",
        textAlign: "left",
      }}
    >
      <Col sm={20} xs={18}>
        <Row justify="start">
          <Col xs={10} sm={10} md={8} lg={8} xl={8}>
            <Form.Item
              label="학기"
              name="semesterCode"
              initialValue={
                data?.semesterCode
                  ? data?.semesterCode
                  : classUser?.sem
                  ? classUser.sem
                  : "전체 학기"
              }
              rules={[
                { required: true, message: "학기를 선택하지 않았습니다." },
              ]}
            >
              <Select
                loading={loading}
                labelInValue
                defaultValue={
                  data?.semesterCode
                    ? data?.semesterCode
                    : classUser?.sem
                    ? classUser.sem
                    : "전체 학기"
                }
                style={{ width: "90%" }}
                onChange={onChangeSEM}
              >
                {semList !== null ? (
                  semList.map((item, index) => {
                    let i = item.semesterCode.toString();
                    let yr = i.substring(0, 4);
                    let semester = i.substring(4, 5);
                    return (
                      <Option
                        value={item.semesterCode}
                        key={index}
                        name={yr + "년" + semester + "학기"}
                      >
                        {yr}년 {semester}학기
                      </Option>
                    );
                  })
                ) : (
                  <Option>학기를 선택해 주세요.</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={10} sm={10} md={8} lg={8} xl={8}>
            <Form.Item
              label="분반"
              name="class"
              placeholder="분반을 선택해 주세요."
              initialValue={
                data?.className
                  ? data?.className
                  : classUser?.className
                  ? classUser.className
                  : "전체 분반"
              }
              rules={[
                { required: true, message: "분반을 선택하지 않았습니다." },
              ]}
            >
              <Select
                loading={loading}
                labelInValue
                defaultValue={
                  data?.className
                    ? data?.className
                    : classUser?.classId
                    ? classUser.classId
                    : "전체 분반"
                }
                style={{ width: "90%" }}
                onSelect={onSelect}
              >
                {classList !== null ? (
                  classList.map((item, i) => (
                    <Option key={i} value={item.id} name={item.className}>
                      {item.className}
                    </Option>
                  ))
                ) : (
                  <Option>분반을 선택해 주세요.</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default MaterialSelectHeader;
