import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userRoleState } from "recoil/authState";
import {
  semState_ArdMaterial,
  classState_ArdMaterial,
} from "recoil/filterState";
import { defaultUrl } from "services/api.helpers";
import useGetListWt_Select_ArdMat from "arduino/useGetListWt_Select_ArdMat";
import { Row, Col } from "antd";
import LoadingView from "common/view/LoadingView";
import SelectHeaderArduMaterial from "arduino/SelectHeaderArduMaterial";
import ArduMaterialList from "arduino/ArduMaterialList";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const ArduMaterial = () => {
  const roleState = useRecoilValue(userRoleState);
  const [page, setPage] = useState(0);
  const setModal = useSetRecoilState(modalState);
  const sem = useRecoilValue(semState_ArdMaterial);
  const classId = useRecoilValue(classState_ArdMaterial);

  const { loading, data, error, refetch, totalCnt } =
    useGetListWt_Select_ArdMat({
      url: `${defaultUrl}/boards-wt?cate=arduMaterial&clsid=${classId.id}&sem=${sem}&rpp=12&page=${page}`,
    });

  useEffect(() => {
    return refetch();
  }, [page]);

  useEffect(() => {
    setPage(0);
    return refetch();
  }, [classId, sem]);

  return (
    <>
      <ErrorModal />
      {loading ? (
        <LoadingView />
      ) : error ? (
        setModal({
          type: "error",
          isActive: true,
          title: "게시글 정보를 가져올수 없습니다.",
          msg: `${error}`,
          goBack: true,
        })
      ) : roleState === "ST" ? (
        <>
          <div className="board-top-control-bt role-hide-bt">
            <Row justify="end" style={{ height: "32px" }}>
              <Col span={4}></Col>
            </Row>
          </div>
          <ArduMaterialList
            data={data}
            page={page}
            setPage={setPage}
            totalCnt={totalCnt}
          />
        </>
      ) : (
        <>
          <SelectHeaderArduMaterial
            refetchList={refetch}
            parentLoading={loading}
          />
          <ArduMaterialList
            data={data}
            page={page}
            setPage={setPage}
            totalCnt={totalCnt}
          />
        </>
      )}
    </>
  );
};

export default ArduMaterial;
