import { LockFilled } from "@ant-design/icons";
import { Button, Row } from "antd";
import ErrorModal from "common/view/ErrorModal";

const ResetPasswordSuccess = (props) => {
  const { signinView, setSigninView, email } = props;

  return (
    <div>
      <ErrorModal />
      <div className="signin-header">
        <p>
          <LockFilled style={{ fontSize: "48px", color: "rgb(221,221,221)" }} />
        </p>
        <ul
          style={{
            paddingTop: "32px",
          }}
        >
          <li style={{ marginBottom: "35px", fontSize: "1.2em" }}>{email}</li>
          <li>
            <span>비밀번호 재설정 링크가 전송되었습니다.</span>
          </li>
          <li>
            <span>이메일을 확인하여 비밀번호를 재설정 해주세요.</span>
          </li>
        </ul>
      </div>
      <Row>
        <Button
          type="primary"
          className="login-form-button default-auth-bt"
          onClick={() => setSigninView("signinInput")}
        >
          확인
        </Button>
      </Row>
    </div>
  );
};

export default ResetPasswordSuccess;
