import { useState, useEffect } from "react";
import defaultAxios from "axios";
import moment from 'moment';

const useGetDetail = (opts, axiosInstance = defaultAxios) => {

    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null,
    });
    const [trigger, setTrigger] = useState(0);
    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(moment({}));
    };

    useEffect(() => {
        if(!opts.url){
            return;
        }else{
          axiosInstance(opts)
         .then(response => {
            // console.log(response);
            setState({
                ...state,
                loading: false,
                data: response.data,
            });
        }).catch(error => {
            setState({
                ...state,
                loading: false,
                error
            });
        })
        }
    },[trigger]);



    return {...state, refetch};
};

export default useGetDetail;
