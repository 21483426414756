import { useState, useEffect } from "react";
import auth from "services/firebase";
import defaultAxios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { classState, defalutValue_className,  defalutValue_Sem, semState } from "recoil/filterState";
import { userRoleState } from "recoil/authState";


const useGetListWt_Select_Ardu = (opts, axiosInstance = defaultAxios) => {
    const roleState = useRecoilValue(userRoleState);
    const [ totalCnt, setTotalCnt ] = useState("");
    const [state, setState] = useState ({
        loading: true,
        error: null,
        data: null
    });
    const sem = useRecoilValue(semState);
    const classId = useRecoilValue(classState);
    const setDefalutValueclassName = useSetRecoilState(defalutValue_className);
    const setDefalutValuesem = useSetRecoilState(defalutValue_Sem);

    const [trigger, setTrigger] = useState(0);

    const refetch = () => {
        setState({
            ...state,
            loading: true
        });
        setTrigger(Date.now());
    };

    useEffect( async () => {
        if(!opts.url){
            return;
        }else{
            const token = await auth.currentUser.getIdToken().then((idToken) => {
                return  idToken;
            }).catch((err) => {
                setState({
                    ...state,
                    loading: false,
                    error: err
                });
            });
        if(token){
            const apiProps = Object.assign({...opts, headers: {Authorization: token}});
            axiosInstance(apiProps)
                    .then(response => {
                        if(response.data.userSem){
                            if(!sem){
                                if(roleState === "ADM"){
                                    setDefalutValuesem("전체 학기");
                                }else{
                                    setDefalutValuesem(response.data.userSem);
                                }
                            }
                            if(!classId?.className){
                                if(roleState === "ADM"){
                                    setDefalutValueclassName("전체 분반");
                                }else{
                                    setDefalutValueclassName(response.data.userClassName);
                            }
                        }
                    }else{
                        if(!sem){
                            setDefalutValuesem("전체 학기");
                        }
                        if(!classId.className){
                            setDefalutValueclassName("전체 분반");
                        }
                    }
                        setState({
                            ...state,
                            loading: false,
                            data: response.data.results,
                        });
                        setTotalCnt(response.data.totalCount);
                        return setNumbering(response.data.results);
                    }).catch(error => {
                        setState({
                            ...state,
                            loading: false,
                            error
                        });
                    });
        }else{
            setState({
                ...state,
                loading: false,
                error: "사용자 token이 유효하지 않습니다."
            });
        }

        }
    },[trigger]);


    const setNumbering = (data) => {
        let newData = [];
        const reverseArray = data.reverse();
        reverseArray.map((list, index) => {
            let listData;
            return (
            (listData = Object.assign({ ...list, listNo: index + 1 })),
            newData.push(listData)
            );
        });
        const dataSort = newData.reverse();

        if (dataSort !== null) {
            return setState({...state, data:dataSort, loading:false });
        }
    };

    return {...state, refetch, totalCnt};
};

export default useGetListWt_Select_Ardu;
