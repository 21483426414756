import React from "react";
import auth from "services/firebase";
import { atom, selector } from "recoil";



type view = "selectList" | "userCheck" | "SignUpStAo" | "SignUpMento" | "formInput";
type roleView = "ALL" | "ST" | "PR" | "AS" | "CO" | "ADM";


export const viewSignUpState = atom<view>({
  key: "viewState",
  default: "selectList",
});

export const viewRoleState = atom<roleView>({
  key:'viewRoleState',
  default: "ALL"
});


// export const changeView = selector<view>({
//   key: "changeView",
//   get: ({ get }) => get(viewState),
//   set: ({ set }, newValue) => set(viewState, newValue),
// });