import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { Layout } from "antd";
import "react-quill/dist/quill.snow.css";
import "common/board/Board.css";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import { Switch, Route } from "react-router-dom";
import { userRoleState } from "recoil/authState";
import { useRecoilValue } from "recoil";
import LoadingView from "common/view/LoadingView";
import NoAccess from "common/view/NoAccess";
import QnA from "design/QnA";
import QnAEdit from "common/inquiry/QnAEdit";
import QnAWrite from "common/inquiry/QnAWrite";
import QnADetail from "common/inquiry/QnADetail";

const { Content } = Layout;

const DesignQna = () => {
  const viewState = useRecoilValue(userRoleState);
  const title = "설계 프로젝트";
  const subTitle = "멘토링 Q&A";
  const menuList = [{ name: "멘토링 Q&A", link: "/designQna" }];

  const userLayout = (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content style={{ marginBottom: 50, paddingBottom: "50px" }}>
              <Switch>
                <Route exact path="/designQna">
                  <QnA />
                </Route>
                <Route exact path="/designQna/write">
                  <QnAWrite
                    category="projectQna"
                    url="/designQna"
                    title="멘토링"
                  />
                </Route>
                <Route exact path="/designQna/:bbsId">
                  <QnADetail url="/designQna" />
                </Route>
                <Route exact path="/designQna/edit/:bbsId">
                  <QnAEdit url="/designQna" title="멘토링" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );

  const switchView = () => {
    switch (viewState) {
      case "ST":
        return userLayout;
      case "PR":
        return userLayout;
      case "AS":
        return userLayout;
      case "CO":
        return userLayout;
      case "ADM":
        return userLayout;
      case "":
        return <LoadingView />;
      case "ALL":
        return <NoAccess />;
      default:
        return <LoadingView />;
    }
  };

  return <>{switchView()}</>;
};

export default DesignQna;
