import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import ReactQuillEditor from "common/form/ReactQuillEditor";
import LoadingView from "common/view/LoadingView";
import Warning from "common/view/Warning";
import { Button, Row, Col, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { putInquiry } from "services/inquiryApi";
import "common/form/Form.css";
import useGetDetailWt from "hooks/useGetDetailWt";
import { defaultUrl } from "services/api.helpers";
import { modalState } from "recoil/modalState";
import ErrorModal from "common/view/ErrorModal";

const QnAEdit = ({ url, title }) => {
  const setModal = useSetRecoilState(modalState);
  const key = "updatable";
  const { bbsId } = useParams();
  const history = useHistory();

  const { loading, error, data, refetch } = useGetDetailWt({
    url: `${defaultUrl}/inquiries/${bbsId}`,
  });

  const [quillData, setQuillData] = useState(null);
  const [apiLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [data]);

  function onEditorChange(value) {
    setQuillData(value);
  }

  const HandleFinish = async (values) => {
    // console.log(values);
    let replaceQuillText = null;

    // console.log(quillData);

    if (quillData) {
      replaceQuillText = quillData.replace(/"/gi, `'`);
    } else {
      replaceQuillText = data.description;
    }

    if (replaceQuillText) {
      const newValue = {
        title: values?.title,
        description: replaceQuillText,
      };

      // console.log(newValue);
      setLoading(true);
      return putDataApi(newValue);
    } else {
      const newValue = {
        title: values?.title,
      };
      setLoading(true);
      return putDataApi(newValue);
    }
  };

  const putDataApi = async (data) => {
    // console.log(`putDataApi: ${JSON.stringify(data)}`);
    putInquiry(bbsId, data)
      .then((e) => {
        setLoading(false);
        // console.log(`게시글이 정상적으로 수정되었습니다.`);
        if (e?.status === "success") {
          openMessage();
          return onHandleBack();
        } else {
          console.log("inquiry edit failed - ", JSON.stringify(e?.result));
          return setModal({
            type: "error",
            isActive: true,
            title: "수정 실패",
            msg: `문의글 수정에 실패하였습니다.${
              !!e.result?.errorCode && !!e.result?.message
                ? "(" + e.result?.errorCode + ": " + e.result?.message + ")"
                : ""
            }`,
            // redirect: `/`,
            goBack: true,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("inquiry edit failed - ", JSON.stringify(e));
        onHandleBack();
      });
  };

  const onHandleBack = () => {
    history.push(url);
  };

  const HandleFinishFaild = (errorInfo) => {
    console.log(errorInfo);
  };

  const convertSemesterCode = (code) => {
    let data = `${code}`;
    let yearAndMonth = data.substring(0, 4);
    let semester = data.substring(4, 5);
    return `${yearAndMonth}년 ${semester}학기`;
  };

  const openMessage = () => {
    message.success({
      content: "문의글이 정상적으로 등록되었습니다.",
      key,
      duration: 2,
    });
  };

  return (
    <>
      {data !== null ? (
        <Row justify="center" className="detailMainInfoWrap">
          <Form
            name="inquiryForm"
            layout="vertical"
            className="writeForm"
            onFinish={HandleFinish}
            onFinishFailed={HandleFinishFaild}
            initialValues={{
              writer: data?.writer,
              className: data?.className,
              semesterCode: convertSemesterCode(data?.semesterCode),
              title: data?.title,
              description: data?.description,
            }}
          >
            <Col span={2}>
              <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined className="list-arrow-bt" />
                <span className="list-text-bt">목록</span>
              </Button>
            </Col>
            <Row className="detailMainInfoWrap">
              <Col span={18} offset={3}>
                <h2>{title} 문의하기</h2>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              <Col span={7}>
                <Form.Item label="분반명" name="className">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="학기" name="semesterCode">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="작성자" name="writer">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="제목"
                name="title"
                placeholder="제목을 입력해주세요."
                rules={[
                  { required: true, message: "제목을 입력하지 않았습니다." },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                label="내용"
                name="description"
                className="form-editor-item"
                placeholder="내용을 입력해주세요."
              >
                <ReactQuillEditor
                  value={quillData}
                  onEditorChange={onEditorChange}
                />
              </Form.Item>
              <Form.Item>
                {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 게시글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
                <Warning handleGoBack={onHandleBack} />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="write-submit-button"
                >
                  수정
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      ) : (
        <LoadingView />
      )}
      <ErrorModal />
    </>
  );
};

export default QnAEdit;
