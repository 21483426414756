import {useState, useEffect} from "react";
import "react-quill/dist/quill.snow.css";
import {useLocation, useParams} from "react-router-dom";
import BestDetailContent from "contest/best/component/BestDetailContent";
//import BoardDetailContent from "common/board/BoardDetailContent";
import WithSkeleton from "common/board/WithSkeleton";
import useGetDetail from "hooks/useGetDetail";
import {defaultUrl} from "services/api.helpers";
import LoadingView from "common/view/LoadingView";

const BestDetail = ({url}) => {
    const {bbsId} = useParams();
    //console.log(`bbsId is ${bbsId}`);
    const {loading, data, error, refetch} = useGetDetail({
        url: `${defaultUrl}/boards/${bbsId}`,
    });

    return (
        <>
            {loading ? (
                <LoadingView />
            ) : (
                <BestDetailContent url={url} detailData={data} loading={loading}/>
            )}
        </>
    );
};

export default BestDetail;
