import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userRoleState } from "recoil/authState";
import LoadingView from "common/view/LoadingView";
import "arduino/Inquiry.css";
import moment from "moment";
import ReactQuill from "react-quill";
import { defaultUrl } from "services/api.helpers";
import { Row, Col, Tag } from "antd";
import useGetDetailWt from "hooks/useGetDetailWt";
import DetailHeaderPublic from "common/board-api/DetailHeaderPublic";
import QnAComment from "common/inquiry/QnAComment";
import DetailHeaderAdmQna from "common/board-api/DetailHeaderAdmQna";

const QnADetail = ({ url }) => {
  const roleState = useRecoilValue(userRoleState);
  const { bbsId } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState(false);

  const { loading, data, error, refetch, userId } = useGetDetailWt({
    url: `${defaultUrl}/inquiries/${bbsId}`,
  });

  useEffect(() => {
    // console.log(bbsId);
    if (status) {
      refetch();
    }
  }, [bbsId, status]);

  const convertSemesterCode = (code) => {
    if (code) {
      let data = `${code}`;
      let yearAndMonth = data.substring(0, 4);
      let semester = data.substring(4, 5);
      return `${yearAndMonth}년 ${semester}학기`;
    } else {
      return "";
    }
  };

  return (
    <>
      {loading === false ? (
        <div className="qna-detail">
          <Row
            wrap
            align="middle"
            justify="start"
            style={{ overflow: "hidden" }}
          >
            {data.createdUserId === userId || roleState === "ADM" ? (
              <DetailHeaderAdmQna
                bbsId={bbsId}
                url={url}
                status={data.status}
              />
            ) : (
              <DetailHeaderPublic url={url} />
            )}
            <Row span={24} className="border-grid" justify="start">
              <Col md={4} sm={6} xs={6} className="border-grid-title">
                <span>제목</span>
              </Col>
              <Col md={20} sm={18} xs={18} className="border-grid-content">
                <span>ㅤ{data?.title?.slice(0, 100)}</span>
              </Col>
            </Row>
            <Row span={24} className="border-grid-row" justify="start">
              <Col md={4} sm={6} xs={6} className="border-grid-title">
                <span>작성일</span>
              </Col>
              <Col md={10} sm={18} xs={18} className="border-grid-content">
                <span>ㅤ{moment(data?.createdAt).format("YY/MM/DD")}</span>
              </Col>
              <Col
                md={4} sm={6} xs={6} 
                className="border-grid-title"
                style={{ borderLeft: "1px solid #e0e0e0" }}
              >
                <span>답변상태</span>
              </Col>
              <Col md={6} sm={18} xs={18} className="border-grid-content">
                <span>ㅤ{data?.status === true ? (
                    <Tag color="green" className="statusTag">
                      답변완료
                    </Tag>
                ) : (
                    <Tag color="orange" className="statusTag">
                      미완료
                    </Tag>
                )}</span>
              </Col>
            </Row>
            <Row span={24} className="border-grid-row" justify="start">
              <Col md={4} sm={6} xs={6} className="border-grid-title">
                <span>분반명</span>
              </Col>
              <Col md={10} sm={18} xs={18} className="border-grid-content">
                <span>ㅤ{data?.className}</span>
              </Col>
              <Col
                md={4} sm={6} xs={6}
                className="border-grid-title"
                style={{ borderLeft: "1px solid #e0e0e0" }}
              >
                <span>학기</span>
              </Col>
              <Col md={6} sm={18} xs={18} className="border-grid-content">
                <span>ㅤ{convertSemesterCode(data?.semesterCode)}</span>
              </Col>
            </Row>

            {(roleState === "ST" || roleState === "ALL") ? <></> : (
                <Row span={24} className="border-grid-row" justify="start">
                  <Col md={4} sm={6} xs={6} className="border-grid-title">
                    <span>작성자</span>
                  </Col>
                  <Col md={10} sm={18} xs={18} className="border-grid-content">
                    <span>ㅤ{data?.writer}</span>
                  </Col>
                </Row>
            )}

            <Row
              span={24}
              className="border-grid"
              justify="start"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Col
                span={24}
                className="border-grid-title"
                style={{
                  borderBottom: "1px solid #e0e0e0",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <span>문의내용</span>
              </Col>
              <Col
                span={24}
                className="border-grid-content"
                style={{ padding: "20px 30px", minHeight: 400 }}
              >
                <ReactQuill
                  value={data?.description}
                  readOnly={true}
                  theme={"bubble"}
                />
              </Col>
            </Row>
            <Row>
              <Row className="comment-row">
                <Col
                  span={24}
                  className="border-grid-title"
                  style={{ borderLeft: "none", borderRight: "none" }}
                ></Col>
                <QnAComment bbsId={bbsId} status={data.status} refetch={refetch} />
              </Row>
            </Row>
          </Row>
        </div>
      ) : (
        <LoadingView />
      )}
    </>
  );
};

export default QnADetail;
