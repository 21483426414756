import { useState, useEffect } from "react";
import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { useParams, Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import BestList from "contest/best/BestList";
import BestWrite from "contest/best/BestWrite";
import BestDetail from "contest/best/BestDetail";
import BestEdit from "contest/best/BestEdit";
import BoardListWriterAdmin from "common/board-api/BoardListWriterAdmin";
import BoardWrite from "common/board-api/BoardWrite";
import BoardEdit from "common/board-api/BoardEdit";
import BoardDetail from "common/board-api/BoardDetail";
import "common/board/Board.css";

const { Content } = Layout;

const Contest = () => {
  let { value } = useParams();
  const title = "경진대회";
  const [subTitle, setSubTitle] = useState("");
  const menuList = [
    { name: "경진대회 공지", link: "/cont/noti" },
    { name: "우수작 소개", link: "/cont/best" },
  ];

  useEffect(() => {
    switch (value) {
      case "noti":
        return setSubTitle("경진대회 공지");
      case "best":
        return setSubTitle("우수작 소개");
      default:
        return setSubTitle("경진대회");
    }
  }, [value]);

  return (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle} title={title} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content className="board-content-wrap">
              <Switch>
                <Route path="/cont/noti" exact>
                  <BoardListWriterAdmin category="contNoti" />
                </Route>
                <Route path="/cont/noti/write" exact>
                  <BoardWrite
                    category="contNoti"
                    title="경진대회 공지"
                    url="/cont/noti"
                  />
                </Route>
                <Route path="/cont/noti/edit/:bbsId">
                  <BoardEdit title="경진대회 공지" url="/cont/noti" />
                </Route>
                <Route path="/cont/noti/detail/:bbsId">
                  <BoardDetail type="basic" url="/cont/noti" />
                </Route>

                <Route exact path="/cont/best" value={value}>
                  <BestList />
                </Route>
                <Route exact path="/cont/best/write" value={value}>
                  <BestWrite url="/cont/best" />
                </Route>
                <Route exact path="/cont/best/edit/:bbsId" value={value}>
                  <BestEdit url="/cont/best" />
                </Route>
                <Route exact path="/cont/best/detail/:bbsId" value={value}>
                  <BestDetail url="/cont/best" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );
};

export default Contest;
