import {
  Space,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  List,
  Skeleton,
} from "antd";
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

export default function WithSkeleton() {
  return (
    <>
      <Row
        wrap
        align="middle"
        justify="start"
        style={{ overflow: "hidden" }}
        className="WithSkeleton"
      >
        <Row
          className="detailMainInfoWrap"
          style={{ paddingLeft: 20, maxWidth: 1200 }}
        >
          <Col span={2}>
            <Button type="text">
              <ArrowLeftOutlined
                style={{ color: "#BDBDBD", fontSize: 20, fontWeight: "bold" }}
              />
              <span style={{ color: "#828282", fontWeight: 400, fontSize: 16 }}>
                목록
              </span>
            </Button>
          </Col>
          <Col span={2} offset={18}></Col>
          <Col span={2} offset={0}></Col>
          <Col span={24} style={{ margin: "50px 0 25px", padding: "0 20px" }}>
            <Title className="titleText">
              <Skeleton.Input style={{ width: 200 }} active size="large" />
            </Title>
            <Text type="secondary" className="secondaryText">
              <Space>
                <Skeleton.Input
                  style={{ width: 180, height: "1em" }}
                  active
                  size="small"
                />
              </Space>
            </Text>
            <Divider className="titleDivider" />
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            padding: "0 24px 24px",
            width: "100%",
            height: "500px",
            margin: "0 20px",
          }}
        >
          <Skeleton active style={{ padding: "12px 20px" }} />
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
          <Divider className="titleDivider" />
          <div
            className="detailIconWrap"
            style={{ height: "500px", padding: "50px 0" }}
          >
            <Skeleton active />
          </div>
          <Row justify="start" style={{ margin: "0 0 25px" }}>
            <List className="prevnextList" itemLayout="horizontal">
              <List.Item
                actions={[
                  <a key="list-loadmore-edit">
                    <UpOutlined />
                    ㅤ다음글
                  </a>,
                  <a key="list-loadmore-more">
                    <Skeleton.Input
                      style={{ width: 120 }}
                      size="small"
                      active
                    />
                  </a>,
                ]}
              />
              <List.Item
                actions={[
                  <a key="list-loadmore-edit">
                    <DownOutlined />
                    ㅤ이전글
                  </a>,
                  <a key="list-loadmore-more">
                    <Skeleton.Input
                      style={{ width: 120 }}
                      size="small"
                      active
                    />
                  </a>,
                ]}
              />
            </List>
          </Row>
        </Row>
      </Row>
    </>
  );
}
