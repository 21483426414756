import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import LoadingView from "common/view/LoadingView";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { BrowserRouter } from "react-router-dom";
import App from 'App.js';
import { Suspense } from 'react';

ReactDOM.render( 
<RecoilRoot>
    <Suspense fallback={LoadingView}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
    </Suspense>
</RecoilRoot>
, document.getElementById('root'));
