import { useState, useEffect } from "react";
import { modalState, modalType } from "recoil/modalState";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Modal } from "antd";

const ErrorModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const history = useHistory();

  useEffect(() => {
  }, [modal]);

  const showModal = () => {
    setModal({ ...modal, isActive: true });
  };

  const handleOk = () => {
    setModal({ ...modal, isActive: false });
    if (!!modal.goBack) {
      setModal({...modal, goBack: false});
      window.history.back();
    }
    if (!!modal.redirect) {
      setModal({...modal, redirect: ''});
      return history.push(modal.redirect);
    }
  };

  const handleCancel = () => {
    setModal({ ...modal, isActive: false });
  };

  return (
    <>
      <Modal
        title={modal.title}
        visible={modal.isActive}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <div className="modal-type-icon">
          </div>
          <span>{modal.msg}</span>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModal;
