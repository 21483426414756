import SignIn from "auth/SignIn";
import SignUp from "auth/SignUp";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const SignTapView = (): JSX.Element => {
  return (
    <>
      <div className="tap-wrap">
        <Tabs defaultActiveKey="1">
          <TabPane tab="로그인" key="1" className="tap-title gmarketBold">
            <div className="sign-wrap">
              <SignIn />
            </div>
          </TabPane>
          <TabPane tab="회원가입" key="2" className="tap-title gmarketBold">
            <div className="sign-wrap">
              <SignUp />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default SignTapView;
