import { useState, useEffect } from "react";
import FooterBottomWhite from "common/footer/FooterBottomWhite";
import { useRecoilValue } from "recoil";
import { userRoleState } from "recoil/authState";
import LoadingView from "common/view/LoadingView";
import { Layout } from "antd";
import "common/board/Board.css";
import BoardHeader from "common/board/BoardHeader";
import BoardSidebar from "common/board/BoardSidebar";
import ArduMaterial from "arduino/ArduMaterial";
import { useParams, Switch, Route, useHistory } from "react-router-dom";
import NoAccess from "common/view/NoAccess";
import QnADetail from "common/inquiry/QnADetail";
import QnAWrite from "common/inquiry/QnAWrite";
import BoardDetail from "common/board-api/BoardDetail";
import MaterialEdit from "arduino/MaterialEdit";
import MaterialWrite from "arduino/MaterialWrite";
import QnAEdit from "common/inquiry/QnAEdit";
import QnA from "arduino/QnA";

const { Content } = Layout;

const Arduino = () => {
  const viewState = useRecoilValue(userRoleState);
  let { value } = useParams();
  const title = "아두이노 실습";
  const [subTitle, setSubTitle] = useState("");
  const menuList = [
    { name: "실습 자료실", link: "/ardu/material" },
    { name: "실습 Q&A", link: "/ardu/qna" },
  ];

  useEffect(() => {
    switch (value) {
      case "material":
        return setSubTitle("실습 자료실");
      case "qna":
        return setSubTitle("실습 Q&A");
      default:
        return setSubTitle("실습 자료실");
    }
  }, [value]);

  const userLayout = (
    <div className="board-main-wrap">
      <Layout>
        <BoardHeader subTitle={subTitle} />
      </Layout>
      <Layout className="parent-wrap boardLayout">
        <Layout>
          <BoardSidebar title={title} menuList={menuList} />
          <Layout>
            <Content style={{ marginBottom: 50, paddingBottom: "50px" }}>
              <Switch>
                <Route exact path="/ardu/material">
                  <ArduMaterial />
                </Route>
                <Route exact path="/ardu/material/write">
                  <MaterialWrite
                    url="/ardu/material"
                    title="실습 자료실"
                    category="arduMaterial"
                  />
                </Route>
                <Route exact path="/ardu/material/detail/:bbsId">
                  <BoardDetail
                    type="attach"
                    url="/ardu/material"
                    category="arduMaterial"
                  />
                </Route>
                <Route exact path="/ardu/material/edit/:bbsId">
                  <MaterialEdit url="/ardu/material" title="실습 자료실" />
                </Route>
                <Route exact path="/ardu/qna">
                  <QnA />
                </Route>
                <Route exact path="/ardu/qna/write">
                  <QnAWrite category="arduQna" url="/ardu/qna" title="실습" />
                </Route>
                <Route exact path="/ardu/qna/:bbsId">
                  <QnADetail url="/ardu/qna" />
                </Route>
                <Route exact path="/ardu/qna/edit/:bbsId">
                  <QnAEdit url="/ardu/qna" title="실습" />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <FooterBottomWhite />
    </div>
  );

  const switchView = () => {
    switch (viewState) {
      case "ST":
        return userLayout;
      case "PR":
        return userLayout;
      case "AS":
        return userLayout;
      case "CO":
        return userLayout;
      case "ADM":
        return userLayout;
      case "":
        return <LoadingView />;
      case "ALL":
        return <NoAccess />;
      default:
        return <LoadingView />;
    }
  };

  return <>{switchView()}</>;
};

export default Arduino;
